/* 
 * 	Core Owl Carousel CSS File
 *	v1.3.3
 */



#owl-demo .item{
          margin: 0 15px 0 0;
		  padding: 0 15px 0 0;
    }
#owl-demo4 .item{ margin: 0 15px 0 0; padding: 0 15px 0 0;}
    #owl-demo .item img{
        display: block;
        width: 100%;
        height: auto;
    }
#owl-demo4 .owl-pagination{ display:none;}
	

	
	
	#owl-demo .owl-item div{
      padding:5px;
	  height: auto;
    }
    #owl-demo .owl-item img{
      display: block;
      width: 100%;
      height: auto;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
    }
/* clearfix */
.owl-carousel .owl-wrapper:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
	
}
.latest-news .owl-pagination{ display:none;}
/* display none until init */
.owl-carousel{
	display: none;
	position: relative;
	width: 100%;
	-ms-touch-action: pan-y;
}
.owl-carousel .owl-wrapper{
	display: none;
	position: relative;
	-webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper-outer{
	overflow: hidden;
	position: relative;
	width: 98%;
}

#owl-demo .owl-carousel .owl-wrapper-outer{
	width: 98%;
}

.owl-carousel .owl-wrapper-outer.autoHeight{
	-webkit-transition: height 500ms ease-in-out;
	-moz-transition: height 500ms ease-in-out;
	-ms-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}
	
.owl-carousel .owl-item{
	float: left;
}
.owl-controls .owl-page,
.owl-controls .owl-buttons div{
	cursor: pointer;
}
.owl-controls {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */
.grabbing { 
    cursor:url(grabbing.png) 8 8, move;
}

/* fix */
.owl-carousel  .owl-wrapper,
.owl-carousel  .owl-item{
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility:    hidden;
	-ms-backface-visibility:     hidden;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
}












#services-slide .owl-prev{ display:none;}
#services-slide .owl-next{ display:none;}
#services-slide .owl-controls.clickable {  margin: -32px 0 0 0;  position: relative;}
#services-slide .owl-controls .owl-page span{ background:#ECECEC ;}


.owl-theme .owl-controls{
	margin-top: 10px;
	text-align: center;
}

/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons div{
	color: #FFF;
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
	margin: 5px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	background: url(../images/arrow-cro.png) no-repeat;
	height:25px;
	width:15px;
	filter: Alpha(Opacity=50);/*IE7 fix*/
	opacity: 0.5;
	position: absolute;
}

.owl-theme .owl-controls .owl-buttons .owl-next{   right: -2%;  background-position: -15px 0; top: 46%;  color: rgba(142, 18, 18, 0);}
.owl-theme .owl-controls .owl-buttons .owl-prev{   left: -5%;  background-position: 0px 0;    top: 46%;  color: rgba(142, 18, 18, 0);}

/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover{
	filter: Alpha(Opacity=100);/*IE7 fix*/
	opacity: 1;
	text-decoration: none;
}

/* Styling Pagination*/

.owl-theme .owl-controls .owl-page{
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
	margin: 0 4px;
}
.owl-theme .owl-controls .owl-page span{
	display: block;
	width: 12px;
	height: 12px;
	margin: 0;
	filter: Alpha(Opacity=50);/*IE7 fix*/
	opacity: 1;
	  background: #b7b7b7;
  width: 45px;
  border-radius: 0;
  height: 5px;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{
	filter: Alpha(Opacity=100);/*IE7 fix*/
	opacity: 1;
}

/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers{
	height: auto;
	width: auto;
	color: #FFF;
	padding: 2px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
}

/* preloading images */
.owl-item.loading{
	min-height: 150px;
	background: url(AjaxLoader.gif) no-repeat center center
}

#services-slide .item img{
        display: block;
        width: 100%;
        height: auto;
}
.member-detail .owl-pagination {  display: none; }	
	




.news-sec .owl-theme .owl-controls .owl-buttons div	{background: url(../images/white-arrows.jpg) no-repeat;  width: 57px;  height: 58px;  border-radius: 0px; opacity:1;}
.news-sec .owl-theme .owl-controls .owl-buttons .owl-next{  right: -5px;  top: 42%; background-position:-57px 0;}
.news-sec .owl-theme .owl-controls .owl-buttons .owl-prev {  left: -5px; top:42%; }
.news-sec .owl-carousel .owl-wrapper-outer{ width:100%;}
.news-sec .owl-pagination {  display: none;}
	
	
	
	
	
	
	
	
	
	