/*!
 * Cube Portfolio - Responsive jQuery Grid Plugin
 *
 * version: 2.3.4 (20 July, 2015)
 * require: jQuery v1.7+
 *
 * Copyright 2013-2015, Mihai Buricea (http://scriptpie.com/cubeportfolio/live-preview/)
 * Licensed under CodeCanyon License (http://codecanyon.net/licenses)
 *
 */.cbp, .cbp *, .cbp :after, .cbp :before, .cbp-l-filters-alignCenter, .cbp-l-filters-alignCenter *, .cbp-l-filters-alignLeft, .cbp-l-filters-alignLeft *, .cbp-l-filters-alignRight, .cbp-l-filters-alignRight *, .cbp-l-filters-button, .cbp-l-filters-button *, .cbp-l-filters-buttonCenter, .cbp-l-filters-buttonCenter *, .cbp-l-filters-dropdown, .cbp-l-filters-dropdown *, .cbp-l-filters-list, .cbp-l-filters-list *, .cbp-l-filters-work, .cbp-l-filters-work *, .cbp-l-filters-big, .cbp-l-filters-big *, .cbp-l-filters-text, .cbp-l-filters-text *, .cbp-l-filters-underline, .cbp-l-filters-underline *, .cbp-popup-wrap, .cbp-popup-wrap *, .cbp-popup-singlePage, .cbp-popup-singlePage * {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}
.cbp-l-grid-agency-title, .cbp-l-grid-agency-desc, .cbp-l-grid-work-title, .cbp-l-grid-work-desc, .cbp-l-grid-blog-title, .cbp-l-grid-projects-title, .cbp-l-grid-projects-desc, .cbp-l-grid-masonry-projects-title, .cbp-l-grid-masonry-projects-desc {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis
}
.cbp-l-filters-alignCenter, .cbp-l-filters-alignCenter *, .cbp-l-filters-alignLeft, .cbp-l-filters-alignLeft *, .cbp-l-filters-alignRight, .cbp-l-filters-alignRight *, .cbp-l-filters-button, .cbp-l-filters-button *, .cbp-l-filters-buttonCenter, .cbp-l-filters-buttonCenter *, .cbp-l-filters-dropdown, .cbp-l-filters-dropdown *, .cbp-l-filters-list, .cbp-l-filters-list *, .cbp-l-filters-work, .cbp-l-filters-work *, .cbp-l-filters-big, .cbp-l-filters-big *, .cbp-l-filters-text, .cbp-l-filters-text *, .cbp-l-filters-underline, .cbp-l-filters-underline * {
	-webkit-tap-highlight-color: transparent
}
.cbp-item {
	display: inline-block;
	margin: 0 10px 20px 0
}
.cbp {
	position: relative;
	margin: 0 auto;
	z-index: 1;
	height: 400px
}
.cbp:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	
}
.cbp>* {
	visibility: hidden
}
.cbp .cbp-item {
	position: absolute;
	top: 0;
	left: 0;
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden
}
.cbp img {
	display: block;
	width: auto;
	height: auto;
	min-width: 100%;
	max-width: 100%
}
.cbp a, .cbp a:hover, .cbp a:active {
	text-decoration: none;
	outline: 0
}
.cbp-ready>* {
	visibility: visible
}
.cbp-ready:before {
	display: none
}
.cbp-wrapper-outer {
	overflow: hidden
}
.cbp-wrapper-outer, .cbp-wrapper, .cbp-wrapper-helper {
	position: absolute;
	top: 0;
	left: 0;
	list-style-type: none;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	z-index: 1
}
.cbp-item-off {
	z-index: -1;
	pointer-events: none;
	visibility: hidden
}
.cbp-item-on2off {
	z-index: 0
}
.cbp-item-off2on {
	z-index: 1
}
.cbp-item-on2on {
	z-index: 2
}
.cbp-item-wrapper {
	width: 100%;
	height: 100%;
	position: relative;
	top: 0;
	left: 0
}
.cbp-l-project-related-wrap img, .cbp-l-inline img {
	display: block;
	width: 100%;
	height: auto
}
.cbp-addItems {
	-webkit-transition: height .5s ease-in-out !important;
	transition: height .5s ease-in-out !important;
	will-change: height
}
.cbp-loading-fadeIn {
	-webkit-animation: fadeIn .6s ease-in;
	animation: fadeIn .6s ease-in
}
.cbp-panel {
	width: 94%;
	max-width: 1170px;
	margin: 0 auto
}
.cbp-misc-video {
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
	background: #000
}
.cbp-misc-video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}
.cbp-caption, .cbp-caption-defaultWrap, .cbp-caption-activeWrap {
	display: block
}
.cbp-caption-activeWrap {
	  cursor: default;
}
.cbp-caption-active .cbp-caption, .cbp-caption-active .cbp-caption-defaultWrap, .cbp-caption-active .cbp-caption-activeWrap {
	width: 100%;
	overflow: hidden;
	position: relative;
	z-index: 1
}
.cbp-caption-active .cbp-caption-defaultWrap {
	top: 0
}
.cbp-caption-active .cbp-caption-activeWrap {
	position: absolute;
	z-index: 2;
	height: 100%
}
.cbp-l-caption-title {
	color: #fff;
	font: 400 16px/21px "Open Sans", sans-serif
}
.cbp-l-caption-desc {
	color: #aaa;
	font: 400 12px/16px "Open Sans", sans-serif
}
.cbp-l-caption-text {
	font: 400 14px/21px "Open Sans", sans-serif;
	color: #fff;
	letter-spacing: 3px;
	padding: 0 6px
}
.cbp-l-caption-buttonLeft, .cbp-l-caption-buttonRight {
	background-color: #3288C4;
	color: #FFFFFF;
	display: inline-block;
	font: 400 12px/30px "Open Sans", sans-serif;
	min-width: 90px;
	text-align: center;
	margin: 4px;
	padding: 0 6px
}
.cbp-l-caption-buttonLeft:hover, .cbp-l-caption-buttonRight:hover {
	background-color: #2578B3;
	color: #fff
}
.cbp-caption-none .cbp-caption-activeWrap {
	display: none
}
.cbp-l-caption-alignLeft .cbp-l-caption-body {
	padding: 12px 30px
}
.cbp-caption-fadeIn .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-minimal .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-moveRight .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-overlayRightAlong .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-pushDown .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-pushTop .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-revealBottom .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-revealLeft .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-revealTop .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-zoom .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-opacity .cbp-l-caption-alignLeft .cbp-l-caption-body {
	padding-top: 30px
}
.cbp-l-caption-alignCenter {
	display: table;
	width: 100%;
	height: 100%
}
.cbp-l-caption-alignCenter .cbp-l-caption-body {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	padding: 10px 0
}
.cbp-l-caption-alignCenter .cbp-l-caption-buttonLeft, .cbp-l-caption-alignCenter .cbp-l-caption-buttonRight {
	position: relative;
	-webkit-transition: left .4s cubic-bezier(.19, 1, .22, 1);
	transition: left .4s cubic-bezier(.19, 1, .22, 1)
}
.cbp-caption-overlayBottom .cbp-l-caption-alignCenter .cbp-l-caption-buttonLeft, .cbp-caption-overlayBottomPush .cbp-l-caption-alignCenter .cbp-l-caption-buttonLeft, .cbp-caption-overlayBottomAlong .cbp-l-caption-alignCenter .cbp-l-caption-buttonLeft {
	left: -20px
}
.cbp-caption-overlayBottom .cbp-l-caption-alignCenter .cbp-l-caption-buttonRight, .cbp-caption-overlayBottomPush .cbp-l-caption-alignCenter .cbp-l-caption-buttonRight, .cbp-caption-overlayBottomAlong .cbp-l-caption-alignCenter .cbp-l-caption-buttonRight {
	left: 20px
}
.cbp-caption:hover .cbp-l-caption-alignCenter .cbp-l-caption-buttonLeft, .cbp-caption:hover .cbp-l-caption-alignCenter .cbp-l-caption-buttonRight {
	left: 0
}
.cbp-caption-fadeIn .cbp-caption-activeWrap {
	opacity: 0;
	background-color: rgba(0,0,0,0.85);
	-webkit-transition: opacity .6s cubic-bezier(.19, 1, .22, 1);
	transition: opacity .6s cubic-bezier(.19, 1, .22, 1)
}
.cbp-caption-fadeIn .cbp-caption:hover .cbp-caption-activeWrap {
	top: 0;
	opacity: 1
}
.cbp-caption-minimal .cbp-l-caption-title, .cbp-caption-minimal .cbp-l-caption-desc {
	position: relative;
	left: 0;
	opacity: 0;
	-webkit-transition: -webkit-transform .35s ease-out;
	transition: transform .35s ease-out
}
.cbp-caption-minimal .cbp-l-caption-title {
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%)
}
.cbp-caption-minimal .cbp-l-caption-desc {
	-webkit-transform: translateY(70%);
	transform: translateY(70%)
}
.cbp-caption-minimal .cbp-caption:hover .cbp-l-caption-title, .cbp-caption-minimal .cbp-caption:hover .cbp-l-caption-desc {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0)
}
.cbp-caption-minimal .cbp-caption-activeWrap {
	top: 0;
	background-color: black;
	background-color: rgba(0,0,0,0.8);
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"
}
.cbp-caption-minimal .cbp-caption:hover .cbp-caption-activeWrap {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)"
}
.cbp-caption-moveRight .cbp-caption-activeWrap {
	left: -100%;
	top: 0;
	-webkit-transition: -webkit-transform .6s cubic-bezier(.19, 1, .22, 1);
	transition: transform .6s cubic-bezier(.19, 1, .22, 1)
}
.cbp-caption-moveRight .cbp-caption:hover .cbp-caption-activeWrap {
	-webkit-transform: translateX(100%);
	transform: translateX(100%)
}
.cbp-caption-overlayBottom .cbp-caption-activeWrap {
	height: 60px;
	background-color: #181616;
	background-color: rgba(24,22,22,0.7);
	-webkit-transition: -webkit-transform .6s cubic-bezier(.19, 1, .22, 1);
	transition: transform .6s cubic-bezier(.19, 1, .22, 1)
}
.cbp-caption-overlayBottom .cbp-caption:hover .cbp-caption-activeWrap {
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%)
}
.cbp-caption-overlayBottomAlong .cbp-caption-defaultWrap, .cbp-caption-overlayBottomAlong .cbp-caption-activeWrap {
	-webkit-transition: -webkit-transform .6s cubic-bezier(.19, 1, .22, 1);
	transition: transform .6s cubic-bezier(.19, 1, .22, 1)
}
.cbp-caption-overlayBottomAlong .cbp-caption-activeWrap {
	height: 60px
}
.cbp-caption-overlayBottomAlong .cbp-caption:hover .cbp-caption-defaultWrap {
	-webkit-transform: translateY(-30px);
	transform: translateY(-30px)
}
.cbp-caption-overlayBottomAlong .cbp-caption:hover .cbp-caption-activeWrap {
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%)
}
.cbp-caption-overlayBottomPush .cbp-caption-defaultWrap {
	-webkit-transition: top .6s cubic-bezier(.19, 1, .22, 1);
	transition: top .6s cubic-bezier(.19, 1, .22, 1)
}
.cbp-caption-overlayBottomPush .cbp-caption-activeWrap {
	bottom: -60px;
	height: 60px;
	-webkit-transition: bottom .6s cubic-bezier(.19, 1, .22, 1);
	transition: bottom .6s cubic-bezier(.19, 1, .22, 1)
}
.cbp-caption-overlayBottomPush .cbp-caption:hover .cbp-caption-defaultWrap {
	top: -60px
}
.cbp-caption-overlayBottomPush .cbp-caption:hover .cbp-caption-activeWrap {
	bottom: 0
}
.cbp-caption-overlayBottomReveal .cbp-caption-defaultWrap {
	z-index: 2;
	-webkit-transition: top .5s cubic-bezier(.19, 1, .22, 1);
	transition: top .5s cubic-bezier(.19, 1, .22, 1)
}
.cbp-caption-overlayBottomReveal .cbp-caption-activeWrap {
	bottom: 0;
	z-index: 1;
	height: 60px
}
.cbp-caption-overlayBottomReveal .cbp-caption:hover .cbp-caption-defaultWrap {
	top: -60px
}
.cbp-caption-overlayRightAlong .cbp-caption-defaultWrap, .cbp-caption-overlayRightAlong .cbp-caption-activeWrap {
	-webkit-transition: -webkit-transform .6s cubic-bezier(.19, 1, .22, 1);
	transition: transform .6s cubic-bezier(.19, 1, .22, 1)
}
.cbp-caption-overlayRightAlong .cbp-caption-activeWrap {
	top: 0;
	left: -50%;
	width: 50%
}
.cbp-caption-overlayRightAlong .cbp-caption:hover .cbp-caption-defaultWrap {
	-webkit-transform: translateX(25%);
	transform: translateX(25%)
}
.cbp-caption-overlayRightAlong .cbp-caption:hover .cbp-caption-activeWrap {
	-webkit-transform: translateX(100%);
	transform: translateX(100%)
}
.cbp-caption-pushDown .cbp-caption-defaultWrap, .cbp-caption-pushDown .cbp-caption-activeWrap {
	-webkit-transition: -webkit-transform .6s cubic-bezier(.19, 1, .22, 1);
	transition: transform .6s cubic-bezier(.19, 1, .22, 1)
}
.cbp-caption-pushDown .cbp-caption-activeWrap {
	top: -102%;
	height: 102%
}
.cbp-caption-pushDown .cbp-caption:hover .cbp-caption-defaultWrap {
	-webkit-transform: translateY(100%);
	transform: translateY(100%)
}
.cbp-caption-pushDown .cbp-caption:hover .cbp-caption-activeWrap {
	-webkit-transform: translateY(99%);
	transform: translateY(99%)
}
.cbp-caption-pushTop .cbp-caption-activeWrap, .cbp-caption-pushTop .cbp-caption-defaultWrap {
	-webkit-transition: -webkit-transform .6s cubic-bezier(.19, 1, .22, 1);
	transition: transform .6s cubic-bezier(.19, 1, .22, 1)
}
.cbp-caption-pushTop .cbp-caption-activeWrap {
	height: 102%
}
.cbp-caption-pushTop .cbp-caption:hover .cbp-caption-defaultWrap {
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%)
}
.cbp-caption-pushTop .cbp-caption:hover .cbp-caption-activeWrap {
	-webkit-transform: translateY(-99%);
	transform: translateY(-99%)
}
.cbp-caption-revealBottom .cbp-caption-defaultWrap {
	z-index: 2;
	-webkit-transition: -webkit-transform .6s cubic-bezier(.19, 1, .22, 1);
	transition: transform .6s cubic-bezier(.19, 1, .22, 1)
}
.cbp-caption-revealBottom .cbp-caption-activeWrap {
	top: 0;
	z-index: 1
}
.cbp-caption-revealBottom .cbp-caption:hover .cbp-caption-defaultWrap {
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%)
}
.cbp-caption-revealLeft .cbp-caption-activeWrap {
	left: 100%;
	top: 0;
	-webkit-transition: -webkit-transform .6s cubic-bezier(.19, 1, .22, 1);
	transition: transform .6s cubic-bezier(.19, 1, .22, 1)
}
.cbp-caption-revealLeft .cbp-caption:hover .cbp-caption-activeWrap {
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%)
}
.cbp-caption-revealTop .cbp-caption-defaultWrap {
	z-index: 2;
	-webkit-transition: -webkit-transform .6s cubic-bezier(.19, 1, .22, 1);
	transition: transform .6s cubic-bezier(.19, 1, .22, 1)
}
.cbp-caption-revealTop .cbp-caption-activeWrap {
	top: 0;
	z-index: 1
}
.cbp-caption-revealTop .cbp-caption:hover .cbp-caption-defaultWrap {
	-webkit-transform: translateY(100%);
	transform: translateY(100%)
}
.cbp-caption-zoom .cbp-caption-defaultWrap {
	-webkit-transition: -webkit-transform .6s cubic-bezier(.19, 1, .22, 1);
	transition: transform .6s cubic-bezier(.19, 1, .22, 1)
}
.cbp-caption-zoom .cbp-caption:hover .cbp-caption-defaultWrap {
	-webkit-transform: scale(1.25);
	transform: scale(1.25)
}
.cbp-caption-zoom .cbp-caption-activeWrap {
	opacity: 0;
	background-color: rgba(0,0,0,0.9);
	-webkit-transition: opacity .8s cubic-bezier(.19, 1, .22, 1);
	transition: opacity .8s cubic-bezier(.19, 1, .22, 1)
}
.cbp-caption-zoom .cbp-caption:hover .cbp-caption-activeWrap {
	opacity: 1;
	top: 0
}
.cbp-caption-opacity .cbp-item {
	padding: 1px
}
.cbp-caption-opacity .cbp-caption, .cbp-caption-opacity .cbp-caption-activeWrap, .cbp-caption-opacity .cbp-caption-defaultWrap {
	background-color: transparent
}
.cbp-caption-opacity .cbp-caption {
	border: 1px solid transparent
}
.cbp-caption-opacity .cbp-caption:hover {
	border-color: #EDEDED
}
.cbp-caption-opacity .cbp-caption-defaultWrap {
	opacity: 1;
	-webkit-transition: opacity .6s cubic-bezier(.19, 1, .22, 1);
	transition: opacity .6s cubic-bezier(.19, 1, .22, 1)
}
.cbp-caption-opacity .cbp-caption:hover .cbp-caption-defaultWrap {
	opacity: .8
}
.cbp-caption-opacity .cbp-caption:hover .cbp-caption-activeWrap {
	top: 0
}
.cbp-caption-expand .cbp-caption, .cbp-caption-expand .cbp-caption-activeWrap, .cbp-caption-expand .cbp-caption-defaultWrap {
	background-color: transparent
}
.cbp-caption-expand .cbp-caption-activeWrap {
	height: auto
}
.cbp-caption-expand .cbp-caption {
	border-bottom: 1px dotted #eaeaea
}
.cbp-caption-expand .cbp-caption-defaultWrap {
	cursor: pointer;
	font: 500 15px/23px "Roboto", sans-serif;
	color: #474747;
	padding: 12px 0 11px 0
}
.cbp-caption-expand .cbp-caption-defaultWrap .fa {
	margin-right: 6px;
	width: 16px;
	text-align: center;
	font-size: 14px
}
.cbp-caption-expand .cbp-caption-defaultWrap .fa-angle-down {
	position: absolute;
	right: 0;
	margin-top: 4px;
	width: 16px;
	text-align: center;
	font-size: 14px
}
.cbp-caption-expand .cbp-l-caption-body {
	font: 400 13px/21px "Roboto", sans-serif;
	color: #888;
	padding: 0 0 20px 26px
}
.cbp-caption-expand-active {
	transition: height .4s !important
}
.cbp-caption-expand-active .cbp-item {
	transition: left .4s, top .4s !important
}
.cbp-caption-expand-open .cbp-caption-activeWrap {
	transition: height .4s
}
.cbp-l-filters-alignCenter {
	margin-bottom: 40px;
	text-align: center;
	font: 400 12px/21px sans-serif;
	color: #DADADA
}
.cbp-l-filters-alignCenter .cbp-filter-item {
	color: #949494;
	cursor: pointer;
	font: 400 13px/21px "Open Sans", sans-serif;
	padding: 0 12px;
	transition: all .3s ease-in-out;
	position: relative;
	overflow: visible;
	margin: 10px 0 0;
	display: inline-block
}
.cbp-l-filters-alignCenter .cbp-filter-item:hover {
	color: #2D2C2C
}
.cbp-l-filters-alignCenter .cbp-filter-item.cbp-filter-item-active {
	color: #2D2C2C;
	cursor: default
}
.cbp-l-filters-alignCenter .cbp-filter-counter {
	background: none repeat scroll 0 0 #626161;
	border-radius: 3px;
	color: #FFFFFF;
	font: 400 11px/18px "Open Sans", sans-serif;
	margin: 0 auto;
	padding: 4px 0;
	text-align: center;
	width: 34px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	opacity: 0;
	-webkit-transition: all .25s ease;
	transition: all .25s ease
}
.cbp-l-filters-alignCenter .cbp-filter-counter:before {
	content: "";
	position: absolute;
	bottom: -4px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #626161;
	display: none
}
.cbp-l-filters-alignCenter .cbp-filter-item:hover .cbp-filter-counter:before {
	display: block
}
.cbp-l-filters-alignCenter .cbp-filter-item:hover .cbp-filter-counter {
	bottom: 30px;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	opacity: 1
}
.cbp-l-filters-alignLeft {
	margin-bottom: 40px
}
.cbp-l-filters-alignLeft .cbp-filter-item {
	background-color: #fff;
	border: 1px solid #cdcdcd;
	cursor: pointer;
	font: 400 12px/30px "Open Sans", sans-serif;
	padding: 0 13px;
	position: relative;
	overflow: visible;
	margin: 10px 8px 0 0;
	display: inline-block;
	color: #888888;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}
.cbp-l-filters-alignLeft .cbp-filter-item:hover {
	color: #111
}
.cbp-l-filters-alignLeft .cbp-filter-item.cbp-filter-item-active {
	background-color: #6C7A89;
	border: 1px solid #6C7A89;
	color: #fff;
	cursor: default
}
.cbp-l-filters-alignLeft .cbp-filter-counter {
	display: inline
}
.cbp-l-filters-alignRight {
	margin-bottom: 40px;
	text-align: right
}
.cbp-l-filters-alignRight .cbp-filter-item {
	background-color: transparent;
	color: #8B8B8B;
	cursor: pointer;
	font: 400 11px/29px "Open Sans", sans-serif;
	padding: 0 12px;
	position: relative;
	overflow: visible;
	margin: 10px 0 0 5px;
	border: 1px solid #E4E2E2;
	text-transform: uppercase;
	display: inline-block;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out
}
.cbp-l-filters-alignRight .cbp-filter-item:hover {
	color: #2B3444
}
.cbp-l-filters-alignRight .cbp-filter-item:hover .cbp-filter-counter:before {
	display: block
}
.cbp-l-filters-alignRight .cbp-filter-item:hover .cbp-filter-counter {
	bottom: 44px;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	opacity: 1
}
.cbp-l-filters-alignRight .cbp-filter-item.cbp-filter-item-active {
	color: #FFFFFF;
	background-color: #049372;
	border-color: #049372;
	cursor: default
}
.cbp-l-filters-alignRight .cbp-filter-counter {
	background: none repeat scroll 0 0 #049372;
	border-radius: 3px;
	color: #fff;
	font: 400 11px/18px "Open Sans", sans-serif;
	margin: 0 auto;
	padding: 4px 0;
	text-align: center;
	width: 34px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	opacity: 0;
	-webkit-transition: opacity .25s ease, bottom .25s ease;
	transition: opacity .25s ease, bottom .25s ease
}
.cbp-l-filters-alignRight .cbp-filter-counter:before {
	content: "";
	position: absolute;
	bottom: -4px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #049372;
	display: none
}
.cbp-l-filters-button {
	margin-bottom: 30px
}
.cbp-l-filters-button .cbp-filter-item {
	background-color: #FFFFFF;
	border: 2px solid #ECECEC;
	border-radius: 2px;
	color: #888888;
	cursor: pointer;
	font: 400 12px/29px "Open Sans", sans-serif;
	margin: 10px 10px 0 0;
	overflow: visible;
	padding: 0 17px;
	position: relative;
	display: inline-block;
	-webkit-transition: color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out;
	transition: color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out
}
.cbp-l-filters-button .cbp-filter-item:hover {
	color: #5d5d5d
}
.cbp-l-filters-button .cbp-filter-item.cbp-filter-item-active {
	background-color: #4D4C4D;
	color: #fff;
	border-color: #4D4C4D;
	cursor: default
}
.cbp-l-filters-button .cbp-filter-counter {
	background-color: #4D4C4D;
	border-radius: 3px;
	color: #fff;
	font: 400 11px/18px "Open Sans", sans-serif;
	margin: 0 auto;
	padding: 4px 0;
	text-align: center;
	width: 34px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: opacity .25s ease, bottom .25s ease;
	transition: opacity .25s ease, bottom .25s ease
}
.cbp-l-filters-button .cbp-filter-counter:before {
	content: "";
	position: absolute;
	bottom: -4px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #4D4C4D;
	display: none
}
.cbp-l-filters-button .cbp-filter-item:hover .cbp-filter-counter:before {
	display: block
}
.cbp-l-filters-button .cbp-filter-item:hover .cbp-filter-counter {
	bottom: 44px;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	opacity: 1
}
.cbp-l-filters-buttonCenter {
	margin-bottom: 40px;
	text-align: center
}
.cbp-l-filters-buttonCenter .cbp-filter-item {
	background-color: #FFFFFF;
	border: 1px solid #ECECEC;
	color: #888888;
	cursor: pointer;
	font: 400 12px/32px Roboto, sans-serif;
	margin: 10px 10px 0 0;
	overflow: visible;
	padding: 0 17px;
	position: relative;
	display: inline-block;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out
}
.cbp-l-filters-buttonCenter .cbp-filter-item:hover {
	color: #5d5d5d
}
.cbp-l-filters-buttonCenter .cbp-filter-item.cbp-filter-item-active {
	color: #3B9CB3;
	border-color: #8CD2E5;
	cursor: default
}
.cbp-l-filters-buttonCenter .cbp-filter-counter {
	background-color: #68ABBC;
	border-radius: 3px;
	color: #fff;
	font: 400 11px/18px Roboto, sans-serif;
	margin: 0 auto;
	padding: 4px 0;
	text-align: center;
	width: 34px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: opacity .25s ease, bottom .25s ease;
	transition: opacity .25s ease, bottom .25s ease
}
.cbp-l-filters-buttonCenter .cbp-filter-counter:before {
	content: "";
	position: absolute;
	bottom: -4px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #68ABBC;
	display: none
}
.cbp-l-filters-buttonCenter .cbp-filter-item:hover .cbp-filter-counter:before {
	display: block
}
.cbp-l-filters-buttonCenter .cbp-filter-item:hover .cbp-filter-counter {
	bottom: 44px;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	opacity: 1
}
.cbp-l-filters-dropdown {
	margin-bottom: 40px;
	height: 38px;
	position: relative;
	z-index: 5
}
.cbp-l-filters-dropdownWrap {
	width: 200px;
	position: absolute;
	right: 0;
	background: #3288C4
}
.cbp-l-filters-dropdownHeader {
	font: 400 12px/38px "Open Sans", sans-serif;
	margin: 0 17px;
	color: #FFF;
	cursor: default;
	position: relative
}
.cbp-l-filters-dropdownHeader:after {
	border-color: #FFFFFF transparent;
	border-style: solid;
	border-width: 5px 5px 0;
	content: "";
	height: 0;
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	margin-top: -1px
}
.cbp-l-filters-dropdownWrap.cbp-l-filters-dropdownWrap-open .cbp-l-filters-dropdownHeader:after {
	border-width: 0 5px 5px
}
.cbp-l-filters-dropdownList {
	display: none;
	list-style: none outside none;
	margin: 0;
	padding: 0
}
.cbp-l-filters-dropdownList>li {
	margin: 0;
	list-style: none
}
.cbp-l-filters-dropdownWrap.cbp-l-filters-dropdownWrap-open .cbp-l-filters-dropdownList {
	display: block;
	margin: 0
}
.cbp-l-filters-dropdownList .cbp-filter-item {
	background: none;
	color: #9FBCE7;
	width: 100%;
	text-align: left;
	font: 400 12px/40px "Open Sans", sans-serif;
	margin: 0;
	padding: 0 17px;
	cursor: pointer;
	border: none;
	border-top: 1px solid #5592C2
}
.cbp-l-filters-dropdownList .cbp-filter-item:hover {
	color: #e6e6e6
}
.cbp-l-filters-dropdownList .cbp-filter-item-active {
	color: #e6e6e6;
	cursor: default
}
.cbp-l-filters-dropdownList .cbp-filter-counter {
	display: inline
}
.cbp-l-filters-list {
	margin-bottom: 40px;
	content: "";
	display: table;
	clear: both;
	  border-bottom: solid 1px #bcbcbc;
	  width: 100%;
}
.cbp-l-filters-list .cbp-filter-item {
	background-color: transparent;
	color: #222;
	cursor: pointer;
	font: 400 12px/35px "Open Sans", sans-serif;
	margin:0 70px 0 0;
	position: relative;
	overflow: visible;
	margin-top: 10px;
	float: left;

	border-right-width: 0;
	-webkit-transition: left .3s ease-in-out;
	transition: left .3s ease-in-out;
	font-size: 18px;
  font-family: 'Raleway', sans-serif;
  padding: 15px 0px;
  font-weight: 500;
}
.cbp-l-filters-list .cbp-filter-item:hover {
  color: #222;
  background-color: #fff;
  
}
.cbp-l-filters-list .cbp-filter-item.cbp-filter-item-active {
	cursor: default;
	background-color: #fff;
}
.cbp-l-filters-list-first {
}
.cbp-l-filters-list .cbp-filter-counter {
	display: inline
}
.cbp-l-filters-work {
	margin-bottom: 40px;
	text-align: center
}
.cbp-l-filters-work .cbp-filter-item {
	background-color: #FFFFFF;
	color: #888;
	cursor: pointer;
	font: 600 11px/37px "Open Sans", sans-serif;
	margin: 10px 5px 0 0;
	overflow: visible;
	padding: 0 16px;
	position: relative;
	display: inline-block;
	text-transform: uppercase;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out
}
.cbp-l-filters-work .cbp-filter-item:hover {
	color: #fff;
	background: #607D8B
}
.cbp-l-filters-work .cbp-filter-item.cbp-filter-item-active {
	background: #607D8B;
	color: #fff;
	cursor: default
}
.cbp-l-filters-work .cbp-filter-counter {
	font: 600 11px/37px "Open Sans", sans-serif;
	text-align: center;
	display: inline-block;
	margin-left: 8px
}
.cbp-l-filters-work .cbp-filter-counter:before {
	content: '('
}
.cbp-l-filters-work .cbp-filter-counter:after {
	content: ')'
}
.cbp-l-filters-big {
	margin-bottom: 40px;
	text-align: center
}
.cbp-l-filters-big .cbp-filter-item {
	color: #444;
	cursor: pointer;
	font: 400 15px/22px Roboto, sans-serif;
	margin: 10px 15px 0 0;
	padding: 10px 23px;
	position: relative;
	display: inline-block;
	border: 1px solid transparent;
	text-transform: uppercase;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out
}
.cbp-l-filters-big .cbp-filter-item:hover {
	color: #888
}
.cbp-l-filters-big .cbp-filter-item.cbp-filter-item-active {
	border-color: #d5d5d5;
	cursor: default;
	color: #444
}
.cbp-l-filters-text {
	margin-bottom: 45px;
	text-align: center;
	font: 400 12px/21px Lato, sans-serif;
	color: #DADADA;
	padding: 0 15px
}
.cbp-l-filters-text .cbp-filter-item {
	color: #949494;
	cursor: pointer;
	font: 400 13px/21px Lato, sans-serif;
	padding: 0 12px;
	transition: all .3s ease-in-out;
	position: relative;
	overflow: visible;
	margin: 10px 0 0;
	display: inline-block
}
.cbp-l-filters-text .cbp-filter-item:hover {
	color: #2D2C2C
}
.cbp-l-filters-text .cbp-filter-item.cbp-filter-item-active {
	color: #2D2C2C;
	cursor: default
}
.cbp-l-filters-text .cbp-filter-counter {
	background: none repeat scroll 0 0 #626161;
	border-radius: 3px;
	color: #FFFFFF;
	font: 400 11px/16px Lato, sans-serif;
	margin: 0 auto;
	padding: 4px 0;
	text-align: center;
	width: 32px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	opacity: 0;
	-webkit-transition: all .25s ease;
	transition: all .25s ease
}
.cbp-l-filters-text .cbp-filter-counter:before {
	content: "";
	position: absolute;
	bottom: -4px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #626161;
	display: none
}
.cbp-l-filters-text .cbp-filter-item:hover .cbp-filter-counter:before {
	display: block
}
.cbp-l-filters-text .cbp-filter-item:hover .cbp-filter-counter {
	bottom: 30px;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	opacity: 1
}
.cbp-l-filters-text-sort {
	display: inline-block;
	font: 400 13px/21px Lato, sans-serif;
	color: #949494;
	margin-right: 15px
}
.cbp-l-filters-underline {
	margin-bottom: 40px
}
.cbp-l-filters-underline .cbp-filter-item {
	border-bottom: 3px solid transparent;
	cursor: pointer;
	font: 600 14px/21px "Open Sans", sans-serif;
	padding: 8px 10px;
	position: relative;
	overflow: visible;
	margin: 10px 20px 0 0;
	display: inline-block;
	color: #787878;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}
.cbp-l-filters-underline .cbp-filter-item:hover {
	color: #111
}
.cbp-l-filters-underline .cbp-filter-item.cbp-filter-item-active {
	border-bottom-color: #666;
	color: #444;
	cursor: default
}
.cbp-l-filters-underline .cbp-filter-counter {
	display: inline
}
.cbp-animation-quicksand {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-quicksand .cbp-item {
	-webkit-transition: -webkit-transform .6s ease-in-out;
	transition: transform .6s ease-in-out
}
.cbp-animation-quicksand .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-quicksand .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-quicksand .cbp-item-on2off .cbp-item-wrapper {
	-webkit-animation: quicksand-off .6s ease-out both;
	animation: quicksand-off .6s ease-out both
}
.cbp-animation-quicksand .cbp-item-off2on .cbp-item-wrapper {
	-webkit-animation: quicksand-on .6s ease-out both;
	animation: quicksand-on .6s ease-out both
}
@-webkit-keyframes quicksand-off {
100% {
opacity:0;
-webkit-transform:scale3d(0, 0, 0)
}
}
@keyframes quicksand-off {
100% {
opacity:0;
transform:scale3d(0, 0, 0)
}
}
@-webkit-keyframes quicksand-on {
0% {
opacity:0;
-webkit-transform:scale3d(0, 0, 0)
}
}
@keyframes quicksand-on {
0% {
opacity:0;
transform:scale3d(0, 0, 0)
}
}
.cbp-animation-fadeOut, .cbp-animation-boxShadow {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-fadeOut .cbp-item, .cbp-animation-boxShadow .cbp-item {
	-webkit-transition: -webkit-transform .6s ease-in-out;
	transition: transform .6s ease-in-out
}
.cbp-animation-fadeOut .cbp-item, .cbp-animation-boxShadow .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-fadeOut .cbp-item-wrapper, .cbp-animation-boxShadow .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-fadeOut .cbp-item-on2off .cbp-item-wrapper, .cbp-animation-boxShadow .cbp-item-on2off .cbp-item-wrapper {
	-webkit-animation: fadeOut-off .6s ease-in-out both;
	animation: fadeOut-off .6s ease-in-out both
}
.cbp-animation-fadeOut .cbp-item-off2on .cbp-item-wrapper, .cbp-animation-boxShadow .cbp-item-off2on .cbp-item-wrapper {
	-webkit-animation: fadeOut-on .6s ease-in-out both;
	animation: fadeOut-on .6s ease-in-out both
}
@-webkit-keyframes fadeOut-off {
0% {
opacity:1
}
80%, 100% {
opacity:0
}
}
@keyframes fadeOut-off {
0% {
opacity:1
}
80%, 100% {
opacity:0
}
}
@-webkit-keyframes fadeOut-on {
0% {
opacity:0
}
100% {
opacity:1
}
}
@keyframes fadeOut-on {
0% {
opacity:0
}
100% {
opacity:1
}
}
.cbp-animation-flipOut {
	-webkit-transition: height .7s ease-in-out;
	transition: height .7s ease-in-out;
	will-change: height
}
.cbp-animation-flipOut .cbp-item {
	-webkit-transition: -webkit-transform .7s ease-in-out;
	transition: transform .7s ease-in-out
}
.cbp-animation-flipOut .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-flipOut .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-flipOut .cbp-item-on2off .cbp-item-wrapper {
	-webkit-animation: flipOut-out .7s both ease-in;
	animation: flipOut-out .7s both ease-in
}
.cbp-animation-flipOut .cbp-item-off2on .cbp-item-wrapper {
	-webkit-animation: flipOut-in .7s ease-out both;
	animation: flipOut-in .7s ease-out both
}
@-webkit-keyframes flipOut-out {
50%, 100% {
-webkit-transform:translateZ(-1000px) rotateY(-90deg);
opacity:0.2
}
}
@keyframes flipOut-out {
50%, 100% {
transform:translateZ(-1000px) rotateY(-90deg);
opacity:0.2
}
}
@-webkit-keyframes flipOut-in {
0%, 50% {
-webkit-transform:translateZ(-1000px) rotateY(90deg);
opacity:0.2
}
}
@keyframes flipOut-in {
0%, 50% {
transform:translateZ(-1000px) rotateY(90deg);
opacity:0.2
}
}
.cbp-animation-flipBottom {
	-webkit-transition: height .7s ease-in-out;
	transition: height .7s ease-in-out;
	will-change: height
}
.cbp-animation-flipBottom .cbp-item {
	-webkit-transition: -webkit-transform .7s ease-in-out;
	transition: transform .7s ease-in-out
}
.cbp-animation-flipBottom .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-flipBottom .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-flipBottom .cbp-item-on2off .cbp-item-wrapper {
	-webkit-animation: flipBottom-out .7s both ease-in;
	animation: flipBottom-out .7s both ease-in
}
.cbp-animation-flipBottom .cbp-item-off2on .cbp-item-wrapper {
	-webkit-animation: flipBottom-in .7s ease-out both;
	animation: flipBottom-in .7s ease-out both
}
@-webkit-keyframes flipBottom-out {
50%, 100% {
-webkit-transform:translateZ(-1000px) rotateX(-90deg);
opacity:0.2
}
}
@keyframes flipBottom-out {
50%, 100% {
transform:translateZ(-1000px) rotateX(-90deg);
opacity:0.2
}
}
@-webkit-keyframes flipBottom-in {
0%, 50% {
-webkit-transform:translateZ(-1000px) rotateX(90deg);
opacity:0.2
}
}
@keyframes flipBottom-in {
0%, 50% {
transform:translateZ(-1000px) rotateX(90deg);
opacity:0.2
}
}
.cbp-animation-scaleSides {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-scaleSides .cbp-item {
	-webkit-transition: -webkit-transform .6s ease-in-out;
	transition: transform .6s ease-in-out
}
.cbp-animation-scaleSides .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-scaleSides .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-scaleSides .cbp-item-on2off .cbp-item-wrapper {
	-webkit-animation: scaleSides-out .9s ease both;
	animation: scaleSides-out .9s ease both
}
.cbp-animation-scaleSides .cbp-item-off2on .cbp-item-wrapper {
	-webkit-animation: scaleSides-in .9s ease both;
	animation: scaleSides-in .9s ease both
}
@-webkit-keyframes scaleSides-out {
50%, 100% {
-webkit-transform:scale(.6);
opacity:0
}
}
@keyframes scaleSides-out {
50%, 100% {
transform:scale(.6);
opacity:0
}
}
@-webkit-keyframes scaleSides-in {
0%, 50% {
-webkit-transform:scale(.6);
opacity:0
}
}
@keyframes scaleSides-in {
0%, 50% {
transform:scale(.6);
opacity:0
}
}
.cbp-animation-skew {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-skew .cbp-item {
	-webkit-transition: -webkit-transform .6s ease-in-out;
	transition: transform .6s ease-in-out
}
.cbp-animation-skew .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-skew .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-skew .cbp-item-on2off .cbp-item-wrapper {
	-webkit-animation: skew-off .6s ease-out both;
	animation: skew-off .6s ease-out both
}
.cbp-animation-skew .cbp-item-off2on .cbp-item-wrapper {
	-webkit-animation: skew-on .6s ease-out both;
	animation: skew-on .6s ease-out both
}
@-webkit-keyframes skew-off {
100% {
opacity:0;
-webkit-transform:scale3d(0, 0, 0) skew(20deg, 0)
}
}
@keyframes skew-off {
100% {
opacity:0;
transform:scale3d(0, 0, 0) skew(20deg, 0)
}
}
@-webkit-keyframes skew-on {
0% {
opacity:0;
-webkit-transform:scale3d(0, 0, 0) skew(0, 20deg)
}
}
@keyframes skew-on {
0% {
opacity:0;
transform:scale3d(0, 0, 0) skew(0, 20deg)
}
}
.cbp-animation-fadeOutTop {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-fadeOutTop .cbp-wrapper-outer {
	overflow: visible
}
.cbp-animation-fadeOutTop .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px;
	overflow: visible
}
.cbp-animation-fadeOutTop .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-fadeOutTop .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-animation: fadeOutTop-out .6s both ease-in-out;
	animation: fadeOutTop-out .6s both ease-in-out
}
.cbp-animation-fadeOutTop .cbp-wrapper .cbp-item-wrapper {
	-webkit-animation: fadeOutTop-in .6s both ease-in-out;
	animation: fadeOutTop-in .6s both ease-in-out
}
@-webkit-keyframes fadeOutTop-out {
0% {
-webkit-transform:translateY(0);
opacity:1
}
50%, 100% {
-webkit-transform:translateY(-30px);
opacity:0
}
}
@keyframes fadeOutTop-out {
0% {
transform:translateY(0);
opacity:1
}
50%, 100% {
transform:translateY(-30px);
opacity:0
}
}
@-webkit-keyframes fadeOutTop-in {
0%, 50% {
-webkit-transform:translateY(-30px);
opacity:0
}
100% {
-webkit-transform:translateY(0);
opacity:1
}
}
@keyframes fadeOutTop-in {
0%, 50% {
transform:translateY(-30px);
opacity:0
}
100% {
transform:translateY(0);
opacity:1
}
}
.cbp-animation-slideLeft {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-slideLeft .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-slideLeft .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-slideLeft .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-animation: slideLeft-out .8s both ease-in-out;
	animation: slideLeft-out .8s both ease-in-out
}
.cbp-animation-slideLeft .cbp-wrapper .cbp-item-wrapper {
	-webkit-animation: slideLeft-in .8s both ease-in-out;
	animation: slideLeft-in .8s both ease-in-out
}
@-webkit-keyframes slideLeft-out {
0% {
opacity:1;
transform:scale(1)
}
25% {
opacity:.75;
-webkit-transform:scale(.8)
}
75% {
opacity:.75;
-webkit-transform:scale(.8) translateX(-200%)
}
100% {
opacity:.75;
-webkit-transform:scale(.8) translateX(-200%)
}
}
@keyframes slideLeft-out {
0% {
opacity:1;
transform:scale(1)
}
25% {
opacity:.75;
transform:scale(.8)
}
75% {
opacity:.75;
transform:scale(.8) translateX(-200%)
}
100% {
opacity:.75;
transform:scale(.8) translateX(-200%)
}
}
@-webkit-keyframes slideLeft-in {
0%, 25% {
opacity:.75;
-webkit-transform:scale(.8) translateX(200%)
}
75% {
opacity:.75;
-webkit-transform:scale(.8)
}
100% {
opacity:1;
-webkit-transform:scale(1) translateX(0)
}
}
@keyframes slideLeft-in {
0%, 25% {
opacity:.75;
transform:scale(.8) translateX(200%)
}
75% {
opacity:.75;
transform:scale(.8)
}
100% {
opacity:1;
transform:scale(1) translateX(0)
}
}
.cbp-animation-sequentially {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-sequentially .cbp-wrapper-outer {
	overflow: visible
}
.cbp-animation-sequentially .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px;
	overflow: visible
}
.cbp-animation-sequentially .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-sequentially .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-animation: fadeOutTop-out .6s both ease;
	animation: fadeOutTop-out .6s both ease
}
.cbp-animation-sequentially .cbp-wrapper .cbp-item-wrapper {
	-webkit-animation: fadeOutTop-in .6s both ease-out;
	animation: fadeOutTop-in .6s both ease-out
}
.cbp-animation-3dflip {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-3dflip .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-3dflip .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-3dflip .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-transform-origin: 0% 50%;
	transform-origin: 0% 50%;
	-webkit-animation: flip-out 0.6s both ease-in-out;
	animation: flip-out 0.6s both ease-in-out
}
.cbp-animation-3dflip .cbp-wrapper .cbp-item-wrapper {
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
	-webkit-animation: flip-in 0.6s both ease-in-out;
	animation: flip-in 0.6s both ease-in-out
}
@-webkit-keyframes flip-out {
100% {
opacity:0;
-webkit-transform:rotateY(90deg)
}
}
@keyframes flip-out {
100% {
opacity:0;
transform:rotateY(90deg)
}
}
@-webkit-keyframes flip-in {
0% {
opacity:0;
-webkit-transform:rotateY(-90deg)
}
100% {
opacity:1;
-webkit-transform:rotateY(0deg)
}
}
@keyframes flip-in {
0% {
opacity:0;
transform:rotateY(-90deg)
}
100% {
opacity:1;
transform:rotateY(0deg)
}
}
.cbp-animation-flipOutDelay {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-flipOutDelay .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-flipOutDelay .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-flipOutDelay .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-animation: flipOut-out 1s both ease-in;
	animation: flipOut-out 1s both ease-in
}
.cbp-animation-flipOutDelay .cbp-wrapper .cbp-item-wrapper {
	-webkit-animation: flipOut-in 1s both ease-out;
	animation: flipOut-in 1s both ease-out
}
.cbp-animation-slideDelay {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-slideDelay .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-slideDelay .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-slideDelay .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-animation: slideDelay-out 0.5s both ease-in-out;
	animation: slideDelay-out 0.5s both ease-in-out
}
.cbp-animation-slideDelay .cbp-wrapper .cbp-item-wrapper {
	-webkit-animation: slideDelay-in 0.5s both ease-in-out;
	animation: slideDelay-in 0.5s both ease-in-out
}
@-webkit-keyframes slideDelay-out {
100% {
-webkit-transform:translateX(-100%)
}
}
@keyframes slideDelay-out {
100% {
transform:translateX(-100%)
}
}
@-webkit-keyframes slideDelay-in {
0% {
-webkit-transform:translateX(100%)
}
100% {
-webkit-transform:translateX(0)
}
}
@keyframes slideDelay-in {
0% {
transform:translateX(100%)
}
100% {
transform:translateX(0)
}
}
.cbp-animation-rotateSides {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-rotateSides .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-rotateSides .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-rotateSides .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-transform-origin: -50% 50%;
	-webkit-animation: rotateSides-out .5s both ease-in;
	transform-origin: -50% 50%;
	animation: rotateSides-out .5s both ease-in
}
.cbp-animation-rotateSides .cbp-wrapper .cbp-item-wrapper {
	-webkit-transform-origin: 150% 50%;
	-webkit-animation: rotateSides-in .6s both ease-out;
	transform-origin: 150% 50%;
	animation: rotateSides-in .6s both ease-out
}
@-webkit-keyframes rotateSides-out {
100% {
opacity:0;
-webkit-transform:translateZ(-500px) rotateY(90deg)
}
}
@keyframes rotateSides-out {
100% {
opacity:0;
transform:translateZ(-500px) rotateY(90deg)
}
}
@-webkit-keyframes rotateSides-in {
0% {
opacity:0;
-webkit-transform:translateZ(-500px) rotateY(-90deg)
}
40% {
opacity:0;
-webkit-transform:translateZ(-500px) rotateY(-90deg)
}
}
@keyframes rotateSides-in {
0% {
opacity:0;
transform:translateZ(-500px) rotateY(-90deg)
}
40% {
opacity:0;
transform:translateZ(-500px) rotateY(-90deg)
}
}
.cbp-animation-foldLeft {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-foldLeft .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-foldLeft .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-foldLeft .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
	-webkit-animation: foldLeft-out .7s both ease;
	animation: foldLeft-out .7s both ease
}
.cbp-animation-foldLeft .cbp-wrapper .cbp-item-wrapper {
	-webkit-animation: foldLeft-in .7s both ease;
	animation: foldLeft-in .7s ease both
}
@-webkit-keyframes foldLeft-out {
100% {
opacity:0;
-webkit-transform:translateX(-100%) rotateY(-90deg)
}
}
@keyframes foldLeft-out {
100% {
opacity:0;
transform:translateX(-100%) rotateY(-90deg)
}
}
@-webkit-keyframes foldLeft-in {
0% {
opacity:0.3;
-webkit-transform:translateX(100%)
}
}
@keyframes foldLeft-in {
0% {
opacity:0.3;
transform:translateX(100%)
}
}
.cbp-animation-unfold {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-unfold .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-unfold .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-unfold .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-animation: unfold-out .8s ease both;
	animation: unfold-out .8s ease both
}
.cbp-animation-unfold .cbp-wrapper .cbp-item-wrapper {
	-webkit-transform-origin: 0% 50%;
	-webkit-animation: unfold-in .8s both ease;
	transform-origin: 0% 50%;
	animation: unfold-in .8s both ease
}
@-webkit-keyframes unfold-out {
90% {
opacity:0.3
}
100% {
opacity:0;
-webkit-transform:translateX(-100%)
}
}
@keyframes unfold-out {
90% {
opacity:0.3
}
100% {
opacity:0;
transform:translateX(-100%)
}
}
@-webkit-keyframes unfold-in {
0% {
opacity:0;
-webkit-transform:translateX(100%) rotateY(90deg)
}
}
@keyframes unfold-in {
0% {
opacity:0;
transform:translateX(100%) rotateY(90deg)
}
}
.cbp-animation-scaleDown {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-scaleDown .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-scaleDown .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-scaleDown .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-animation: scaleDown-out .7s ease both;
	animation: scaleDown-out .7s ease both
}
.cbp-animation-scaleDown .cbp-wrapper .cbp-item-wrapper {
	-webkit-animation: scaleDown-in .6s ease both;
	animation: scaleDown-in .6s ease both
}
@-webkit-keyframes scaleDown-out {
100% {
opacity:0;
-webkit-transform:scale(.8)
}
}
@keyframes scaleDown-out {
100% {
opacity:0;
transform:scale(.8)
}
}
@-webkit-keyframes scaleDown-in {
0% {
-webkit-transform:translateX(100%)
}
}
@keyframes scaleDown-in {
0% {
transform:translateX(100%)
}
}
.cbp-animation-frontRow {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-frontRow .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-frontRow .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-frontRow .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-animation: frontRow-out .7s both ease;
	animation: frontRow-out .7s both ease
}
.cbp-animation-frontRow .cbp-wrapper .cbp-item-wrapper {
	-webkit-animation: frontRow-in .6s both ease;
	animation: frontRow-in .6s both ease
}
@-webkit-keyframes frontRow-out {
100% {
-webkit-transform:translateX(-60%) scale(.8);
opacity:0
}
}
@keyframes frontRow-out {
100% {
transform:translateX(-60%) scale(.8);
opacity:0
}
}
@-webkit-keyframes frontRow-in {
0% {
-webkit-transform:translateX(100%) scale(.8)
}
100% {
opacity:1;
-webkit-transform:translateX(0%) scale(1)
}
}
@keyframes frontRow-in {
0% {
transform:translateX(100%) scale(.8)
}
100% {
opacity:1;
transform:translateX(0%) scale(1)
}
}
.cbp-animation-rotateRoom {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-rotateRoom .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-rotateRoom .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-rotateRoom .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
	-webkit-animation: rotateRoom-out .8s both ease;
	animation: rotateRoom-out .8s both ease
}
.cbp-animation-rotateRoom .cbp-wrapper .cbp-item-wrapper {
	-webkit-transform-origin: 0% 50%;
	transform-origin: 0% 50%;
	-webkit-animation: rotateRoom-in .8s both ease;
	animation: rotateRoom-in .8s both ease
}
@-webkit-keyframes rotateRoom-out {
90% {
opacity:.3
}
100% {
opacity:0;
-webkit-transform:translateX(-100%) rotateY(90deg)
}
}
@keyframes rotateRoom-out {
90% {
opacity:.3
}
100% {
opacity:0;
transform:translateX(-100%) rotateY(90deg)
}
}
@-webkit-keyframes rotateRoom-in {
0% {
opacity:.3;
-webkit-transform:translateX(100%) rotateY(-90deg)
}
}
@keyframes rotateRoom-in {
0% {
opacity:.3;
transform:translateX(100%) rotateY(-90deg)
}
}
.cbp-animation-bounceBottom {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-bounceBottom .cbp-wrapper-helper {
	-webkit-animation: bounceBottom-out .6s both ease-in-out;
	animation: bounceBottom-out .6s both ease-in-out
}
.cbp-animation-bounceBottom .cbp-wrapper {
	-webkit-animation: bounceBottom-in .6s both ease-in-out;
	animation: bounceBottom-in .6s both ease-in-out
}
@-webkit-keyframes bounceBottom-out {
100% {
-webkit-transform:translateY(100%);
opacity:0
}
}
@keyframes bounceBottom-out {
100% {
transform:translateY(100%);
opacity:0
}
}
@-webkit-keyframes bounceBottom-in {
0% {
-webkit-transform:translateY(100%);
opacity:0
}
100% {
-webkit-transform:translateY(0);
opacity:1
}
}
@keyframes bounceBottom-in {
0% {
transform:translateY(100%);
opacity:0
}
100% {
transform:translateY(0);
opacity:1
}
}
.cbp-animation-bounceLeft {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-bounceLeft .cbp-wrapper-helper {
	-webkit-animation: bounceLeft-out .6s both ease-in-out;
	animation: bounceLeft-out .6s both ease-in-out
}
.cbp-animation-bounceLeft .cbp-wrapper {
	-webkit-animation: bounceLeft-in .6s both ease-in-out;
	animation: bounceLeft-in .6s both ease-in-out
}
@-webkit-keyframes bounceLeft-out {
100% {
-webkit-transform:translateX(-100%);
opacity:0
}
}
@keyframes bounceLeft-out {
100% {
transform:translateX(-100%);
opacity:0
}
}
@-webkit-keyframes bounceLeft-in {
0% {
-webkit-transform:translateX(-100%);
opacity:0
}
100% {
-webkit-transform:translateX(0);
opacity:1
}
}
@keyframes bounceLeft-in {
0% {
transform:translateX(-100%);
opacity:0
}
100% {
transform:translateX(0);
opacity:1
}
}
.cbp-animation-bounceTop {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-bounceTop .cbp-wrapper-helper {
	-webkit-animation: bounceTop-out .6s both ease-in-out;
	animation: bounceTop-out .6s both ease-in-out
}
.cbp-animation-bounceTop .cbp-wrapper {
	-webkit-animation: bounceTop-in .6s both ease-in-out;
	animation: bounceTop-in .6s both ease-in-out
}
@-webkit-keyframes bounceTop-out {
100% {
-webkit-transform:translateY(-100%);
opacity:0
}
}
@keyframes bounceTop-out {
100% {
transform:translateY(-100%);
opacity:0
}
}
@-webkit-keyframes bounceTop-in {
0% {
-webkit-transform:translateY(-100%);
opacity:0
}
100% {
-webkit-transform:translateY(0);
opacity:1
}
}
@keyframes bounceTop-in {
0% {
transform:translateY(-100%);
opacity:0
}
100% {
transform:translateY(0);
opacity:1
}
}
.cbp-animation-moveLeft {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-moveLeft .cbp-wrapper-helper {
	-webkit-animation: moveLeft-out .6s both ease-in-out;
	animation: moveLeft-out .6s both ease-in-out
}
.cbp-animation-moveLeft .cbp-wrapper {
	-webkit-animation: moveLeft-in .6s both ease-in-out;
	animation: moveLeft-in .6s both ease-in-out
}
@-webkit-keyframes moveLeft-out {
100% {
-webkit-transform:translateX(-100%);
opacity:0
}
}
@keyframes moveLeft-out {
100% {
transform:translateX(-100%);
opacity:0
}
}
@-webkit-keyframes moveLeft-in {
0% {
-webkit-transform:translateX(100%);
opacity:0
}
100% {
-webkit-transform:translateX(0);
opacity:1
}
}
@keyframes moveLeft-in {
0% {
transform:translateX(100%);
opacity:0
}
100% {
transform:translateX(0);
opacity:1
}
}
.cbp-displayType-lazyLoading {
	-webkit-animation: fadeIn .5s both ease-in;
	animation: fadeIn .5s both ease-in
}
@-webkit-keyframes fadeIn {
0% {
opacity:0
}
100% {
opacity:1
}
}
@keyframes fadeIn {
0% {
opacity:0
}
100% {
opacity:1
}
}
.cbp-displayType-fadeInToTop {
	-webkit-perspective: 1000px;
	perspective: 1000px;
	-webkit-animation: fadeInToTop .5s both ease-in;
	animation: fadeInToTop .5s both ease-in
}
@-webkit-keyframes fadeInToTop {
0% {
opacity:0;
-webkit-transform:translateY(30px)
}
100% {
opacity:1;
-webkit-transform:translateY(0)
}
}
@keyframes fadeInToTop {
0% {
opacity:0;
transform:translateY(30px)
}
100% {
opacity:1;
transform:translateY(0)
}
}
.cbp-displayType-sequentially .cbp-item {
	-webkit-animation: fadeIn .5s both ease-in;
	animation: fadeIn .5s both ease-in
}
.cbp-displayType-bottomToTop {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-displayType-bottomToTop .cbp-item {
	-webkit-animation: fadeInBottomToTop .4s both ease-in;
	animation: fadeInBottomToTop .4s both ease-in
}
@-webkit-keyframes fadeInBottomToTop {
0% {
opacity:0;
-webkit-transform:translateY(50px)
}
100% {
opacity:1;
-webkit-transform:translateY(0)
}
}
@keyframes fadeInBottomToTop {
0% {
opacity:0;
transform:translateY(50px)
}
100% {
opacity:1;
transform:translateY(0)
}
}
.cbp-popup-ie8bg {
	position: absolute;
	width: 100%;
	height: 100%;
	min-height: 100%;
	top: 0;
	left: 0;
	z-index: -1;
	background: #000;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)"
}
.cbp-popup-wrap {
	height: 100%;
	text-align: center;
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	display: none;
	overflow-y: scroll;
	overflow-x: hidden;
	z-index: 9990;
	padding: 0 10px
}
.cbp-popup-lightbox {
	background: rgba(0,0,0,0.8)
}
.cbp-popup-singlePage {
	background: white;
	padding: 0
}
.cbp-popup-wrap:before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle
}
.cbp-popup-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	max-width: 100%
}
.cbp-popup-singlePage .cbp-popup-content {
	position: relative;
	z-index: 1;
	margin-top: 145px;
	max-width: 1024px;
	vertical-align: top;
	width: 94%
}
.cbp-popup-singlePage .cbp-popup-content-basic {
	position: relative;
	z-index: 1;
	margin-top: 104px;
	vertical-align: top;
	width: 100%;
	display: inline-block;
	text-align: left
}
.cbp-popup-lightbox-figure {
	position: relative
}
.cbp-popup-lightbox-bottom {
	left: 0;
	position: absolute;
	top: 100%;
	width: 100%;
	margin-top: 3px
}
.cbp-popup-lightbox-title {
	padding-right: 70px;
	font: 400 12px/18px "Open Sans", sans-serif;
	color: #eee
}
.cbp-popup-lightbox-counter {
	position: absolute;
	top: 0;
	right: 0;
	font: 400 12px/18px "Open Sans", sans-serif;
	color: #eee
}
.cbp-popup-lightbox-img {
	width: auto;
	max-width: 100%;
	height: auto;
	display: block;
	margin: 40px 0 40px;
	box-shadow: 0 0 8px rgba(0,0,0,0.6)
}
.cbp-popup-lightbox-img[data-action] {
	cursor: pointer
}
.cbp-popup-lightbox-isIframe .cbp-popup-content {
	width: 75%
}
@media only screen and (max-width: 768px) {
.cbp-popup-lightbox-isIframe .cbp-popup-content {
	width: 95%
}
}
.cbp-popup-lightbox-isIframe .cbp-lightbox-bottom {
	left: 0;
	position: absolute;
	top: 100%;
	width: 100%;
	margin-top: 3px
}
.cbp-popup-lightbox-iframe {
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
	background: #000
}
.cbp-popup-lightbox-iframe iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 8px rgba(0,0,0,0.6)
}
.cbp-popup-lightbox-iframe audio {
	margin-top: 27%
}
.cbp-popup-singlePage .cbp-popup-navigation-wrap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9990;
	height: 104px;
	background-color: #34425C
}
.cbp-popup-singlePage .cbp-popup-navigation {
	position: relative;
	width: 100%;
	height: 100%
}
.cbp-popup-singlePage-sticky .cbp-popup-navigation-wrap {
	position: fixed;
	top: 0 !important
}
.cbp-popup-singlePage-counter {
	color: #bbc0df;
	position: absolute;
	margin: auto;
	right: 40px;
	top: 0;
	bottom: 0;
	font: 400 13px/30px "Open Sans", sans-serif;
	height: 30px
}
@media only screen and (max-width: 768px) {
.cbp-popup-singlePage-counter {
	right: 3%
}
}
.cbp-popup-next, .cbp-popup-prev, .cbp-popup-close {
	padding: 0;
	border: medium none;
	position: absolute;
	cursor: pointer;
	outline: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.cbp-popup-lightbox .cbp-popup-prev, .cbp-popup-lightbox .cbp-popup-next, .cbp-popup-lightbox .cbp-popup-close {
	visibility: hidden
}
.cbp-popup-ready.cbp-popup-lightbox .cbp-popup-next, .cbp-popup-ready.cbp-popup-lightbox .cbp-popup-prev, .cbp-popup-ready.cbp-popup-lightbox .cbp-popup-close {
	visibility: visible
}
.cbp-popup-lightbox .cbp-popup-prev {
	background: url("../img/cbp-sprite.png") no-repeat scroll 0 0 transparent;
	width: 44px;
	height: 44px;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto
}
.cbp-popup-lightbox .cbp-popup-prev:hover {
	background-position: 0 -46px
}
.cbp-popup-singlePage .cbp-popup-prev {
	background: url("../img/cbp-sprite.png") no-repeat scroll 0 -92px transparent;
	width: 44px;
	height: 44px;
	margin: auto;
	top: 0;
	right: 108px;
	bottom: 0;
	left: 0
}
.cbp-popup-singlePage .cbp-popup-prev:hover {
	background-position: 0 -138px
}
.cbp-popup-lightbox .cbp-popup-next {
	background: url("../img/cbp-sprite.png") no-repeat scroll -46px 0 transparent;
	width: 44px;
	height: 44px;
	top: 0;
	bottom: 0;
	right: 20px;
	margin: auto
}
.cbp-popup-lightbox .cbp-popup-next:hover {
	background-position: -46px -46px
}
.cbp-popup-singlePage .cbp-popup-next {
	background: url("../img/cbp-sprite.png") no-repeat scroll -46px -92px transparent;
	width: 44px;
	height: 44px;
	margin: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 108px
}
.cbp-popup-singlePage .cbp-popup-next:hover {
	background-position: -46px -138px
}
.cbp-popup-lightbox .cbp-popup-close {
	background: url("../img/cbp-sprite.png") no-repeat scroll -92px 0 transparent;
	height: 40px;
	width: 40px;
	right: 20px;
	top: 20px
}
.cbp-popup-lightbox .cbp-popup-close:hover {
	background-position: -92px -46px
}
.cbp-popup-singlePage .cbp-popup-close {
	background: url("../img/cbp-sprite.png") no-repeat scroll -92px -92px transparent;
	height: 44px;
	width: 44px;
	margin: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0
}
.cbp-popup-singlePage .cbp-popup-ie8bg {
	background-color: #fff
}
.cbp-popup-singlePage .cbp-popup-close:hover {
	background-position: -92px -138px
}
@media only screen and (max-width: 360px), (max-height: 600px) {
.cbp-popup-next, .cbp-popup-prev, .cbp-popup-close {
	-ms-transform: scale(0.8);
	-webkit-transform: scale(0.8);
	transform: scale(0.8)
}
.cbp-popup-lightbox .cbp-popup-close {
	right: 10px;
	top: 10px
}
.cbp-popup-lightbox .cbp-popup-next {
	right: 10px
}
.cbp-popup-lightbox .cbp-popup-prev {
	left: 10px
}
.cbp-popup-singlePage .cbp-popup-navigation-wrap {
	height: 84px
}
.cbp-popup-singlePage .cbp-popup-content {
	margin-top: 120px
}
}
.cbp-popup-loadingBox {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	z-index: 2;
	background: url("../img/cbp-loading-popup.gif") no-repeat scroll center center #0D0D0D;
	border: 1px solid rgba(79,80,79,0.3);
	border-radius: 3px;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=71)";
	opacity: .71;
	width: 50px;
	height: 50px
}
.cbp-popup-ready .cbp-popup-loadingBox {
	visibility: hidden
}
.cbp-popup-loading .cbp-popup-loadingBox {
	visibility: visible
}
.cbp-popup-singlePage {
	left: 100%;
	-webkit-transition: left .6s ease-in-out;
	transition: left .6s ease-in-out
}
.cbp-popup-singlePage.cbp-popup-loading .cbp-popup-content {
	opacity: 0
}
.cbp-popup-singlePage-open {
	left: 0
}
.cbp-popup-singlePage.cbp-popup-singlePage-fade {
	left: 0;
	opacity: 0;
	-webkit-transition: opacity .25s ease-in-out;
	transition: opacity .25s ease-in-out
}
.cbp-popup-singlePage-open.cbp-popup-singlePage-fade {
	opacity: 1
}
.cbp-popup-singlePage.cbp-popup-singlePage-right {
	left: -100%;
	-webkit-transition: left .6s ease-in-out;
	transition: left .6s ease-in-out
}
.cbp-popup-singlePage-open.cbp-popup-singlePage-right {
	left: 0
}
.cbp-l-project-title {
	color: #454444;
	font: 600 42px/46px "Open Sans", sans-serif;
	letter-spacing: 2px;
	margin-bottom: 15px;
	text-align: center;
	text-transform: uppercase
}
.cbp-l-project-subtitle {
	color: #787878;
	font: 400 14px/21px "Open Sans", sans-serif;
	margin: 0 auto 50px;
	max-width: 500px;
	text-align: center
}
.cbp-popup-singlePage .cbp-popup-content .cbp-l-project-img {
	display: block;
	margin: 0 auto;
	max-width: 100%
}
.cbp-l-project-container {
	overflow: hidden;
	margin: 40px auto 0;
	clear: both
}
.cbp-l-project-desc {
	float: left;
	width: 64%
}
.cbp-l-project-social-wrapper, .cbp-l-inline-social-wrapper {
	display: inline-block;
	width: 100px;
	height: 20px;
	overflow: hidden
}
.cbp-l-inline-social-wrapper {
	margin-top: 5px
}
.cbp-l-project-details {
	float: right;
	width: 36%;
	padding-left: 40px;
	margin-bottom: 15px
}
@media only screen and (max-width: 768px) {
.cbp-l-project-title {
	font-size: 30px;
	line-height: 34px
}
.cbp-l-project-desc {
	width: 100%
}
.cbp-l-project-details {
	width: 100%;
	margin-top: 20px;
	padding-left: 0
}
}
.cbp-l-project-desc-title {
	border-bottom: 1px solid #cdcdcd;
	margin-bottom: 22px;
	color: #444
}
.cbp-l-project-desc-title span, .cbp-l-project-details-title span {
	border-bottom: 1px solid #747474;
	display: inline-block;
	margin: 0 0 -1px 0;
	font: 400 16px/36px "Open Sans", sans-serif;
	padding: 0 5px 0 0
}
.cbp-l-project-desc-text {
	font: 400 13px/20px "Open Sans", sans-serif;
	color: #555;
	margin-bottom: 20px
}
.cbp-l-project-details-title {
	border-bottom: 1px solid #cdcdcd;
	margin-bottom: 19px;
	color: #444
}
.cbp-l-project-details-list {
}
.cbp-l-project-details-list>li, .cbp-l-project-details-list>div {
	border-bottom: 1px dotted #DFDFDF;
	padding: inherit;
	color: #666;
	font: 400 12px/30px "Open Sans", sans-serif
}
.cbp-l-project-details-list strong {
	display: inline-block;
	color: #696969;
	font-weight: 600;
	min-width: 100px
}
.cbp-l-project-details-visit {
	color: #FFFFFF;
	float: right;
	clear: both;
	text-decoration: none;
	font: 400 11px/18px "Open Sans", sans-serif;
	margin-top: 10px;
	background-color: #53668A;
	padding: 8px 19px
}
.cbp-l-project-details-visit:hover {
	opacity: 0.9;
	color: #fff
}
.cbp-l-project-related {
}
.cbp-l-project-related-wrap {
	font-size: 0;
	margin: 0
}
.cbp-l-project-related-item {
	margin-right: 5%;
	max-width: 30%;
	float: left
}
.cbp-l-project-related-item:last-child {
	margin-right: 0
}
.cbp-l-project-related-title {
	font: 700 14px/18px "Open Sans", sans-serif;
	color: #474747;
	margin-top: 20px
}
.cbp-l-project-related-link {
	text-decoration: none
}
.cbp-l-project-related-link:hover {
	opacity: 0.9
}
.cbp-l-member-img {
	float: left;
	width: 40%;
	margin-top: 20px
}
.cbp-l-member-img img {
	border: 1px solid #e2e2e2;
	width: auto;
	max-width: 100%;
	height: auto;
	display: inline-block
}
.cbp-l-member-info {
	margin-top: 20px;
	padding-left: 25px;
	float: left;
	width: 60%
}
@media only screen and (max-width: 768px) {
.cbp-l-member-img {
	width: 100%;
	text-align: center
}
.cbp-l-member-info {
	width: 100%;
	padding-left: 0
}
}
.cbp-l-member-name {
	font: 400 28px/28px "Open Sans", sans-serif;
	color: #474747
}
.cbp-l-member-position {
	font: 400 13px/21px "Open Sans", sans-serif;
	color: #888;
	margin-top: 6px
}
.cbp-l-member-desc {
	font: 400 12px/18px "Open Sans", sans-serif;
	margin-top: 25px;
	color: #474747
}
.cbp-popup-singlePageInline-open {
	-webkit-transition: height .5s ease 0s !important;
	transition: height .5s ease 0s !important
}
.cbp-popup-singlePageInline-open .cbp-item {
	-webkit-transition: -webkit-transform .5s ease 0s !important;
	transition: transform .5s ease 0s !important
}
.cbp-popup-singlePageInline-close .cbp-popup-singlePageInline::before {
	display: none
}
.cbp-popup-singlePageInline-close .cbp-popup-singlePageInline .cbp-popup-content, .cbp-popup-singlePageInline-close .cbp-popup-singlePageInline .cbp-popup-navigation {
	-webkit-transition-delay: 0;
	transition-delay: 0
}
.cbp-popup-singlePageInline {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	overflow: hidden
}
.cbp-popup-singlePageInline:before {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	z-index: 2;
	content: '';
	width: 30px;
	height: 30px;
	border: 1px solid rgba(79,80,79,0.3);
	border-right: 5px solid #000;
	border-bottom: 5px solid #000;
	border-radius: 50%;
	border-left: 5px solid transparent;
	border-top: 5px solid transparent;
	animation: loading .5s infinite linear;
	-webkit-animation: loading .5s infinite linear;
	opacity: 0.6
}
.cbp-popup-singlePageInline .cbp-popup-content {
	opacity: 0;
	width: 100%;
	z-index: 1;
	min-height: 300px
}
.cbp-popup-singlePageInline .cbp-popup-content, .cbp-popup-singlePageInline .cbp-popup-navigation {
	-webkit-transition: opacity .4s ease-in .2s;
	transition: opacity .4s ease-in .2s
}
.cbp-popup-singlePageInline .cbp-popup-navigation {
	opacity: 0;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	width: 40px;
	height: 40px
}
.cbp-popup-singlePageInline .cbp-popup-close {
	background: url("../img/cbp-sprite.png") no-repeat scroll -92px 0 transparent;
	height: 40px;
	width: 40px;
	right: 20px;
	top: 30px
}
.cbp-popup-singlePageInline .cbp-popup-close:hover {
	opacity: 0.7
}
.cbp-popup-singlePageInline-ready {
	z-index: 4
}
.cbp-popup-singlePageInline-ready .cbp-popup-content, .cbp-popup-singlePageInline-ready .cbp-popup-navigation {
	opacity: 1
}
.cbp-popup-singlePageInline-ready:before {
	display: none
}
.cbp-singlePageInline-active {
	opacity: 0.6 !important
}
@-webkit-keyframes loading {
0% {
-webkit-transform:rotate(0deg)
}
100% {
-webkit-transform:rotate(360deg)
}
}
@keyframes loading {
0% {
transform:rotate(0deg)
}
100% {
transform:rotate(360deg)
}
}
.cbp-l-inline {
	margin: 20px 0;
	overflow: hidden;
	background: #FAFAFA;
	padding: 30px
}
.cbp-l-inline-left {
	float: left;
	width: 44%
}
.cbp-l-project-img {
	max-width: 100%
}
.cbp-l-inline-right {
	float: right;
	width: 56%;
	padding-left: inherit
}
@media only screen and (max-width: 768px) {
.cbp-l-inline-left {
	width: 100%;
	text-align: center;
	margin-top: 40px
}
.cbp-l-inline-right {
	width: 100%;
	padding-left: 0;
	margin-top: 20px
}
}
.cbp-l-inline-title {
	font: 400 28px/30px "Open Sans", sans-serif;
	color: #474747
}
.cbp-l-inline-subtitle {
	font: 400 13px/21px "Open Sans", sans-serif;
	color: #888;
	margin-top: 7px
}
.cbp-l-inline-desc {
	font: 400 13px/20px "Open Sans", sans-serif;
	color: #474747;
	margin-top: 25px
}
.cbp-l-inline-view-wrap {
	text-align: right
}
.cbp-l-inline-view {
	font: 400 13px/35px "Open Sans", sans-serif;
	color: #9C9C9C;
	margin-top: 40px;
	display: inline-block;
	padding: 0 20px;
	border: 1px solid #ccc;
	text-decoration: none
}
.cbp-l-inline-view:hover {
	color: #757575
}
.cbp-l-inline-details {
	margin-top: 15px;
	font: 13px/22px "Open Sans", sans-serif
}
.cbp-l-loadMore-defaultText, .cbp-l-loadMore-button-defaultText {
	display: block
}
.cbp-l-loadMore-loadingText, .cbp-l-loadMore-noMoreLoading, .cbp-l-loadMore-button-loadingText, .cbp-l-loadMore-button-noMoreLoading {
	display: none
}
.cbp-l-loadMore-loading .cbp-l-loadMore-loadingText, .cbp-l-loadMore-loading .cbp-l-loadMore-button-loadingText {
	display: block
}
.cbp-l-loadMore-loading .cbp-l-loadMore-defaultText, .cbp-l-loadMore-loading .cbp-l-loadMore-noMoreLoading, .cbp-l-loadMore-loading .cbp-l-loadMore-button-defaultText, .cbp-l-loadMore-loading .cbp-l-loadMore-button-noMoreLoading {
	display: none
}
.cbp-l-loadMore-stop .cbp-l-loadMore-noMoreLoading, .cbp-l-loadMore-stop .cbp-l-loadMore-button-noMoreLoading {
	display: block
}
.cbp-l-loadMore-stop .cbp-l-loadMore-defaultText, .cbp-l-loadMore-stop .cbp-l-loadMore-loadingText, .cbp-l-loadMore-stop .cbp-l-loadMore-button-defaultText, .cbp-l-loadMore-stop .cbp-l-loadMore-button-loadingText {
	display: none
}
.cbp-l-loadMore-button {
	text-align: center
}
.cbp-l-loadMore-button .cbp-l-loadMore-link, .cbp-l-loadMore-button .cbp-l-loadMore-button-link {
	border: 2px solid #DEDEDE;
	border-radius: 2px;
	color: #7E7B7B;
	display: inline-block;
	font: 400 11px/34px "Open Sans", sans-serif;
	min-width: 80px;
	text-decoration: none;
	padding: 0 20px;
	outline: 0;
	margin-top: 30px;
	box-shadow: none
}
.cbp-l-loadMore-button .cbp-l-loadMore-link:hover, .cbp-l-loadMore-button .cbp-l-loadMore-button-link:hover {
	color: #B0B0B0
}
.cbp-l-loadMore-button .cbp-l-loadMore-link.cbp-l-loadMore-stop, .cbp-l-loadMore-button .cbp-l-loadMore-button-link.cbp-l-loadMore-stop, .cbp-l-loadMore-button .cbp-l-loadMore-button-link.cbp-l-loadMore-button-stop {
	cursor: default;
	color: #B0B0B0
}
.cbp-l-loadMore-text {
	text-align: center;
	margin-top: 50px
}
.cbp-l-loadMore-text .cbp-l-loadMore-link, .cbp-l-loadMore-text .cbp-l-loadMore-text-link {
	font: 400 15px "Open Sans", sans-serif;
	color: #5D5D5D;
	text-decoration: none;
	cursor: pointer
}
.cbp-l-loadMore-text .cbp-l-loadMore-stop, .cbp-l-loadMore-text .cbp-l-loadMore-text-stop {
	color: #5D5D5D;
	cursor: default
}
.cbp-mode-slider {
	-webkit-transition: height .35s cubic-bezier(0.22, 0.6, 0.345, 1);
	transition: height .35s cubic-bezier(0.22, 0.6, 0.345, 1)
}
.cbp-mode-slider .cbp-wrapper, .cbp-mode-slider .cbp-item {
	-webkit-transition: -webkit-transform .35s cubic-bezier(0.22, 0.6, 0.345, 1);
	transition: transform .35s cubic-bezier(0.22, 0.6, 0.345, 1)
}
.cbp-mode-slider-dragStart .cbp-wrapper {
	-webkit-transition: none;
	transition: none
}
.cbp-mode-slider-dragStart * {
	cursor: move !important;
	cursor: -webkit-grab !important;
	cursor: -o-grab !important;
	cursor: -ms-grab !important;
	cursor: grab !important
}
.cbp-nav-next, .cbp-nav-prev {
	position: relative;
	background: #7c8b90;
	cursor: pointer;
	display: inline-block;
	margin-left: 1px;
	height: 22px;
	width: 21px
}
.cbp-nav-next {
	border-radius: 0 2px 2px 0
}
.cbp-nav-prev {
	border-radius: 2px 0 0 2px
}
.cbp-nav-next:hover, .cbp-nav-prev:hover {
	opacity: 0.8
}
.cbp-nav-next:after, .cbp-nav-prev:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	background: url("../img/cbp-sprite.png") no-repeat;
	height: 10px;
	width: 7px
}
.cbp-nav-next:after {
	background-position: -134px 0
}
.cbp-nav-prev:after {
	background-position: -134px -12px
}
.cbp-nav-stop {
	opacity: 0.5 !important;
	cursor: default !important
}
.cbp-nav {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.cbp-nav-controls {
	position: absolute;
	top: -51px;
	right: 0;
	z-index: 100
}
.cbp-nav-pagination {
	position: absolute;
	bottom: -30px;
	right: 0;
	z-index: 100;
	left: 0;
	text-align: center
}
.cbp-nav-pagination-item {
	position: relative;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin: 0 4px;
	display: inline-block;
	cursor: pointer;
	background: #c2c2c2;
	-webkit-transition: background 0.5s;
	transition: background 0.5s
}
.cbp-nav-pagination-active {
	background: #797979
}
.cbp-slider-wrap, .cbp-slider-item {
	margin: 0;
	padding: 0;
	list-style-type: none
}
.cbp-slider .cbp-nav-controls {
	position: static
}
.cbp-slider .cbp-nav-next, .cbp-slider .cbp-nav-prev {
	background: transparent;
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	z-index: 100;
	width: 44px;
	height: 44px
}
.cbp-slider .cbp-nav-next {
	right: 25px;
	left: auto
}
.cbp-slider .cbp-nav-prev {
	left: 25px;
	right: auto
}
.cbp-slider .cbp-nav-next:after, .cbp-slider .cbp-nav-prev:after {
	background: url("../img/cbp-sprite.png") no-repeat;
	width: 44px;
	height: 44px
}
.cbp-slider .cbp-nav-next:after {
	background-position: -46px -92px
}
.cbp-slider .cbp-nav-next:hover:after {
	background-position: -46px -46px
}
.cbp-slider .cbp-nav-prev:after {
	background-position: 0 -92px
}
.cbp-slider .cbp-nav-prev:hover:after {
	background-position: 0 -46px
}
.cbp-slider .cbp-nav-pagination {
	text-align: right;
	bottom: 20px;
	right: 25px
}
.cbp-slider-edge .cbp-nav-controls {
	position: static
}
.cbp-slider-edge .cbp-nav-next, .cbp-slider-edge .cbp-nav-prev {
	background: transparent;
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	z-index: 100;
	width: 44px;
	height: 44px
}
.cbp-slider-edge .cbp-nav-next {
	right: -14px;
	left: auto
}
.cbp-slider-edge .cbp-nav-prev {
	left: -14px;
	right: auto
}
.cbp-slider-edge .cbp-nav-next:after, .cbp-slider-edge .cbp-nav-prev:after {
	background: url("../img/cbp-sprite.png") no-repeat;
	width: 9px;
	height: 16px
}
.cbp-slider-edge .cbp-nav-next:after {
	background-position: -134px -24px
}
.cbp-slider-edge .cbp-nav-prev:after {
	background-position: -134px -42px
}
.cbp-slider-edge .cbp-nav-pagination {
	bottom: -50px
}
.cbp-slider-edge .cbp-nav-pagination-item {
	border: 2px solid #0f0f0f;
	opacity: 0.4;
	background: transparent
}
.cbp-slider-edge .cbp-nav-pagination-active {
	background: #000
}
.cbp-l-grid-agency .cbp-caption:after {
	position: absolute;
	content: '';
	width: 0;
	height: 0;
	border-bottom: 10px solid #fff;
	border-right: 10px solid transparent;
	border-left: 10px solid transparent;
	bottom: 0px;
	left: 50%;
	margin-left: -5px;
	z-index: 1
}
.cbp-l-grid-agency.cbp-caption-zoom .cbp-caption:hover .cbp-caption-defaultWrap {
	-webkit-transform: scale(1.15);
	transform: scale(1.15)
}
.cbp-l-grid-agency-title {
	margin-top: 18px;
	font: 700 17px/24px Lato, sans-serif;
	color: #666;
	text-align: center
}
.cbp-item:hover .cbp-l-grid-agency-title {
	color: #222
}
.cbp-l-grid-agency-desc {
	font: 400 12px/21px "Open Sans", sans-serif;
	color: #aaa;
	text-align: center
}
.cbp-l-grid-work.cbp-caption-zoom .cbp-caption-activeWrap {
	background-color: rgba(0,0,0,0.7)
}
.cbp-l-grid-work .cbp-item {
	padding: 3px
}
.cbp-l-grid-work .cbp-item-wrapper {
	background-color: #fff;
	box-shadow: 0 1px 1px rgba(0,0,0,0.2);
	padding: 7px 7px 27px;
	border-top: 1px solid #F4F4F4
}
.cbp-l-grid-work-title {
	margin-top: 17px;
	font: 400 17px/25px "Roboto Condensed", sans-serif;
	color: #607D8B;
	text-align: center;
	text-transform: uppercase;
	display: block
}
.cbp-l-grid-work-title:hover {
	color: #365D67
}
.cbp-l-grid-work-desc {
	font: 400 11px/16px "Open Sans", sans-serif;
	color: #888888;
	text-align: center;
	text-transform: uppercase
}
.cbp-l-grid-blog-title {
	font: 400 18px/30px "Open Sans", sans-serif;
	color: #444;
	display: block;
	margin-top: 17px
}
.cbp-l-grid-blog-title:hover {
	color: #787878
}
.cbp-l-grid-blog-date {
	font: 400 12px/18px "Open Sans", sans-serif;
	color: #787878;
	display: inline-block
}
.cbp-l-grid-blog-comments {
	font: 400 12px/18px "Open Sans", sans-serif;
	color: #3C6FBB;
	display: inline-block
}
.cbp-l-grid-blog-comments:hover {
	opacity: .8
}
.cbp-l-grid-blog-desc {
	font: 400 13px/18px "Open Sans", sans-serif;
	color: #9B9B9B;
	margin-top: 9px
}
.cbp-l-grid-blog-split {
	margin: 0 4px;
	font: 400 13px/16px "Open Sans", sans-serif;
	color: #787878;
	display: inline-block
}
.cbp-l-grid-clients {
	height: 180px
}
.cbp-l-clients-title-block {
	font: 400 32px/53px Roboto, sans-serif;
	color: #666464;
	text-align: center;
	margin-bottom: 40px
}
.cbp-l-grid-projects-title {
	font: 700 14px/21px "Open Sans", sans-serif;
	color: #474747;
	margin-top: 15px
}
.cbp-l-grid-projects-desc {
	font: 400 12px/18px "Open Sans", sans-serif;
	color: #888888;
	margin-top: 5px
}
.cbp-l-grid-masonry-projects .cbp-caption-activeWrap {
	background-color: #59a3b6;
	background-color: rgba(89,163,182,0.95)
}
.cbp-l-grid-masonry-projects .cbp-l-caption-buttonLeft, .cbp-l-grid-masonry-projects .cbp-l-caption-buttonRight {
	background-color: #4d4d4d
}
.cbp-l-grid-masonry-projects .cbp-l-caption-buttonLeft:hover, .cbp-l-grid-masonry-projects .cbp-l-caption-buttonRight:hover {
	background-color: #252424
}
.cbp-l-grid-masonry-projects-title {
	font: 500 15px/22px Roboto, sans-serif;
	color: #59a3b6;
	text-align: center;
	display: block;
	margin-top: 12px
}
.cbp-l-grid-masonry-projects-title:hover {
	color: #457C8B
}
.cbp-l-grid-masonry-projects-desc {
	font: 400 12px/18px Roboto, sans-serif;
	color: #b2b2b2;
	text-align: center
}
.cbp-l-grid-team .cbp-caption {
	border: 1px solid #E7E7E7
}
.cbp-l-grid-team-name {
	font: 400 17px/24px "Open Sans", sans-serif;
	color: #456297;
	display: block;
	text-align: center;
	margin-top: 18px
}
.cbp-l-grid-team-name:hover {
	color: #34425C
}
.cbp-l-grid-team-position {
	font: italic 400 13px/21px "Open Sans", sans-serif;
	color: #999;
	text-align: center
}
.cbp-l-slider-title-block {
	border-bottom: 1px solid #cdcdcd;
	margin-bottom: 22px
}
.cbp-l-slider-title-block div {
	padding: 0 2px 6px 0;
	display: inline-block;
	border-bottom: 1px solid #a9a5a5;
	color: #5e5e5e;
	margin-bottom: -1px;
	font: 15px/21px Roboto, sans-serif
}
.cbp-l-grid-slider-team-name {
	float: left;
	font: 20px/30px Roboto, sans-serif;
	color: #494949;
	margin-top: 16px
}
.cbp-l-grid-slider-team-position {
	clear: both;
	font: 14px/21px Roboto, sans-serif;
	color: #A6A6A6
}
.cbp-l-grid-slider-team-desc {
	font: 13px/20px Roboto, sans-serif;
	color: #969696;
	margin-top: 15px
}
.cbp-l-grid-slider-team-social {
	float: right;
	margin-top: 16px
}
.cbp-l-grid-slider-team-social a {
	color: #737373;
	line-height: 30px;
	margin-left: 6px
}
.cbp-l-grid-slider-team-social a:hover {
	opacity: 0.8
}
.cbp-l-slider-testimonials-wrap {
	background: #f8f9f9;
	padding: 80px 0 110px;
	border-width: 1px 0;
	border-style: solid;
	border-color: #dce1e2
}
.cbp-l-grid-slider-testimonials-body {
	color: #424242;
	max-width: 800px;
	margin: 0 auto;
	font: 20px/32px sans-serif;
	text-align: center;
	padding: 0 20px
}
.cbp-l-grid-slider-testimonials-footer {
	font: 12px/19px Roboto, sans-serif;
	color: #777;
	text-align: center;
	margin-bottom: 10px;
	margin-top: 30px
}
.cbp-l-grid-tabs {
	height: 100px
}
.cbp-l-grid-tabs .cbp-item {
	font: 14px/24px Lato, sans-serif;
	max-width: 700px;
	width: 100%;
	margin: 0 auto;
	right: 0;
	text-align: center;
	color: #5a5a5a
}
.cbp-l-testimonials-title-block {
	position: relative;
	text-align: center;
	font: 26px/36px Roboto, sans-serif;
	color: #E7E7E7;
	margin-bottom: 60px
}
.cbp-l-testimonials-title-block :after {
	content: '';
	position: absolute;
	margin: 0 auto;
	width: 23px;
	height: 2px;
	bottom: -6px;
	background-color: #C2C2C2;
	left: 0;
	right: 0
}
.cbp-l-testimonials-wrap {
	background: #2D2D2D;
	padding: 60px 0 110px
}
.cbp-l-grid-testimonials-body {
	color: #e7e7e7;
	max-width: 800px;
	margin: 0 auto;
	font: 20px/32px sans-serif;
	text-align: center;
	padding: 0 20px
}
.cbp-l-grid-testimonials-footer {
	font: 12px/19px Roboto, sans-serif;
	color: #C2C2C2;
	text-align: center;
	margin-bottom: 40px;
	margin-top: 35px
}
