@font-face {
	font-family: 'medical-guide-icons';
	src:url('/fonts/medical-guide-icons.eot?z74vbr');
	src:url('/fonts/medical-guide-icons.eot?#iefixz74vbr') format('embedded-opentype'),
		url('/fonts/medical-guide-icons.ttf?z74vbr') format('truetype'),
		url('/fonts/medical-guide-icons.woff?z74vbr') format('woff'),
		url('/fonts/medical-guide-icons.svg?z74vbr#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'medical-guide-icons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-lungs:before {
	content: "\ebd9";
}
.icon-ambulanc:before {
	content: "\ebda";
}
.icon-first-aid-box:before {
	content: "\ebdb";
}
.icon-ambulance-siren:before {
	content: "\ebdc";
}
.icon-tooth:before {
	content: "\ebdd";
}
.icon-add-tooth:before {
	content: "\ebde";
}
.icon-remove-tooth:before {
	content: "\ebdf";
}
.icon-aids-symbol:before {
	content: "\ebe0";
}
.icon-blood-drop:before {
	content: "\ebe1";
}
.icon-heart-beat:before {
	content: "\ebe2";
}
.icon-blood-bag:before {
	content: "\ebe3";
}
.icon-medicine-bottle:before {
	content: "\ebe4";
}
.icon-brain:before {
	content: "\ebe5";
}
.icon-DNA:before {
	content: "\ebe6";
}
.icon-doctor:before {
	content: "\ebe7";
}
.icon-medical:before {
	content: "\ebe8";
}
.icon-eye6:before {
	content: "\ebe9";
}
.icon-ambulance:before {
	content: "\ebea";
}
.icon-ambulance2:before {
	content: "\ebeb";
}
.icon-ambulance3:before {
	content: "\ebec";
}
.icon-ambulance4:before {
	content: "\ebed";
}
.icon-medical2:before {
	content: "\ebee";
}
.icon-kidney:before {
	content: "\ebef";
}
.icon-kidney2:before {
	content: "\ebf0";
}
.icon-stomach:before {
	content: "\ebf1";
}
.icon-liver:before {
	content: "\ebf2";
}
.icon-heart10:before {
	content: "\ebf3";
}
.icon-lungs2:before {
	content: "\ebf4";
}
.icon-lung .path1:before {
	content: "\ebf5";
	color: rgb(245, 245, 245);
}
.icon-lung .path2:before {
	content: "\ebf6";
	margin-left: -1em;
	color: rgb(245, 245, 245);
}
.icon-lung .path3:before {
	content: "\ebf7";
	margin-left: -1em;
	color: rgb(245, 245, 245);
}
.icon-lung .path4:before {
	content: "\ebf8";
	margin-left: -1em;
	color: rgb(0, 0, 0);
}
.icon-patient-bed:before {
	content: "\ebf9";
}
.icon-patientbed:before {
	content: "\ebfa";
}
.icon-dropper:before {
	content: "\ebfb";
}
.icon-injection2:before {
	content: "\ebfc";
}
.icon-injection3:before {
	content: "\ebfd";
}
.icon-blood-bag2:before {
	content: "\ebfe";
}
.icon-tablet6:before {
	content: "\ebff";
}
.icon-first-aid-box2:before {
	content: "\ec00";
}
.icon-prescription-pad:before {
	content: "\ec01";
}
.icon-Medicine-bottle:before {
	content: "\ec02";
}
.icon-tablets:before {
	content: "\ec03";
}
.icon-air-ambulance-helicopter:before {
	content: "\ec04";
}
.icon-mortar-pestle:before {
	content: "\ec05";
}
.icon-ecg-line:before {
	content: "\ec06";
}
.icon-currency:before {
	content: "\e600";
}
.icon-dollar:before {
	content: "\e601";
}
.icon-yen:before {
	content: "\e603";
}
.icon-euro:before {
	content: "\e604";
}
.icon-locklockerstreamline:before {
	content: "\e605";
}
.icon-caddieshopshoppingstreamline:before {
	content: "\e606";
}
.icon-caddieshoppingstreamline:before {
	content: "\e607";
}
.icon-icons9:before {
	content: "\e608";
}
.icon-icons20:before {
	content: "\e609";
}
.icon-icons100:before {
	content: "\e60a";
}
.icon-icons104:before {
	content: "\e60b";
}
.icon-icons116:before {
	content: "\e60c";
}
.icon-icons152:before {
	content: "\e60d";
}
.icon-icons170:before {
	content: "\e60e";
}
.icon-icons185:before {
	content: "\e60f";
}
.icon-icons202:before {
	content: "\e610";
}
.icon-icons206:before {
	content: "\e611";
}
.icon-icons214:before {
	content: "\e612";
}
.icon-icons236:before {
	content: "\e613";
}
.icon-icons240:before {
	content: "\e614";
}
.icon-map:before {
	content: "\e615";
}
.icon-pound:before {
	content: "\e602";
}
.icon-truck:before {
	content: "\e616";
}
.icon-heart:before {
	content: "\e617";
}
.icon-cloud:before {
	content: "\e618";
}
.icon-star:before {
	content: "\e619";
}
.icon-tv:before {
	content: "\e61a";
}
.icon-sound:before {
	content: "\e61b";
}
.icon-video:before {
	content: "\e61c";
}
.icon-trash:before {
	content: "\e61d";
}
.icon-user:before {
	content: "\e61e";
}
.icon-key:before {
	content: "\e61f";
}
.icon-search:before {
	content: "\e620";
}
.icon-settings:before {
	content: "\e621";
}
.icon-camera:before {
	content: "\e622";
}
.icon-tag:before {
	content: "\e623";
}
.icon-lock:before {
	content: "\e624";
}
.icon-bulb:before {
	content: "\e625";
}
.icon-pen:before {
	content: "\e626";
}
.icon-diamond:before {
	content: "\e627";
}
.icon-display:before {
	content: "\e628";
}
.icon-location:before {
	content: "\e629";
}
.icon-eye:before {
	content: "\e62a";
}
.icon-bubble:before {
	content: "\e62b";
}
.icon-stack:before {
	content: "\e62c";
}
.icon-cup:before {
	content: "\e62d";
}
.icon-phone:before {
	content: "\e62e";
}
.icon-news:before {
	content: "\e62f";
}
.icon-mail:before {
	content: "\e630";
}
.icon-like:before {
	content: "\e631";
}
.icon-photo:before {
	content: "\e632";
}
.icon-note:before {
	content: "\e633";
}
.icon-clock:before {
	content: "\e634";
}
.icon-paperplane:before {
	content: "\e635";
}
.icon-params:before {
	content: "\e636";
}
.icon-banknote:before {
	content: "\e637";
}
.icon-data:before {
	content: "\e638";
}
.icon-music:before {
	content: "\e639";
}
.icon-megaphone:before {
	content: "\e63a";
}
.icon-study:before {
	content: "\e63b";
}
.icon-lab:before {
	content: "\e63c";
}
.icon-food:before {
	content: "\e63d";
}
.icon-t-shirt:before {
	content: "\e63e";
}
.icon-fire:before {
	content: "\e63f";
}
.icon-clip:before {
	content: "\e640";
}
.icon-shop:before {
	content: "\e641";
}
.icon-calendar:before {
	content: "\e642";
}
.icon-wallet:before {
	content: "\e643";
}
.icon-vynil:before {
	content: "\e644";
}
.icon-truck2:before {
	content: "\e645";
}
.icon-world:before {
	content: "\e646";
}
.icon-mobile:before {
	content: "\e000";
}
.icon-laptop:before {
	content: "\e001";
}
.icon-desktop:before {
	content: "\e002";
}
.icon-tablet:before {
	content: "\e003";
}
.icon-phone2:before {
	content: "\e004";
}
.icon-document:before {
	content: "\e005";
}
.icon-documents:before {
	content: "\e006";
}
.icon-search2:before {
	content: "\e007";
}
.icon-clipboard:before {
	content: "\e008";
}
.icon-newspaper:before {
	content: "\e009";
}
.icon-notebook:before {
	content: "\e00a";
}
.icon-book-open:before {
	content: "\e00b";
}
.icon-browser:before {
	content: "\e00c";
}
.icon-calendar2:before {
	content: "\e00d";
}
.icon-presentation:before {
	content: "\e00e";
}
.icon-picture:before {
	content: "\e00f";
}
.icon-pictures:before {
	content: "\e010";
}
.icon-video2:before {
	content: "\e011";
}
.icon-camera2:before {
	content: "\e012";
}
.icon-printer:before {
	content: "\e013";
}
.icon-toolbox:before {
	content: "\e014";
}
.icon-briefcase:before {
	content: "\e015";
}
.icon-wallet2:before {
	content: "\e016";
}
.icon-gift:before {
	content: "\e017";
}
.icon-bargraph:before {
	content: "\e018";
}
.icon-grid:before {
	content: "\e019";
}
.icon-expand:before {
	content: "\e01a";
}
.icon-focus:before {
	content: "\e01b";
}
.icon-edit:before {
	content: "\e01c";
}
.icon-adjustments:before {
	content: "\e01d";
}
.icon-ribbon:before {
	content: "\e01e";
}
.icon-hourglass:before {
	content: "\e01f";
}
.icon-lock2:before {
	content: "\e020";
}
.icon-megaphone2:before {
	content: "\e021";
}
.icon-shield:before {
	content: "\e022";
}
.icon-trophy:before {
	content: "\e023";
}
.icon-flag:before {
	content: "\e024";
}
.icon-map3:before {
	content: "\e025";
}
.icon-puzzle:before {
	content: "\e026";
}
.icon-basket:before {
	content: "\e027";
}
.icon-envelope:before {
	content: "\e028";
}
.icon-streetsign:before {
	content: "\e029";
}
.icon-telescope:before {
	content: "\e02a";
}
.icon-gears:before {
	content: "\e02b";
}
.icon-key3:before {
	content: "\e02c";
}
.icon-paperclip:before {
	content: "\e02d";
}
.icon-attachment:before {
	content: "\e02e";
}
.icon-pricetags:before {
	content: "\e02f";
}
.icon-lightbulb:before {
	content: "\e030";
}
.icon-layers:before {
	content: "\e031";
}
.icon-pencil:before {
	content: "\e032";
}
.icon-tools:before {
	content: "\e033";
}
.icon-tools-2:before {
	content: "\e034";
}
.icon-scissors:before {
	content: "\e035";
}
.icon-paintbrush:before {
	content: "\e036";
}
.icon-magnifying-glass:before {
	content: "\e037";
}
.icon-circle-compass:before {
	content: "\e038";
}
.icon-linegraph:before {
	content: "\e039";
}
.icon-mic:before {
	content: "\e03a";
}
.icon-strategy:before {
	content: "\e03b";
}
.icon-beaker:before {
	content: "\e03c";
}
.icon-caution:before {
	content: "\e03d";
}
.icon-recycle:before {
	content: "\e03e";
}
.icon-anchor:before {
	content: "\e03f";
}
.icon-profile-male:before {
	content: "\e040";
}
.icon-profile-female:before {
	content: "\e041";
}
.icon-bike:before {
	content: "\e042";
}
.icon-wine:before {
	content: "\e043";
}
.icon-hotairballoon:before {
	content: "\e044";
}
.icon-globe:before {
	content: "\e045";
}
.icon-genius:before {
	content: "\e046";
}
.icon-map-pin:before {
	content: "\e047";
}
.icon-dial:before {
	content: "\e048";
}
.icon-chat:before {
	content: "\e049";
}
.icon-heart2:before {
	content: "\e04a";
}
.icon-cloud2:before {
	content: "\e04b";
}
.icon-upload:before {
	content: "\e04c";
}
.icon-download:before {
	content: "\e04d";
}
.icon-target:before {
	content: "\e04e";
}
.icon-hazardous:before {
	content: "\e04f";
}
.icon-piechart:before {
	content: "\e050";
}
.icon-speedometer:before {
	content: "\e051";
}
.icon-global:before {
	content: "\e052";
}
.icon-compass:before {
	content: "\e053";
}
.icon-lifesaver:before {
	content: "\e054";
}
.icon-clock3:before {
	content: "\e055";
}
.icon-aperture:before {
	content: "\e056";
}
.icon-quote:before {
	content: "\e057";
}
.icon-scope:before {
	content: "\e058";
}
.icon-alarmclock:before {
	content: "\e059";
}
.icon-refresh:before {
	content: "\e05a";
}
.icon-happy:before {
	content: "\e05b";
}
.icon-sad:before {
	content: "\e05c";
}
.icon-facebook:before {
	content: "\e05d";
}
.icon-twitter:before {
	content: "\e05e";
}
.icon-googleplus:before {
	content: "\e05f";
}
.icon-rss:before {
	content: "\e060";
}
.icon-tumblr:before {
	content: "\e061";
}
.icon-linkedin:before {
	content: "\e062";
}
.icon-dribbble:before {
	content: "\e063";
}
.icon-number:before {
	content: "\e647";
}
.icon-number2:before {
	content: "\e648";
}
.icon-number3:before {
	content: "\e649";
}
.icon-number4:before {
	content: "\e64a";
}
.icon-number5:before {
	content: "\e64b";
}
.icon-number6:before {
	content: "\e64c";
}
.icon-number7:before {
	content: "\e64d";
}
.icon-number8:before {
	content: "\e64e";
}
.icon-number9:before {
	content: "\e64f";
}
.icon-number10:before {
	content: "\e650";
}
.icon-number11:before {
	content: "\e651";
}
.icon-number12:before {
	content: "\e652";
}
.icon-number13:before {
	content: "\e653";
}
.icon-number14:before {
	content: "\e654";
}
.icon-number15:before {
	content: "\e655";
}
.icon-number16:before {
	content: "\e656";
}
.icon-number17:before {
	content: "\e657";
}
.icon-number18:before {
	content: "\e658";
}
.icon-number19:before {
	content: "\e659";
}
.icon-number20:before {
	content: "\e65a";
}
.icon-quote2:before {
	content: "\e65b";
}
.icon-quote3:before {
	content: "\e65c";
}
.icon-tag2:before {
	content: "\e65d";
}
.icon-tag3:before {
	content: "\e65e";
}
.icon-link:before {
	content: "\e65f";
}
.icon-link2:before {
	content: "\e660";
}
.icon-cabinet:before {
	content: "\e661";
}
.icon-cabinet2:before {
	content: "\e662";
}
.icon-calendar3:before {
	content: "\e663";
}
.icon-calendar4:before {
	content: "\e664";
}
.icon-calendar5:before {
	content: "\e665";
}
.icon-file:before {
	content: "\e666";
}
.icon-file2:before {
	content: "\e667";
}
.icon-file3:before {
	content: "\e668";
}
.icon-files:before {
	content: "\e669";
}
.icon-phone3:before {
	content: "\e66a";
}
.icon-tablet2:before {
	content: "\e66b";
}
.icon-window:before {
	content: "\e66c";
}
.icon-monitor:before {
	content: "\e66d";
}
.icon-ipod:before {
	content: "\e66e";
}
.icon-tv2:before {
	content: "\e66f";
}
.icon-camera3:before {
	content: "\e670";
}
.icon-camera4:before {
	content: "\e671";
}
.icon-camera5:before {
	content: "\e672";
}
.icon-film:before {
	content: "\e673";
}
.icon-film2:before {
	content: "\e674";
}
.icon-film3:before {
	content: "\e675";
}
.icon-microphone:before {
	content: "\e676";
}
.icon-microphone2:before {
	content: "\e677";
}
.icon-microphone3:before {
	content: "\e678";
}
.icon-drink:before {
	content: "\e679";
}
.icon-drink2:before {
	content: "\e67a";
}
.icon-drink3:before {
	content: "\e67b";
}
.icon-drink4:before {
	content: "\e67c";
}
.icon-coffee:before {
	content: "\e67d";
}
.icon-mug:before {
	content: "\e67e";
}
.icon-icecream:before {
	content: "\e67f";
}
.icon-cake:before {
	content: "\e680";
}
.icon-inbox:before {
	content: "\e681";
}
.icon-download4:before {
	content: "\e682";
}
.icon-upload4:before {
	content: "\e683";
}
.icon-inbox2:before {
	content: "\e684";
}
.icon-checkmark:before {
	content: "\e685";
}
.icon-checkmark3:before {
	content: "\e686";
}
.icon-cancel:before {
	content: "\e687";
}
.icon-cancel2:before {
	content: "\e688";
}
.icon-plus:before {
	content: "\e689";
}
.icon-plus2:before {
	content: "\e68a";
}
.icon-minus:before {
	content: "\e68b";
}
.icon-minus2:before {
	content: "\e68c";
}
.icon-notice:before {
	content: "\e68d";
}
.icon-notice2:before {
	content: "\e68e";
}
.icon-cog:before {
	content: "\e68f";
}
.icon-cogs:before {
	content: "\e690";
}
.icon-cog2:before {
	content: "\e691";
}
.icon-warning:before {
	content: "\e692";
}
.icon-health:before {
	content: "\e693";
}
.icon-suitcase:before {
	content: "\e694";
}
.icon-suitcase2:before {
	content: "\e695";
}
.icon-suitcase3:before {
	content: "\e696";
}
.icon-picture2:before {
	content: "\e697";
}
.icon-pictures2:before {
	content: "\e698";
}
.icon-pictures3:before {
	content: "\e699";
}
.icon-android:before {
	content: "\e69a";
}
.icon-marvin:before {
	content: "\e69b";
}
.icon-pacman:before {
	content: "\e69c";
}
.icon-cassette:before {
	content: "\e69d";
}
.icon-watch:before {
	content: "\e69e";
}
.icon-chronometer:before {
	content: "\e69f";
}
.icon-watch2:before {
	content: "\e6a0";
}
.icon-alarmclock2:before {
	content: "\e6a1";
}
.icon-time:before {
	content: "\e6a2";
}
.icon-time2:before {
	content: "\e6a3";
}
.icon-headphones:before {
	content: "\e6a4";
}
.icon-wallet3:before {
	content: "\e6a5";
}
.icon-checkmark4:before {
	content: "\e6a6";
}
.icon-cancel3:before {
	content: "\e6a7";
}
.icon-eye2:before {
	content: "\e6a8";
}
.icon-position:before {
	content: "\e6a9";
}
.icon-sitemap:before {
	content: "\e6aa";
}
.icon-sitemap2:before {
	content: "\e6ab";
}
.icon-cloud3:before {
	content: "\e6ac";
}
.icon-upload5:before {
	content: "\e6ad";
}
.icon-chart:before {
	content: "\e6ae";
}
.icon-chart2:before {
	content: "\e6af";
}
.icon-chart3:before {
	content: "\e6b0";
}
.icon-chart4:before {
	content: "\e6b1";
}
.icon-chart5:before {
	content: "\e6b2";
}
.icon-chart6:before {
	content: "\e6b3";
}
.icon-location3:before {
	content: "\e6b4";
}
.icon-download5:before {
	content: "\e6b5";
}
.icon-basket2:before {
	content: "\e6b6";
}
.icon-folder:before {
	content: "\e6b7";
}
.icon-gamepad:before {
	content: "\e6b8";
}
.icon-alarm:before {
	content: "\e6b9";
}
.icon-alarm-cancel:before {
	content: "\e6ba";
}
.icon-phone4:before {
	content: "\e6bb";
}
.icon-phone5:before {
	content: "\e6bc";
}
.icon-image:before {
	content: "\e6bd";
}
.icon-open:before {
	content: "\e6be";
}
.icon-sale:before {
	content: "\e6bf";
}
.icon-direction:before {
	content: "\e6c0";
}
.icon-map4:before {
	content: "\e6c1";
}
.icon-trashcan:before {
	content: "\e6c2";
}
.icon-vote:before {
	content: "\e6c3";
}
.icon-graduate:before {
	content: "\e6c4";
}
.icon-lab2:before {
	content: "\e6c5";
}
.icon-tie:before {
	content: "\e6c6";
}
.icon-football:before {
	content: "\e6c7";
}
.icon-eightball:before {
	content: "\e6c8";
}
.icon-bowling:before {
	content: "\e6c9";
}
.icon-bowlingpin:before {
	content: "\e6ca";
}
.icon-baseball:before {
	content: "\e6cb";
}
.icon-soccer:before {
	content: "\e6cc";
}
.icon-3dglasses:before {
	content: "\e6cd";
}
.icon-microwave:before {
	content: "\e6ce";
}
.icon-refrigerator:before {
	content: "\e6cf";
}
.icon-oven:before {
	content: "\e6d0";
}
.icon-washingmachine:before {
	content: "\e6d1";
}
.icon-mouse:before {
	content: "\e6d2";
}
.icon-smiley:before {
	content: "\e6d3";
}
.icon-sad3:before {
	content: "\e6d4";
}
.icon-mute:before {
	content: "\e6d5";
}
.icon-hand:before {
	content: "\e6d6";
}
.icon-radio:before {
	content: "\e6d7";
}
.icon-satellite:before {
	content: "\e6d8";
}
.icon-medal:before {
	content: "\e6d9";
}
.icon-medal2:before {
	content: "\e6da";
}
.icon-switch:before {
	content: "\e6db";
}
.icon-key4:before {
	content: "\e6dc";
}
.icon-cord:before {
	content: "\e6dd";
}
.icon-locked:before {
	content: "\e6de";
}
.icon-unlocked:before {
	content: "\e6df";
}
.icon-locked2:before {
	content: "\e6e0";
}
.icon-unlocked2:before {
	content: "\e6e1";
}
.icon-magnifier:before {
	content: "\e6e2";
}
.icon-zoomin:before {
	content: "\e6e3";
}
.icon-zoomout:before {
	content: "\e6e4";
}
.icon-stack2:before {
	content: "\e6e5";
}
.icon-stack3:before {
	content: "\e6e6";
}
.icon-stack4:before {
	content: "\e6e7";
}
.icon-davidstar:before {
	content: "\e6e8";
}
.icon-cross:before {
	content: "\e6e9";
}
.icon-moonandstar:before {
	content: "\e6ea";
}
.icon-transformers:before {
	content: "\e6eb";
}
.icon-batman:before {
	content: "\e6ec";
}
.icon-spaceinvaders:before {
	content: "\e6ed";
}
.icon-skeletor:before {
	content: "\e6ee";
}
.icon-lamp:before {
	content: "\e6ef";
}
.icon-lamp2:before {
	content: "\e6f0";
}
.icon-umbrella:before {
	content: "\e6f1";
}
.icon-streetlight:before {
	content: "\e6f2";
}
.icon-bomb:before {
	content: "\e6f3";
}
.icon-archive:before {
	content: "\e6f4";
}
.icon-battery:before {
	content: "\e6f5";
}
.icon-battery2:before {
	content: "\e6f6";
}
.icon-battery3:before {
	content: "\e6f7";
}
.icon-battery4:before {
	content: "\e6f8";
}
.icon-battery5:before {
	content: "\e6f9";
}
.icon-megaphone3:before {
	content: "\e6fa";
}
.icon-megaphone4:before {
	content: "\e6fb";
}
.icon-patch:before {
	content: "\e6fc";
}
.icon-pil:before {
	content: "\e6fd";
}
.icon-injection:before {
	content: "\e6fe";
}
.icon-thermometer:before {
	content: "\e6ff";
}
.icon-lamp3:before {
	content: "\e700";
}
.icon-lamp4:before {
	content: "\e701";
}
.icon-lamp5:before {
	content: "\e702";
}
.icon-cube:before {
	content: "\e703";
}
.icon-box:before {
	content: "\e704";
}
.icon-box2:before {
	content: "\e705";
}
.icon-diamond2:before {
	content: "\e706";
}
.icon-bag:before {
	content: "\e707";
}
.icon-moneybag:before {
	content: "\e708";
}
.icon-grid2:before {
	content: "\e709";
}
.icon-grid3:before {
	content: "\e70a";
}
.icon-list:before {
	content: "\e70b";
}
.icon-list3:before {
	content: "\e70c";
}
.icon-ruler:before {
	content: "\e70d";
}
.icon-ruler2:before {
	content: "\e70e";
}
.icon-layout:before {
	content: "\e70f";
}
.icon-layout2:before {
	content: "\e710";
}
.icon-layout3:before {
	content: "\e711";
}
.icon-layout4:before {
	content: "\e712";
}
.icon-layout5:before {
	content: "\e713";
}
.icon-layout6:before {
	content: "\e714";
}
.icon-layout7:before {
	content: "\e715";
}
.icon-layout8:before {
	content: "\e716";
}
.icon-layout9:before {
	content: "\e717";
}
.icon-layout10:before {
	content: "\e718";
}
.icon-layout11:before {
	content: "\e719";
}
.icon-layout12:before {
	content: "\e71a";
}
.icon-layout13:before {
	content: "\e71b";
}
.icon-layout14:before {
	content: "\e71c";
}
.icon-tools2:before {
	content: "\e71d";
}
.icon-screwdriver:before {
	content: "\e71e";
}
.icon-paint:before {
	content: "\e71f";
}
.icon-hammer:before {
	content: "\e720";
}
.icon-brush:before {
	content: "\e721";
}
.icon-pen2:before {
	content: "\e722";
}
.icon-chat2:before {
	content: "\e723";
}
.icon-comments:before {
	content: "\e724";
}
.icon-chat3:before {
	content: "\e725";
}
.icon-chat4:before {
	content: "\e726";
}
.icon-volume:before {
	content: "\e727";
}
.icon-volume2:before {
	content: "\e728";
}
.icon-volume3:before {
	content: "\e729";
}
.icon-equalizer:before {
	content: "\e72a";
}
.icon-resize:before {
	content: "\e72b";
}
.icon-resize2:before {
	content: "\e72c";
}
.icon-stretch:before {
	content: "\e72d";
}
.icon-narrow:before {
	content: "\e72e";
}
.icon-resize3:before {
	content: "\e72f";
}
.icon-download6:before {
	content: "\e730";
}
.icon-calculator:before {
	content: "\e731";
}
.icon-library:before {
	content: "\e732";
}
.icon-auction:before {
	content: "\e733";
}
.icon-justice:before {
	content: "\e734";
}
.icon-stats:before {
	content: "\e735";
}
.icon-stats2:before {
	content: "\e736";
}
.icon-attachment2:before {
	content: "\e737";
}
.icon-hourglass2:before {
	content: "\e738";
}
.icon-abacus:before {
	content: "\e739";
}
.icon-pencil3:before {
	content: "\e73a";
}
.icon-pen3:before {
	content: "\e73b";
}
.icon-pin:before {
	content: "\e73c";
}
.icon-pin2:before {
	content: "\e73d";
}
.icon-discout:before {
	content: "\e73e";
}
.icon-edit2:before {
	content: "\e73f";
}
.icon-scissors2:before {
	content: "\e740";
}
.icon-profile:before {
	content: "\e741";
}
.icon-profile2:before {
	content: "\e742";
}
.icon-profile3:before {
	content: "\e743";
}
.icon-rotate:before {
	content: "\e744";
}
.icon-rotate2:before {
	content: "\e745";
}
.icon-reply:before {
	content: "\e746";
}
.icon-forward:before {
	content: "\e747";
}
.icon-retweet:before {
	content: "\e748";
}
.icon-shuffle:before {
	content: "\e749";
}
.icon-loop:before {
	content: "\e74a";
}
.icon-crop:before {
	content: "\e74b";
}
.icon-square:before {
	content: "\e74c";
}
.icon-square2:before {
	content: "\e74d";
}
.icon-circle:before {
	content: "\e74e";
}
.icon-dollar2:before {
	content: "\e74f";
}
.icon-dollar3:before {
	content: "\e750";
}
.icon-coins:before {
	content: "\e751";
}
.icon-pig:before {
	content: "\e752";
}
.icon-bookmark:before {
	content: "\e753";
}
.icon-bookmark2:before {
	content: "\e754";
}
.icon-addressbook:before {
	content: "\e755";
}
.icon-addressbook2:before {
	content: "\e756";
}
.icon-safe:before {
	content: "\e757";
}
.icon-envelope2:before {
	content: "\e758";
}
.icon-envelope3:before {
	content: "\e759";
}
.icon-radioactive:before {
	content: "\e75a";
}
.icon-music2:before {
	content: "\e75b";
}
.icon-presentation2:before {
	content: "\e75c";
}
.icon-male:before {
	content: "\e75d";
}
.icon-female:before {
	content: "\e75e";
}
.icon-aids:before {
	content: "\e75f";
}
.icon-heart3:before {
	content: "\e760";
}
.icon-info:before {
	content: "\e761";
}
.icon-info2:before {
	content: "\e762";
}
.icon-piano:before {
	content: "\e763";
}
.icon-rain:before {
	content: "\e764";
}
.icon-snow:before {
	content: "\e765";
}
.icon-lightning:before {
	content: "\e766";
}
.icon-sun:before {
	content: "\e767";
}
.icon-moon:before {
	content: "\e768";
}
.icon-cloudy:before {
	content: "\e769";
}
.icon-cloudy2:before {
	content: "\e76a";
}
.icon-car:before {
	content: "\e76b";
}
.icon-bike2:before {
	content: "\e76c";
}
.icon-truck3:before {
	content: "\e76d";
}
.icon-bus:before {
	content: "\e76e";
}
.icon-bike3:before {
	content: "\e76f";
}
.icon-plane:before {
	content: "\e770";
}
.icon-paperplane2:before {
	content: "\e771";
}
.icon-rocket:before {
	content: "\e772";
}
.icon-book:before {
	content: "\e773";
}
.icon-book2:before {
	content: "\e774";
}
.icon-barcode:before {
	content: "\e775";
}
.icon-barcode2:before {
	content: "\e776";
}
.icon-expand2:before {
	content: "\e777";
}
.icon-collapse:before {
	content: "\e778";
}
.icon-popout:before {
	content: "\e779";
}
.icon-popin:before {
	content: "\e77a";
}
.icon-target2:before {
	content: "\e77b";
}
.icon-badge:before {
	content: "\e77c";
}
.icon-badge2:before {
	content: "\e77d";
}
.icon-ticket:before {
	content: "\e77e";
}
.icon-ticket2:before {
	content: "\e77f";
}
.icon-ticket3:before {
	content: "\e780";
}
.icon-microphone4:before {
	content: "\e781";
}
.icon-cone:before {
	content: "\e782";
}
.icon-blocked:before {
	content: "\e783";
}
.icon-stop:before {
	content: "\e784";
}
.icon-keyboard:before {
	content: "\e785";
}
.icon-keyboard2:before {
	content: "\e786";
}
.icon-radio2:before {
	content: "\e787";
}
.icon-printer2:before {
	content: "\e788";
}
.icon-checked:before {
	content: "\e789";
}
.icon-error:before {
	content: "\e78a";
}
.icon-add:before {
	content: "\e78b";
}
.icon-minus3:before {
	content: "\e78c";
}
.icon-alert:before {
	content: "\e78d";
}
.icon-pictures4:before {
	content: "\e78e";
}
.icon-atom:before {
	content: "\e78f";
}
.icon-eyedropper:before {
	content: "\e790";
}
.icon-globe2:before {
	content: "\e791";
}
.icon-globe3:before {
	content: "\e792";
}
.icon-shipping:before {
	content: "\e793";
}
.icon-yingyang:before {
	content: "\e794";
}
.icon-compass3:before {
	content: "\e795";
}
.icon-zip:before {
	content: "\e796";
}
.icon-zip2:before {
	content: "\e797";
}
.icon-anchor2:before {
	content: "\e798";
}
.icon-lockedheart:before {
	content: "\e799";
}
.icon-magnet:before {
	content: "\e79a";
}
.icon-navigation:before {
	content: "\e79b";
}
.icon-tags:before {
	content: "\e79c";
}
.icon-heart4:before {
	content: "\e79d";
}
.icon-heart5:before {
	content: "\e79e";
}
.icon-usb:before {
	content: "\e79f";
}
.icon-clipboard2:before {
	content: "\e7a0";
}
.icon-clipboard3:before {
	content: "\e7a1";
}
.icon-clipboard4:before {
	content: "\e7a2";
}
.icon-switch2:before {
	content: "\e7a3";
}
.icon-ruler3:before {
	content: "\e7a4";
}
.icon-add-to-list:before {
	content: "\e7a5";
}
.icon-classic-computer:before {
	content: "\e7a6";
}
.icon-controller-fast-backward:before {
	content: "\e7a7";
}
.icon-creative-commons-attribution:before {
	content: "\e7a8";
}
.icon-creative-commons-noderivs:before {
	content: "\e7a9";
}
.icon-creative-commons-noncommercial-eu:before {
	content: "\e7aa";
}
.icon-creative-commons-noncommercial-us:before {
	content: "\e7ab";
}
.icon-creative-commons-public-domain:before {
	content: "\e7ac";
}
.icon-creative-commons-remix:before {
	content: "\e7ad";
}
.icon-creative-commons-share:before {
	content: "\e7ae";
}
.icon-creative-commons-sharealike:before {
	content: "\e7af";
}
.icon-creative-commons:before {
	content: "\e7b0";
}
.icon-document-landscape:before {
	content: "\e7b1";
}
.icon-remove-user:before {
	content: "\e7b2";
}
.icon-warning2:before {
	content: "\e7b3";
}
.icon-arrow-bold-down:before {
	content: "\e7b4";
}
.icon-arrow-bold-left:before {
	content: "\e7b5";
}
.icon-arrow-bold-right:before {
	content: "\e7b6";
}
.icon-arrow-bold-up:before {
	content: "\e7b7";
}
.icon-arrow-down:before {
	content: "\e7b8";
}
.icon-arrow-left:before {
	content: "\e7b9";
}
.icon-arrow-long-down:before {
	content: "\e7ba";
}
.icon-arrow-long-left:before {
	content: "\e7bb";
}
.icon-arrow-long-right:before {
	content: "\e7bc";
}
.icon-arrow-long-up:before {
	content: "\e7bd";
}
.icon-arrow-right:before {
	content: "\e7be";
}
.icon-arrow-up:before {
	content: "\e7bf";
}
.icon-arrow-with-circle-down:before {
	content: "\e7c0";
}
.icon-arrow-with-circle-left:before {
	content: "\e7c1";
}
.icon-arrow-with-circle-right:before {
	content: "\e7c2";
}
.icon-arrow-with-circle-up:before {
	content: "\e7c3";
}
.icon-bookmark3:before {
	content: "\e7c4";
}
.icon-bookmarks:before {
	content: "\e7c5";
}
.icon-chevron-down:before {
	content: "\e7c6";
}
.icon-chevron-left:before {
	content: "\e7c7";
}
.icon-chevron-right:before {
	content: "\e7c8";
}
.icon-chevron-small-down:before {
	content: "\e7c9";
}
.icon-chevron-small-left:before {
	content: "\e7ca";
}
.icon-chevron-small-right:before {
	content: "\e7cb";
}
.icon-chevron-small-up:before {
	content: "\e7cc";
}
.icon-chevron-thin-down:before {
	content: "\e7cd";
}
.icon-chevron-thin-left:before {
	content: "\e7ce";
}
.icon-chevron-thin-right:before {
	content: "\e7cf";
}
.icon-chevron-thin-up:before {
	content: "\e7d0";
}
.icon-chevron-up:before {
	content: "\e7d1";
}
.icon-chevron-with-circle-down:before {
	content: "\e7d2";
}
.icon-chevron-with-circle-left:before {
	content: "\e7d3";
}
.icon-chevron-with-circle-right:before {
	content: "\e7d4";
}
.icon-chevron-with-circle-up:before {
	content: "\e7d5";
}
.icon-cloud4:before {
	content: "\e7d6";
}
.icon-controller-fast-forward:before {
	content: "\e7d7";
}
.icon-controller-jump-to-start:before {
	content: "\e7d8";
}
.icon-controller-next:before {
	content: "\e7d9";
}
.icon-controller-paus:before {
	content: "\e7da";
}
.icon-controller-play:before {
	content: "\e7db";
}
.icon-controller-record:before {
	content: "\e7dc";
}
.icon-controller-stop:before {
	content: "\e7dd";
}
.icon-controller-volume:before {
	content: "\e7de";
}
.icon-dot-single:before {
	content: "\e7df";
}
.icon-dots-three-horizontal:before {
	content: "\e7e0";
}
.icon-dots-three-vertical:before {
	content: "\e7e1";
}
.icon-dots-two-horizontal:before {
	content: "\e7e2";
}
.icon-dots-two-vertical:before {
	content: "\e7e3";
}
.icon-download7:before {
	content: "\e7e4";
}
.icon-emoji-flirt:before {
	content: "\e7e5";
}
.icon-flow-branch:before {
	content: "\e7e6";
}
.icon-flow-cascade:before {
	content: "\e7e7";
}
.icon-flow-line:before {
	content: "\e7e8";
}
.icon-flow-parallel:before {
	content: "\e7e9";
}
.icon-flow-tree:before {
	content: "\e7ea";
}
.icon-install:before {
	content: "\e7eb";
}
.icon-layers2:before {
	content: "\e7ec";
}
.icon-open-book:before {
	content: "\e7ed";
}
.icon-resize-100:before {
	content: "\e7ee";
}
.icon-resize-full-screen:before {
	content: "\e7ef";
}
.icon-save:before {
	content: "\e7f0";
}
.icon-select-arrows:before {
	content: "\e7f1";
}
.icon-sound-mute:before {
	content: "\e7f2";
}
.icon-sound2:before {
	content: "\e7f3";
}
.icon-trash2:before {
	content: "\e7f4";
}
.icon-triangle-down:before {
	content: "\e7f5";
}
.icon-triangle-left:before {
	content: "\e7f6";
}
.icon-triangle-right:before {
	content: "\e7f7";
}
.icon-triangle-up:before {
	content: "\e7f8";
}
.icon-uninstall:before {
	content: "\e7f9";
}
.icon-upload-to-cloud:before {
	content: "\e7fa";
}
.icon-upload6:before {
	content: "\e7fb";
}
.icon-add-user:before {
	content: "\e7fc";
}
.icon-address:before {
	content: "\e7fd";
}
.icon-adjust:before {
	content: "\e7fe";
}
.icon-air:before {
	content: "\e7ff";
}
.icon-aircraft-landing:before {
	content: "\e800";
}
.icon-aircraft-take-off:before {
	content: "\e801";
}
.icon-aircraft:before {
	content: "\e802";
}
.icon-align-bottom:before {
	content: "\e803";
}
.icon-align-horizontal-middle:before {
	content: "\e804";
}
.icon-align-left:before {
	content: "\e805";
}
.icon-align-right:before {
	content: "\e806";
}
.icon-align-top:before {
	content: "\e807";
}
.icon-align-vertical-middle:before {
	content: "\e808";
}
.icon-archive2:before {
	content: "\e809";
}
.icon-area-graph:before {
	content: "\e80a";
}
.icon-attachment3:before {
	content: "\e80b";
}
.icon-awareness-ribbon:before {
	content: "\e80c";
}
.icon-back-in-time:before {
	content: "\e80d";
}
.icon-back:before {
	content: "\e80e";
}
.icon-bar-graph:before {
	content: "\e80f";
}
.icon-battery6:before {
	content: "\e810";
}
.icon-beamed-note:before {
	content: "\e811";
}
.icon-bell:before {
	content: "\e812";
}
.icon-blackboard:before {
	content: "\e813";
}
.icon-block:before {
	content: "\e814";
}
.icon-book3:before {
	content: "\e815";
}
.icon-bowl:before {
	content: "\e816";
}
.icon-box3:before {
	content: "\e817";
}
.icon-briefcase2:before {
	content: "\e818";
}
.icon-browser2:before {
	content: "\e819";
}
.icon-brush2:before {
	content: "\e81a";
}
.icon-bucket:before {
	content: "\e81b";
}
.icon-cake2:before {
	content: "\e81c";
}
.icon-calculator2:before {
	content: "\e81d";
}
.icon-calendar6:before {
	content: "\e81e";
}
.icon-camera6:before {
	content: "\e81f";
}
.icon-ccw:before {
	content: "\e820";
}
.icon-chat5:before {
	content: "\e821";
}
.icon-check:before {
	content: "\e822";
}
.icon-circle-with-cross:before {
	content: "\e823";
}
.icon-circle-with-minus:before {
	content: "\e824";
}
.icon-circle-with-plus:before {
	content: "\e825";
}
.icon-circle2:before {
	content: "\e826";
}
.icon-circular-graph:before {
	content: "\e827";
}
.icon-clapperboard:before {
	content: "\e828";
}
.icon-clipboard5:before {
	content: "\e829";
}
.icon-clock4:before {
	content: "\e82a";
}
.icon-code:before {
	content: "\e82b";
}
.icon-cog3:before {
	content: "\e82c";
}
.icon-colours:before {
	content: "\e82d";
}
.icon-compass4:before {
	content: "\e82e";
}
.icon-copy:before {
	content: "\e82f";
}
.icon-credit-card:before {
	content: "\e830";
}
.icon-credit:before {
	content: "\e831";
}
.icon-cross2:before {
	content: "\e832";
}
.icon-cup2:before {
	content: "\e833";
}
.icon-cw:before {
	content: "\e834";
}
.icon-cycle:before {
	content: "\e835";
}
.icon-database:before {
	content: "\e836";
}
.icon-dial-pad:before {
	content: "\e837";
}
.icon-direction2:before {
	content: "\e838";
}
.icon-document2:before {
	content: "\e839";
}
.icon-documents2:before {
	content: "\e83a";
}
.icon-drink5:before {
	content: "\e83b";
}
.icon-drive:before {
	content: "\e83c";
}
.icon-drop:before {
	content: "\e83d";
}
.icon-edit3:before {
	content: "\e83e";
}
.icon-email:before {
	content: "\e83f";
}
.icon-emoji-happy:before {
	content: "\e840";
}
.icon-emoji-neutral:before {
	content: "\e841";
}
.icon-emoji-sad:before {
	content: "\e842";
}
.icon-erase:before {
	content: "\e843";
}
.icon-eraser:before {
	content: "\e844";
}
.icon-export:before {
	content: "\e845";
}
.icon-eye3:before {
	content: "\e846";
}
.icon-feather:before {
	content: "\e847";
}
.icon-flag2:before {
	content: "\e848";
}
.icon-flash:before {
	content: "\e849";
}
.icon-flashlight:before {
	content: "\e84a";
}
.icon-flat-brush:before {
	content: "\e84b";
}
.icon-folder-images:before {
	content: "\e84c";
}
.icon-folder-music:before {
	content: "\e84d";
}
.icon-folder-video:before {
	content: "\e84e";
}
.icon-folder2:before {
	content: "\e84f";
}
.icon-forward4:before {
	content: "\e850";
}
.icon-funnel:before {
	content: "\e851";
}
.icon-game-controller:before {
	content: "\e852";
}
.icon-gauge:before {
	content: "\e853";
}
.icon-globe4:before {
	content: "\e854";
}
.icon-graduation-cap:before {
	content: "\e855";
}
.icon-grid4:before {
	content: "\e856";
}
.icon-hair-cross:before {
	content: "\e857";
}
.icon-hand2:before {
	content: "\e858";
}
.icon-heart-outlined:before {
	content: "\e859";
}
.icon-heart6:before {
	content: "\e85a";
}
.icon-help-with-circle:before {
	content: "\e85b";
}
.icon-help:before {
	content: "\e85c";
}
.icon-home:before {
	content: "\e85d";
}
.icon-hour-glass:before {
	content: "\e85e";
}
.icon-image-inverted:before {
	content: "\e85f";
}
.icon-image2:before {
	content: "\e860";
}
.icon-images:before {
	content: "\e861";
}
.icon-inbox3:before {
	content: "\e862";
}
.icon-infinity:before {
	content: "\e863";
}
.icon-info-with-circle:before {
	content: "\e864";
}
.icon-info3:before {
	content: "\e865";
}
.icon-key5:before {
	content: "\e866";
}
.icon-keyboard3:before {
	content: "\e867";
}
.icon-lab-flask:before {
	content: "\e868";
}
.icon-landline:before {
	content: "\e869";
}
.icon-language:before {
	content: "\e86a";
}
.icon-laptop2:before {
	content: "\e86b";
}
.icon-leaf:before {
	content: "\e86c";
}
.icon-level-down:before {
	content: "\e86d";
}
.icon-level-up:before {
	content: "\e86e";
}
.icon-lifebuoy:before {
	content: "\e86f";
}
.icon-light-bulb:before {
	content: "\e870";
}
.icon-light-down:before {
	content: "\e871";
}
.icon-light-up:before {
	content: "\e872";
}
.icon-line-graph:before {
	content: "\e873";
}
.icon-link3:before {
	content: "\e874";
}
.icon-list4:before {
	content: "\e875";
}
.icon-location-pin:before {
	content: "\e876";
}
.icon-location4:before {
	content: "\e877";
}
.icon-lock-open:before {
	content: "\e878";
}
.icon-lock3:before {
	content: "\e879";
}
.icon-log-out:before {
	content: "\e87a";
}
.icon-login:before {
	content: "\e87b";
}
.icon-loop3:before {
	content: "\e87c";
}
.icon-magnet2:before {
	content: "\e87d";
}
.icon-magnifying-glass2:before {
	content: "\e87e";
}
.icon-mail5:before {
	content: "\e87f";
}
.icon-man:before {
	content: "\e880";
}
.icon-map5:before {
	content: "\e881";
}
.icon-mask:before {
	content: "\e882";
}
.icon-medal3:before {
	content: "\e883";
}
.icon-megaphone5:before {
	content: "\e884";
}
.icon-menu:before {
	content: "\e885";
}
.icon-message:before {
	content: "\e886";
}
.icon-mic2:before {
	content: "\e887";
}
.icon-minus4:before {
	content: "\e888";
}
.icon-mobile3:before {
	content: "\e889";
}
.icon-modern-mic:before {
	content: "\e88a";
}
.icon-moon2:before {
	content: "\e88b";
}
.icon-mouse2:before {
	content: "\e88c";
}
.icon-music3:before {
	content: "\e88d";
}
.icon-network:before {
	content: "\e88e";
}
.icon-new-message:before {
	content: "\e88f";
}
.icon-new:before {
	content: "\e890";
}
.icon-news2:before {
	content: "\e891";
}
.icon-note2:before {
	content: "\e892";
}
.icon-notification:before {
	content: "\e893";
}
.icon-old-mobile:before {
	content: "\e894";
}
.icon-old-phone:before {
	content: "\e895";
}
.icon-palette:before {
	content: "\e896";
}
.icon-paper-plane:before {
	content: "\e897";
}
.icon-pencil4:before {
	content: "\e898";
}
.icon-phone6:before {
	content: "\e899";
}
.icon-pie-chart:before {
	content: "\e89a";
}
.icon-pin3:before {
	content: "\e89b";
}
.icon-plus3:before {
	content: "\e89c";
}
.icon-popup:before {
	content: "\e89d";
}
.icon-power-plug:before {
	content: "\e89e";
}
.icon-price-ribbon:before {
	content: "\e89f";
}
.icon-price-tag:before {
	content: "\e8a0";
}
.icon-print:before {
	content: "\e8a1";
}
.icon-progress-empty:before {
	content: "\e8a2";
}
.icon-progress-full:before {
	content: "\e8a3";
}
.icon-progress-one:before {
	content: "\e8a4";
}
.icon-progress-two:before {
	content: "\e8a5";
}
.icon-publish:before {
	content: "\e8a6";
}
.icon-quote4:before {
	content: "\e8a7";
}
.icon-radio3:before {
	content: "\e8a8";
}
.icon-reply-all:before {
	content: "\e8a9";
}
.icon-reply2:before {
	content: "\e8aa";
}
.icon-retweet2:before {
	content: "\e8ab";
}
.icon-rocket2:before {
	content: "\e8ac";
}
.icon-round-brush:before {
	content: "\e8ad";
}
.icon-rss2:before {
	content: "\e8ae";
}
.icon-ruler4:before {
	content: "\e8af";
}
.icon-scissors3:before {
	content: "\e8b0";
}
.icon-share-alternitive:before {
	content: "\e8b1";
}
.icon-share:before {
	content: "\e8b2";
}
.icon-shareable:before {
	content: "\e8b3";
}
.icon-shield2:before {
	content: "\e8b4";
}
.icon-shop2:before {
	content: "\e8b5";
}
.icon-shopping-bag:before {
	content: "\e8b6";
}
.icon-shopping-basket:before {
	content: "\e8b7";
}
.icon-shopping-cart:before {
	content: "\e8b8";
}
.icon-shuffle2:before {
	content: "\e8b9";
}
.icon-signal:before {
	content: "\e8ba";
}
.icon-sound-mix:before {
	content: "\e8bb";
}
.icon-sports-club:before {
	content: "\e8bc";
}
.icon-spreadsheet:before {
	content: "\e8bd";
}
.icon-squared-cross:before {
	content: "\e8be";
}
.icon-squared-minus:before {
	content: "\e8bf";
}
.icon-squared-plus:before {
	content: "\e8c0";
}
.icon-star-outlined:before {
	content: "\e8c1";
}
.icon-star2:before {
	content: "\e8c2";
}
.icon-stopwatch:before {
	content: "\e8c3";
}
.icon-suitcase4:before {
	content: "\e8c4";
}
.icon-swap:before {
	content: "\e8c5";
}
.icon-sweden:before {
	content: "\e8c6";
}
.icon-switch3:before {
	content: "\e8c7";
}
.icon-tablet3:before {
	content: "\e8c8";
}
.icon-tag4:before {
	content: "\e8c9";
}
.icon-text-document-inverted:before {
	content: "\e8ca";
}
.icon-text-document:before {
	content: "\e8cb";
}
.icon-text:before {
	content: "\e8cc";
}
.icon-thermometer2:before {
	content: "\e8cd";
}
.icon-thumbs-down:before {
	content: "\e8ce";
}
.icon-thumbs-up:before {
	content: "\e8cf";
}
.icon-thunder-cloud:before {
	content: "\e8d0";
}
.icon-ticket4:before {
	content: "\e8d1";
}
.icon-time-slot:before {
	content: "\e8d2";
}
.icon-tools3:before {
	content: "\e8d3";
}
.icon-traffic-cone:before {
	content: "\e8d4";
}
.icon-tree:before {
	content: "\e8d5";
}
.icon-trophy2:before {
	content: "\e8d6";
}
.icon-tv3:before {
	content: "\e8d7";
}
.icon-typing:before {
	content: "\e8d8";
}
.icon-unread:before {
	content: "\e8d9";
}
.icon-untag:before {
	content: "\e8da";
}
.icon-user2:before {
	content: "\e8db";
}
.icon-users:before {
	content: "\e8dc";
}
.icon-v-card:before {
	content: "\e8dd";
}
.icon-video3:before {
	content: "\e8de";
}
.icon-vinyl:before {
	content: "\e8df";
}
.icon-voicemail:before {
	content: "\e8e0";
}
.icon-wallet4:before {
	content: "\e8e1";
}
.icon-water:before {
	content: "\e8e2";
}
.icon-500px-with-circle:before {
	content: "\e8e3";
}
.icon-500px:before {
	content: "\e8e4";
}
.icon-basecamp:before {
	content: "\e8e5";
}
.icon-behance:before {
	content: "\e8e6";
}
.icon-creative-cloud:before {
	content: "\e8e7";
}
.icon-dropbox:before {
	content: "\e8e8";
}
.icon-evernote:before {
	content: "\e8e9";
}
.icon-flattr:before {
	content: "\e8ea";
}
.icon-foursquare:before {
	content: "\e8eb";
}
.icon-google-drive:before {
	content: "\e8ec";
}
.icon-google-hangouts:before {
	content: "\e8ed";
}
.icon-grooveshark:before {
	content: "\e8ee";
}
.icon-icloud:before {
	content: "\e8ef";
}
.icon-mixi:before {
	content: "\e8f0";
}
.icon-onedrive:before {
	content: "\e8f1";
}
.icon-paypal:before {
	content: "\e8f2";
}
.icon-picasa:before {
	content: "\e8f3";
}
.icon-qq:before {
	content: "\e8f4";
}
.icon-rdio-with-circle:before {
	content: "\e8f5";
}
.icon-renren:before {
	content: "\e8f6";
}
.icon-scribd:before {
	content: "\e8f7";
}
.icon-sina-weibo:before {
	content: "\e8f8";
}
.icon-skype-with-circle:before {
	content: "\e8f9";
}
.icon-skype:before {
	content: "\e8fa";
}
.icon-slideshare:before {
	content: "\e8fb";
}
.icon-smashing:before {
	content: "\e8fc";
}
.icon-soundcloud:before {
	content: "\e8fd";
}
.icon-spotify-with-circle:before {
	content: "\e8fe";
}
.icon-spotify:before {
	content: "\e8ff";
}
.icon-swarm:before {
	content: "\eaeb";
}
.icon-vine-with-circle:before {
	content: "\eaec";
}
.icon-vine:before {
	content: "\eaed";
}
.icon-vk-alternitive:before {
	content: "\eaee";
}
.icon-vk-with-circle:before {
	content: "\eaef";
}
.icon-vk:before {
	content: "\eaf0";
}
.icon-xing-with-circle:before {
	content: "\eaf1";
}
.icon-xing:before {
	content: "\eaf2";
}
.icon-yelp:before {
	content: "\eaf3";
}
.icon-dribbble-with-circle:before {
	content: "\eaf4";
}
.icon-dribbble4:before {
	content: "\eaf5";
}
.icon-facebook-with-circle:before {
	content: "\eaf6";
}
.icon-facebook4:before {
	content: "\eaf7";
}
.icon-flickr-with-circle:before {
	content: "\eaf8";
}
.icon-flickr:before {
	content: "\eaf9";
}
.icon-github-with-circle:before {
	content: "\eafa";
}
.icon-github:before {
	content: "\eafb";
}
.icon-google-with-circle:before {
	content: "\eafc";
}
.icon-google:before {
	content: "\eafd";
}
.icon-instagram-with-circle:before {
	content: "\eafe";
}
.icon-instagram:before {
	content: "\eaff";
}
.icon-lastfm-with-circle:before {
	content: "\eb00";
}
.icon-lastfm:before {
	content: "\eb01";
}
.icon-linkedin-with-circle:before {
	content: "\eb02";
}
.icon-linkedin3:before {
	content: "\eb03";
}
.icon-pinterest-with-circle:before {
	content: "\eb04";
}
.icon-pinterest:before {
	content: "\eb05";
}
.icon-rdio:before {
	content: "\eb06";
}
.icon-stumbleupon-with-circle:before {
	content: "\eb07";
}
.icon-stumbleupon:before {
	content: "\eb08";
}
.icon-tumblr-with-circle:before {
	content: "\eb09";
}
.icon-tumblr3:before {
	content: "\eb0a";
}
.icon-twitter-with-circle:before {
	content: "\eb0b";
}
.icon-twitter4:before {
	content: "\eb0c";
}
.icon-vimeo-with-circle:before {
	content: "\eb0d";
}
.icon-vimeo:before {
	content: "\eb0e";
}
.icon-youtube-with-circle:before {
	content: "\eb0f";
}
.icon-youtube:before {
	content: "\eb10";
}
.icon-type:before {
	content: "\eb11";
}
.icon-box4:before {
	content: "\eb12";
}
.icon-archive3:before {
	content: "\eb13";
}
.icon-envelope4:before {
	content: "\eb14";
}
.icon-email2:before {
	content: "\eb15";
}
.icon-files2:before {
	content: "\eb16";
}
.icon-uniEB17:before {
	content: "\eb17";
}
.icon-file-settings:before {
	content: "\eb18";
}
.icon-file-add:before {
	content: "\eb19";
}
.icon-file4:before {
	content: "\eb1a";
}
.icon-align-left2:before {
	content: "\eb1b";
}
.icon-align-right2:before {
	content: "\eb1c";
}
.icon-align-center:before {
	content: "\eb1d";
}
.icon-align-justify:before {
	content: "\eb1e";
}
.icon-file-broken:before {
	content: "\eb1f";
}
.icon-browser3:before {
	content: "\eb20";
}
.icon-windows:before {
	content: "\eb21";
}
.icon-window2:before {
	content: "\eb22";
}
.icon-folder3:before {
	content: "\eb23";
}
.icon-folder-add:before {
	content: "\eb24";
}
.icon-folder-settings:before {
	content: "\eb25";
}
.icon-folder-check:before {
	content: "\eb26";
}
.icon-wifi-low:before {
	content: "\eb27";
}
.icon-wifi-mid:before {
	content: "\eb28";
}
.icon-wifi-full:before {
	content: "\eb29";
}
.icon-connection-empty:before {
	content: "\eb2a";
}
.icon-connection-25:before {
	content: "\eb2b";
}
.icon-connection-50:before {
	content: "\eb2c";
}
.icon-connection-75:before {
	content: "\eb2d";
}
.icon-connection-full:before {
	content: "\eb2e";
}
.icon-list5:before {
	content: "\eb2f";
}
.icon-grid5:before {
	content: "\eb30";
}
.icon-uniEB31:before {
	content: "\eb31";
}
.icon-battery-charging:before {
	content: "\eb32";
}
.icon-battery-empty:before {
	content: "\eb33";
}
.icon-battery-25:before {
	content: "\eb34";
}
.icon-battery-50:before {
	content: "\eb35";
}
.icon-battery-75:before {
	content: "\eb36";
}
.icon-battery-full:before {
	content: "\eb37";
}
.icon-settings2:before {
	content: "\eb38";
}
.icon-arrow-left3:before {
	content: "\eb39";
}
.icon-arrow-up3:before {
	content: "\eb3a";
}
.icon-arrow-down3:before {
	content: "\eb3b";
}
.icon-arrow-right3:before {
	content: "\eb3c";
}
.icon-reload:before {
	content: "\eb3d";
}
.icon-refresh2:before {
	content: "\eb3e";
}
.icon-volume4:before {
	content: "\eb3f";
}
.icon-volume-increase:before {
	content: "\eb40";
}
.icon-volume-decrease:before {
	content: "\eb41";
}
.icon-mute2:before {
	content: "\eb42";
}
.icon-microphone5:before {
	content: "\eb43";
}
.icon-microphone-off:before {
	content: "\eb44";
}
.icon-book4:before {
	content: "\eb45";
}
.icon-checkmark5:before {
	content: "\eb46";
}
.icon-checkbox-checked:before {
	content: "\eb47";
}
.icon-checkbox:before {
	content: "\eb48";
}
.icon-paperclip2:before {
	content: "\eb49";
}
.icon-download8:before {
	content: "\eb4a";
}
.icon-tag5:before {
	content: "\eb4b";
}
.icon-trashcan2:before {
	content: "\eb4c";
}
.icon-search3:before {
	content: "\eb4d";
}
.icon-zoomin2:before {
	content: "\eb4e";
}
.icon-zoomout2:before {
	content: "\eb4f";
}
.icon-chat6:before {
	content: "\eb50";
}
.icon-chat-1:before {
	content: "\eb51";
}
.icon-chat-2:before {
	content: "\eb52";
}
.icon-chat-3:before {
	content: "\eb53";
}
.icon-comment:before {
	content: "\eb54";
}
.icon-calendar7:before {
	content: "\eb55";
}
.icon-bookmark4:before {
	content: "\eb56";
}
.icon-email3:before {
	content: "\eb57";
}
.icon-heart7:before {
	content: "\eb58";
}
.icon-enter:before {
	content: "\eb59";
}
.icon-cloud5:before {
	content: "\eb5a";
}
.icon-book5:before {
	content: "\eb5b";
}
.icon-star3:before {
	content: "\eb5c";
}
.icon-clock5:before {
	content: "\eb5d";
}
.icon-printer3:before {
	content: "\eb5e";
}
.icon-home4:before {
	content: "\eb5f";
}
.icon-flag3:before {
	content: "\eb60";
}
.icon-meter:before {
	content: "\eb61";
}
.icon-switch4:before {
	content: "\eb62";
}
.icon-forbidden:before {
	content: "\eb63";
}
.icon-lock4:before {
	content: "\eb64";
}
.icon-unlocked3:before {
	content: "\eb65";
}
.icon-unlocked4:before {
	content: "\eb66";
}
.icon-users2:before {
	content: "\eb67";
}
.icon-user3:before {
	content: "\eb68";
}
.icon-users3:before {
	content: "\eb69";
}
.icon-user4:before {
	content: "\eb6a";
}
.icon-bullhorn:before {
	content: "\eb6b";
}
.icon-share3:before {
	content: "\eb6c";
}
.icon-screen:before {
	content: "\eb6d";
}
.icon-phone7:before {
	content: "\eb6e";
}
.icon-phone-portrait:before {
	content: "\eb6f";
}
.icon-phone-landscape:before {
	content: "\eb70";
}
.icon-tablet4:before {
	content: "\eb71";
}
.icon-tablet-landscape:before {
	content: "\eb72";
}
.icon-laptop3:before {
	content: "\eb73";
}
.icon-camera7:before {
	content: "\eb74";
}
.icon-microwaveoven:before {
	content: "\eb75";
}
.icon-creditcards:before {
	content: "\eb76";
}
.icon-calculator3:before {
	content: "\eb77";
}
.icon-bag2:before {
	content: "\eb78";
}
.icon-diamond3:before {
	content: "\eb79";
}
.icon-drink6:before {
	content: "\eb7a";
}
.icon-shorts:before {
	content: "\eb7b";
}
.icon-vcard:before {
	content: "\eb7c";
}
.icon-sun2:before {
	content: "\eb7d";
}
.icon-bill:before {
	content: "\eb7e";
}
.icon-coffee2:before {
	content: "\eb7f";
}
.icon-uniEB80:before {
	content: "\eb80";
}
.icon-newspaper2:before {
	content: "\eb81";
}
.icon-stack5:before {
	content: "\eb82";
}
.icon-mapmarker:before {
	content: "\eb83";
}
.icon-map6:before {
	content: "\eb84";
}
.icon-support:before {
	content: "\eb85";
}
.icon-uniEB86:before {
	content: "\eb86";
}
.icon-barbell:before {
	content: "\eb87";
}
.icon-stopwatch2:before {
	content: "\eb88";
}
.icon-atom2:before {
	content: "\eb89";
}
.icon-syringe:before {
	content: "\eb8a";
}
.icon-health2:before {
	content: "\eb8b";
}
.icon-bolt:before {
	content: "\eb8c";
}
.icon-pill:before {
	content: "\eb8d";
}
.icon-bones:before {
	content: "\eb8e";
}
.icon-lab3:before {
	content: "\eb8f";
}
.icon-clipboard6:before {
	content: "\eb90";
}
.icon-mug2:before {
	content: "\eb91";
}
.icon-bucket2:before {
	content: "\eb92";
}
.icon-select:before {
	content: "\eb93";
}
.icon-graph:before {
	content: "\eb94";
}
.icon-crop2:before {
	content: "\eb95";
}
.icon-image3:before {
	content: "\eb96";
}
.icon-cube2:before {
	content: "\eb97";
}
.icon-bars:before {
	content: "\eb98";
}
.icon-chart7:before {
	content: "\eb99";
}
.icon-pencil5:before {
	content: "\eb9a";
}
.icon-measure:before {
	content: "\eb9b";
}
.icon-eyedropper2:before {
	content: "\eb9c";
}
.icon-box5:before {
	content: "\eb9d";
}
.icon-write:before {
	content: "\eb9e";
}
.icon-clock6:before {
	content: "\eb9f";
}
.icon-reply3:before {
	content: "\eba0";
}
.icon-reply-all2:before {
	content: "\eba1";
}
.icon-forward5:before {
	content: "\eba2";
}
.icon-flag4:before {
	content: "\eba3";
}
.icon-search4:before {
	content: "\eba4";
}
.icon-trash3:before {
	content: "\eba5";
}
.icon-envelope5:before {
	content: "\eba6";
}
.icon-bubble3:before {
	content: "\eba7";
}
.icon-bubbles:before {
	content: "\eba8";
}
.icon-user5:before {
	content: "\eba9";
}
.icon-users4:before {
	content: "\ebaa";
}
.icon-cloud6:before {
	content: "\ebab";
}
.icon-download9:before {
	content: "\ebac";
}
.icon-upload7:before {
	content: "\ebad";
}
.icon-rain2:before {
	content: "\ebae";
}
.icon-sun3:before {
	content: "\ebaf";
}
.icon-moon3:before {
	content: "\ebb0";
}
.icon-bell2:before {
	content: "\ebb1";
}
.icon-folder4:before {
	content: "\ebb2";
}
.icon-pin4:before {
	content: "\ebb3";
}
.icon-sound3:before {
	content: "\ebb4";
}
.icon-microphone6:before {
	content: "\ebb5";
}
.icon-camera8:before {
	content: "\ebb6";
}
.icon-image4:before {
	content: "\ebb7";
}
.icon-cog4:before {
	content: "\ebb8";
}
.icon-calendar8:before {
	content: "\ebb9";
}
.icon-book6:before {
	content: "\ebba";
}
.icon-map-marker:before {
	content: "\ebbb";
}
.icon-store:before {
	content: "\ebbc";
}
.icon-support2:before {
	content: "\ebbd";
}
.icon-tag6:before {
	content: "\ebbe";
}
.icon-heart8:before {
	content: "\ebbf";
}
.icon-video-camera:before {
	content: "\ebc0";
}
.icon-trophy3:before {
	content: "\ebc1";
}
.icon-cart:before {
	content: "\ebc2";
}
.icon-eye4:before {
	content: "\ebc3";
}
.icon-cancel4:before {
	content: "\ebc4";
}
.icon-chart8:before {
	content: "\ebc5";
}
.icon-target3:before {
	content: "\ebc6";
}
.icon-printer4:before {
	content: "\ebc7";
}
.icon-location5:before {
	content: "\ebc8";
}
.icon-bookmark5:before {
	content: "\ebc9";
}
.icon-monitor2:before {
	content: "\ebca";
}
.icon-cross3:before {
	content: "\ebcb";
}
.icon-plus4:before {
	content: "\ebcc";
}
.icon-left:before {
	content: "\ebcd";
}
.icon-up:before {
	content: "\ebce";
}
.icon-browser4:before {
	content: "\ebcf";
}
.icon-windows2:before {
	content: "\ebd0";
}
.icon-switch5:before {
	content: "\ebd1";
}
.icon-dashboard:before {
	content: "\ebd2";
}
.icon-play:before {
	content: "\ebd3";
}
.icon-fast-forward:before {
	content: "\ebd4";
}
.icon-next:before {
	content: "\ebd5";
}
.icon-refresh3:before {
	content: "\ebd6";
}
.icon-film4:before {
	content: "\ebd7";
}
.icon-home5:before {
	content: "\ebd8";
}
.icon-home6:before {
	content: "\e900";
}
.icon-home2:before {
	content: "\e901";
}
.icon-home3:before {
	content: "\e902";
}
.icon-office:before {
	content: "\e903";
}
.icon-newspaper3:before {
	content: "\e904";
}
.icon-pencil6:before {
	content: "\e905";
}
.icon-pencil2:before {
	content: "\e906";
}
.icon-quill:before {
	content: "\e907";
}
.icon-pen4:before {
	content: "\e908";
}
.icon-blog:before {
	content: "\e909";
}
.icon-eyedropper3:before {
	content: "\e90a";
}
.icon-droplet:before {
	content: "\e90b";
}
.icon-paint-format:before {
	content: "\e90c";
}
.icon-image5:before {
	content: "\e90d";
}
.icon-images2:before {
	content: "\e90e";
}
.icon-camera9:before {
	content: "\e90f";
}
.icon-headphones2:before {
	content: "\e910";
}
.icon-music4:before {
	content: "\e911";
}
.icon-play4:before {
	content: "\e912";
}
.icon-film5:before {
	content: "\e913";
}
.icon-video-camera2:before {
	content: "\e914";
}
.icon-dice:before {
	content: "\e915";
}
.icon-pacman2:before {
	content: "\e916";
}
.icon-spades:before {
	content: "\e917";
}
.icon-clubs:before {
	content: "\e918";
}
.icon-diamonds:before {
	content: "\e919";
}
.icon-bullhorn2:before {
	content: "\e91a";
}
.icon-connection:before {
	content: "\e91b";
}
.icon-podcast:before {
	content: "\e91c";
}
.icon-feed:before {
	content: "\e91d";
}
.icon-mic3:before {
	content: "\e91e";
}
.icon-book7:before {
	content: "\e91f";
}
.icon-books:before {
	content: "\e920";
}
.icon-library2:before {
	content: "\e921";
}
.icon-file-text:before {
	content: "\e922";
}
.icon-profile4:before {
	content: "\e923";
}
.icon-file-empty:before {
	content: "\e924";
}
.icon-files-empty:before {
	content: "\e925";
}
.icon-file-text2:before {
	content: "\e926";
}
.icon-file-picture:before {
	content: "\e927";
}
.icon-file-music:before {
	content: "\e928";
}
.icon-file-play:before {
	content: "\e929";
}
.icon-file-video:before {
	content: "\e92a";
}
.icon-file-zip:before {
	content: "\e92b";
}
.icon-copy2:before {
	content: "\e92c";
}
.icon-paste:before {
	content: "\e92d";
}
.icon-stack6:before {
	content: "\e92e";
}
.icon-folder5:before {
	content: "\e92f";
}
.icon-folder-open:before {
	content: "\e930";
}
.icon-folder-plus:before {
	content: "\e931";
}
.icon-folder-minus:before {
	content: "\e932";
}
.icon-folder-download:before {
	content: "\e933";
}
.icon-folder-upload:before {
	content: "\e934";
}
.icon-price-tag2:before {
	content: "\e935";
}
.icon-price-tags:before {
	content: "\e936";
}
.icon-barcode3:before {
	content: "\e937";
}
.icon-qrcode:before {
	content: "\e938";
}
.icon-ticket5:before {
	content: "\e939";
}
.icon-cart2:before {
	content: "\e93a";
}
.icon-coin-dollar:before {
	content: "\e93b";
}
.icon-coin-euro:before {
	content: "\e93c";
}
.icon-coin-pound:before {
	content: "\e93d";
}
.icon-coin-yen:before {
	content: "\e93e";
}
.icon-credit-card2:before {
	content: "\e93f";
}
.icon-calculator4:before {
	content: "\e940";
}
.icon-lifebuoy2:before {
	content: "\e941";
}
.icon-phone8:before {
	content: "\e942";
}
.icon-phone-hang-up:before {
	content: "\e943";
}
.icon-address-book:before {
	content: "\e944";
}
.icon-envelop:before {
	content: "\e945";
}
.icon-pushpin:before {
	content: "\e946";
}
.icon-location6:before {
	content: "\e947";
}
.icon-location2:before {
	content: "\e948";
}
.icon-compass5:before {
	content: "\e949";
}
.icon-compass2:before {
	content: "\e94a";
}
.icon-map7:before {
	content: "\e94b";
}
.icon-map2:before {
	content: "\e94c";
}
.icon-history:before {
	content: "\e94d";
}
.icon-clock7:before {
	content: "\e94e";
}
.icon-clock2:before {
	content: "\e94f";
}
.icon-alarm2:before {
	content: "\e950";
}
.icon-bell3:before {
	content: "\e951";
}
.icon-stopwatch3:before {
	content: "\e952";
}
.icon-calendar9:before {
	content: "\e953";
}
.icon-printer5:before {
	content: "\e954";
}
.icon-keyboard4:before {
	content: "\e955";
}
.icon-display2:before {
	content: "\e956";
}
.icon-laptop4:before {
	content: "\e957";
}
.icon-mobile4:before {
	content: "\e958";
}
.icon-mobile2:before {
	content: "\e959";
}
.icon-tablet5:before {
	content: "\e95a";
}
.icon-tv4:before {
	content: "\e95b";
}
.icon-drawer:before {
	content: "\e95c";
}
.icon-drawer2:before {
	content: "\e95d";
}
.icon-box-add:before {
	content: "\e95e";
}
.icon-box-remove:before {
	content: "\e95f";
}
.icon-download10:before {
	content: "\e960";
}
.icon-upload8:before {
	content: "\e961";
}
.icon-floppy-disk:before {
	content: "\e962";
}
.icon-drive2:before {
	content: "\e963";
}
.icon-database2:before {
	content: "\e964";
}
.icon-undo:before {
	content: "\e965";
}
.icon-redo:before {
	content: "\e966";
}
.icon-undo2:before {
	content: "\e967";
}
.icon-redo2:before {
	content: "\e968";
}
.icon-forward6:before {
	content: "\e969";
}
.icon-reply4:before {
	content: "\e96a";
}
.icon-bubble4:before {
	content: "\e96b";
}
.icon-bubbles5:before {
	content: "\e96c";
}
.icon-bubbles2:before {
	content: "\e96d";
}
.icon-bubble2:before {
	content: "\e96e";
}
.icon-bubbles3:before {
	content: "\e96f";
}
.icon-bubbles4:before {
	content: "\e970";
}
.icon-user6:before {
	content: "\e971";
}
.icon-users5:before {
	content: "\e972";
}
.icon-user-plus:before {
	content: "\e973";
}
.icon-user-minus:before {
	content: "\e974";
}
.icon-user-check:before {
	content: "\e975";
}
.icon-user-tie:before {
	content: "\e976";
}
.icon-quotes-left:before {
	content: "\e977";
}
.icon-quotes-right:before {
	content: "\e978";
}
.icon-hour-glass2:before {
	content: "\e979";
}
.icon-spinner:before {
	content: "\e97a";
}
.icon-spinner2:before {
	content: "\e97b";
}
.icon-spinner3:before {
	content: "\e97c";
}
.icon-spinner4:before {
	content: "\e97d";
}
.icon-spinner5:before {
	content: "\e97e";
}
.icon-spinner6:before {
	content: "\e97f";
}
.icon-spinner7:before {
	content: "\e980";
}
.icon-spinner8:before {
	content: "\e981";
}
.icon-spinner9:before {
	content: "\e982";
}
.icon-spinner10:before {
	content: "\e983";
}
.icon-spinner11:before {
	content: "\e984";
}
.icon-binoculars:before {
	content: "\e985";
}
.icon-search5:before {
	content: "\e986";
}
.icon-zoom-in:before {
	content: "\e987";
}
.icon-zoom-out:before {
	content: "\e988";
}
.icon-enlarge:before {
	content: "\e989";
}
.icon-shrink:before {
	content: "\e98a";
}
.icon-enlarge2:before {
	content: "\e98b";
}
.icon-shrink2:before {
	content: "\e98c";
}
.icon-key6:before {
	content: "\e98d";
}
.icon-key2:before {
	content: "\e98e";
}
.icon-lock5:before {
	content: "\e98f";
}
.icon-unlocked5:before {
	content: "\e990";
}
.icon-wrench:before {
	content: "\e991";
}
.icon-equalizer3:before {
	content: "\e992";
}
.icon-equalizer2:before {
	content: "\e993";
}
.icon-cog5:before {
	content: "\e994";
}
.icon-cogs2:before {
	content: "\e995";
}
.icon-hammer3:before {
	content: "\e996";
}
.icon-magic-wand:before {
	content: "\e997";
}
.icon-aid-kit:before {
	content: "\e998";
}
.icon-bug:before {
	content: "\e999";
}
.icon-pie-chart2:before {
	content: "\e99a";
}
.icon-stats-dots:before {
	content: "\e99b";
}
.icon-stats-bars:before {
	content: "\e99c";
}
.icon-stats-bars2:before {
	content: "\e99d";
}
.icon-trophy4:before {
	content: "\e99e";
}
.icon-gift2:before {
	content: "\e99f";
}
.icon-glass:before {
	content: "\e9a0";
}
.icon-glass2:before {
	content: "\e9a1";
}
.icon-mug3:before {
	content: "\e9a2";
}
.icon-spoon-knife:before {
	content: "\e9a3";
}
.icon-leaf2:before {
	content: "\e9a4";
}
.icon-rocket3:before {
	content: "\e9a5";
}
.icon-meter3:before {
	content: "\e9a6";
}
.icon-meter2:before {
	content: "\e9a7";
}
.icon-hammer2:before {
	content: "\e9a8";
}
.icon-fire2:before {
	content: "\e9a9";
}
.icon-lab4:before {
	content: "\e9aa";
}
.icon-magnet3:before {
	content: "\e9ab";
}
.icon-bin:before {
	content: "\e9ac";
}
.icon-bin2:before {
	content: "\e9ad";
}
.icon-briefcase3:before {
	content: "\e9ae";
}
.icon-airplane:before {
	content: "\e9af";
}
.icon-truck4:before {
	content: "\e9b0";
}
.icon-road:before {
	content: "\e9b1";
}
.icon-accessibility:before {
	content: "\e9b2";
}
.icon-target4:before {
	content: "\e9b3";
}
.icon-shield3:before {
	content: "\e9b4";
}
.icon-power:before {
	content: "\e9b5";
}
.icon-switch6:before {
	content: "\e9b6";
}
.icon-power-cord:before {
	content: "\e9b7";
}
.icon-clipboard7:before {
	content: "\e9b8";
}
.icon-list-numbered:before {
	content: "\e9b9";
}
.icon-list6:before {
	content: "\e9ba";
}
.icon-list2:before {
	content: "\e9bb";
}
.icon-tree2:before {
	content: "\e9bc";
}
.icon-menu5:before {
	content: "\e9bd";
}
.icon-menu2:before {
	content: "\e9be";
}
.icon-menu3:before {
	content: "\e9bf";
}
.icon-menu4:before {
	content: "\e9c0";
}
.icon-cloud7:before {
	content: "\e9c1";
}
.icon-cloud-download:before {
	content: "\e9c2";
}
.icon-cloud-upload:before {
	content: "\e9c3";
}
.icon-cloud-check:before {
	content: "\e9c4";
}
.icon-download2:before {
	content: "\e9c5";
}
.icon-upload2:before {
	content: "\e9c6";
}
.icon-download3:before {
	content: "\e9c7";
}
.icon-upload3:before {
	content: "\e9c8";
}
.icon-sphere:before {
	content: "\e9c9";
}
.icon-earth:before {
	content: "\e9ca";
}
.icon-link4:before {
	content: "\e9cb";
}
.icon-flag5:before {
	content: "\e9cc";
}
.icon-attachment4:before {
	content: "\e9cd";
}
.icon-eye5:before {
	content: "\e9ce";
}
.icon-eye-plus:before {
	content: "\e9cf";
}
.icon-eye-minus:before {
	content: "\e9d0";
}
.icon-eye-blocked:before {
	content: "\e9d1";
}
.icon-bookmark6:before {
	content: "\e9d2";
}
.icon-bookmarks2:before {
	content: "\e9d3";
}
.icon-sun4:before {
	content: "\e9d4";
}
.icon-contrast:before {
	content: "\e9d5";
}
.icon-brightness-contrast:before {
	content: "\e9d6";
}
.icon-star-empty:before {
	content: "\e9d7";
}
.icon-star-half:before {
	content: "\e9d8";
}
.icon-star-full:before {
	content: "\e9d9";
}
.icon-heart9:before {
	content: "\e9da";
}
.icon-heart-broken:before {
	content: "\e9db";
}
.icon-man2:before {
	content: "\e9dc";
}
.icon-woman:before {
	content: "\e9dd";
}
.icon-man-woman:before {
	content: "\e9de";
}
.icon-happy3:before {
	content: "\e9df";
}
.icon-happy2:before {
	content: "\e9e0";
}
.icon-smile:before {
	content: "\e9e1";
}
.icon-smile2:before {
	content: "\e9e2";
}
.icon-tongue:before {
	content: "\e9e3";
}
.icon-tongue2:before {
	content: "\e9e4";
}
.icon-sad4:before {
	content: "\e9e5";
}
.icon-sad2:before {
	content: "\e9e6";
}
.icon-wink:before {
	content: "\e9e7";
}
.icon-wink2:before {
	content: "\e9e8";
}
.icon-grin:before {
	content: "\e9e9";
}
.icon-grin2:before {
	content: "\e9ea";
}
.icon-cool:before {
	content: "\e9eb";
}
.icon-cool2:before {
	content: "\e9ec";
}
.icon-angry:before {
	content: "\e9ed";
}
.icon-angry2:before {
	content: "\e9ee";
}
.icon-evil:before {
	content: "\e9ef";
}
.icon-evil2:before {
	content: "\e9f0";
}
.icon-shocked:before {
	content: "\e9f1";
}
.icon-shocked2:before {
	content: "\e9f2";
}
.icon-baffled:before {
	content: "\e9f3";
}
.icon-baffled2:before {
	content: "\e9f4";
}
.icon-confused:before {
	content: "\e9f5";
}
.icon-confused2:before {
	content: "\e9f6";
}
.icon-neutral:before {
	content: "\e9f7";
}
.icon-neutral2:before {
	content: "\e9f8";
}
.icon-hipster:before {
	content: "\e9f9";
}
.icon-hipster2:before {
	content: "\e9fa";
}
.icon-wondering:before {
	content: "\e9fb";
}
.icon-wondering2:before {
	content: "\e9fc";
}
.icon-sleepy:before {
	content: "\e9fd";
}
.icon-sleepy2:before {
	content: "\e9fe";
}
.icon-frustrated:before {
	content: "\e9ff";
}
.icon-frustrated2:before {
	content: "\ea00";
}
.icon-crying:before {
	content: "\ea01";
}
.icon-crying2:before {
	content: "\ea02";
}
.icon-point-up:before {
	content: "\ea03";
}
.icon-point-right:before {
	content: "\ea04";
}
.icon-point-down:before {
	content: "\ea05";
}
.icon-point-left:before {
	content: "\ea06";
}
.icon-warning3:before {
	content: "\ea07";
}
.icon-notification2:before {
	content: "\ea08";
}
.icon-question:before {
	content: "\ea09";
}
.icon-plus5:before {
	content: "\ea0a";
}
.icon-minus5:before {
	content: "\ea0b";
}
.icon-info4:before {
	content: "\ea0c";
}
.icon-cancel-circle:before {
	content: "\ea0d";
}
.icon-blocked2:before {
	content: "\ea0e";
}
.icon-cross4:before {
	content: "\ea0f";
}
.icon-checkmark6:before {
	content: "\ea10";
}
.icon-checkmark2:before {
	content: "\ea11";
}
.icon-spell-check:before {
	content: "\ea12";
}
.icon-enter2:before {
	content: "\ea13";
}
.icon-exit:before {
	content: "\ea14";
}
.icon-play2:before {
	content: "\ea15";
}
.icon-pause:before {
	content: "\ea16";
}
.icon-stop3:before {
	content: "\ea17";
}
.icon-previous:before {
	content: "\ea18";
}
.icon-next3:before {
	content: "\ea19";
}
.icon-backward:before {
	content: "\ea1a";
}
.icon-forward2:before {
	content: "\ea1b";
}
.icon-play3:before {
	content: "\ea1c";
}
.icon-pause2:before {
	content: "\ea1d";
}
.icon-stop2:before {
	content: "\ea1e";
}
.icon-backward2:before {
	content: "\ea1f";
}
.icon-forward3:before {
	content: "\ea20";
}
.icon-first:before {
	content: "\ea21";
}
.icon-last:before {
	content: "\ea22";
}
.icon-previous2:before {
	content: "\ea23";
}
.icon-next2:before {
	content: "\ea24";
}
.icon-eject:before {
	content: "\ea25";
}
.icon-volume-high:before {
	content: "\ea26";
}
.icon-volume-medium:before {
	content: "\ea27";
}
.icon-volume-low:before {
	content: "\ea28";
}
.icon-volume-mute:before {
	content: "\ea29";
}
.icon-volume-mute2:before {
	content: "\ea2a";
}
.icon-volume-increase2:before {
	content: "\ea2b";
}
.icon-volume-decrease2:before {
	content: "\ea2c";
}
.icon-loop4:before {
	content: "\ea2d";
}
.icon-loop2:before {
	content: "\ea2e";
}
.icon-infinite:before {
	content: "\ea2f";
}
.icon-shuffle3:before {
	content: "\ea30";
}
.icon-arrow-up-left:before {
	content: "\ea31";
}
.icon-arrow-up4:before {
	content: "\ea32";
}
.icon-arrow-up-right:before {
	content: "\ea33";
}
.icon-arrow-right4:before {
	content: "\ea34";
}
.icon-arrow-down-right:before {
	content: "\ea35";
}
.icon-arrow-down4:before {
	content: "\ea36";
}
.icon-arrow-down-left:before {
	content: "\ea37";
}
.icon-arrow-left4:before {
	content: "\ea38";
}
.icon-arrow-up-left2:before {
	content: "\ea39";
}
.icon-arrow-up2:before {
	content: "\ea3a";
}
.icon-arrow-up-right2:before {
	content: "\ea3b";
}
.icon-arrow-right2:before {
	content: "\ea3c";
}
.icon-arrow-down-right2:before {
	content: "\ea3d";
}
.icon-arrow-down2:before {
	content: "\ea3e";
}
.icon-arrow-down-left2:before {
	content: "\ea3f";
}
.icon-arrow-left2:before {
	content: "\ea40";
}
.icon-circle-up:before {
	content: "\ea41";
}
.icon-circle-right:before {
	content: "\ea42";
}
.icon-circle-down:before {
	content: "\ea43";
}
.icon-circle-left:before {
	content: "\ea44";
}
.icon-tab:before {
	content: "\ea45";
}
.icon-move-up:before {
	content: "\ea46";
}
.icon-move-down:before {
	content: "\ea47";
}
.icon-sort-alpha-asc:before {
	content: "\ea48";
}
.icon-sort-alpha-desc:before {
	content: "\ea49";
}
.icon-sort-numeric-asc:before {
	content: "\ea4a";
}
.icon-sort-numberic-desc:before {
	content: "\ea4b";
}
.icon-sort-amount-asc:before {
	content: "\ea4c";
}
.icon-sort-amount-desc:before {
	content: "\ea4d";
}
.icon-command:before {
	content: "\ea4e";
}
.icon-shift:before {
	content: "\ea4f";
}
.icon-ctrl:before {
	content: "\ea50";
}
.icon-opt:before {
	content: "\ea51";
}
.icon-checkbox-checked2:before {
	content: "\ea52";
}
.icon-checkbox-unchecked:before {
	content: "\ea53";
}
.icon-radio-checked:before {
	content: "\ea54";
}
.icon-radio-checked2:before {
	content: "\ea55";
}
.icon-radio-unchecked:before {
	content: "\ea56";
}
.icon-crop3:before {
	content: "\ea57";
}
.icon-make-group:before {
	content: "\ea58";
}
.icon-ungroup:before {
	content: "\ea59";
}
.icon-scissors4:before {
	content: "\ea5a";
}
.icon-filter:before {
	content: "\ea5b";
}
.icon-font:before {
	content: "\ea5c";
}
.icon-ligature:before {
	content: "\ea5d";
}
.icon-ligature2:before {
	content: "\ea5e";
}
.icon-text-height:before {
	content: "\ea5f";
}
.icon-text-width:before {
	content: "\ea60";
}
.icon-font-size:before {
	content: "\ea61";
}
.icon-bold:before {
	content: "\ea62";
}
.icon-underline:before {
	content: "\ea63";
}
.icon-italic:before {
	content: "\ea64";
}
.icon-strikethrough:before {
	content: "\ea65";
}
.icon-omega:before {
	content: "\ea66";
}
.icon-sigma:before {
	content: "\ea67";
}
.icon-page-break:before {
	content: "\ea68";
}
.icon-superscript:before {
	content: "\ea69";
}
.icon-subscript:before {
	content: "\ea6a";
}
.icon-superscript2:before {
	content: "\ea6b";
}
.icon-subscript2:before {
	content: "\ea6c";
}
.icon-text-color:before {
	content: "\ea6d";
}
.icon-pagebreak:before {
	content: "\ea6e";
}
.icon-clear-formatting:before {
	content: "\ea6f";
}
.icon-table:before {
	content: "\ea70";
}
.icon-table2:before {
	content: "\ea71";
}
.icon-insert-template:before {
	content: "\ea72";
}
.icon-pilcrow:before {
	content: "\ea73";
}
.icon-ltr:before {
	content: "\ea74";
}
.icon-rtl:before {
	content: "\ea75";
}
.icon-section:before {
	content: "\ea76";
}
.icon-paragraph-left:before {
	content: "\ea77";
}
.icon-paragraph-center:before {
	content: "\ea78";
}
.icon-paragraph-right:before {
	content: "\ea79";
}
.icon-paragraph-justify:before {
	content: "\ea7a";
}
.icon-indent-increase:before {
	content: "\ea7b";
}
.icon-indent-decrease:before {
	content: "\ea7c";
}
.icon-share4:before {
	content: "\ea7d";
}
.icon-new-tab:before {
	content: "\ea7e";
}
.icon-embed:before {
	content: "\ea7f";
}
.icon-embed2:before {
	content: "\ea80";
}
.icon-terminal:before {
	content: "\ea81";
}
.icon-share2:before {
	content: "\ea82";
}
.icon-mail6:before {
	content: "\ea83";
}
.icon-mail2:before {
	content: "\ea84";
}
.icon-mail3:before {
	content: "\ea85";
}
.icon-mail4:before {
	content: "\ea86";
}
.icon-google2:before {
	content: "\ea87";
}
.icon-google-plus:before {
	content: "\ea88";
}
.icon-google-plus2:before {
	content: "\ea89";
}
.icon-google-plus3:before {
	content: "\ea8a";
}
.icon-google-drive2:before {
	content: "\ea8b";
}
.icon-facebook5:before {
	content: "\ea8c";
}
.icon-facebook2:before {
	content: "\ea8d";
}
.icon-facebook3:before {
	content: "\ea8e";
}
.icon-ello:before {
	content: "\ea8f";
}
.icon-instagram2:before {
	content: "\ea90";
}
.icon-twitter5:before {
	content: "\ea91";
}
.icon-twitter2:before {
	content: "\ea92";
}
.icon-twitter3:before {
	content: "\ea93";
}
.icon-feed2:before {
	content: "\ea94";
}
.icon-feed3:before {
	content: "\ea95";
}
.icon-feed4:before {
	content: "\ea96";
}
.icon-youtube5:before {
	content: "\ea97";
}
.icon-youtube2:before {
	content: "\ea98";
}
.icon-youtube3:before {
	content: "\ea99";
}
.icon-youtube4:before {
	content: "\ea9a";
}
.icon-twitch:before {
	content: "\ea9b";
}
.icon-vimeo4:before {
	content: "\ea9c";
}
.icon-vimeo2:before {
	content: "\ea9d";
}
.icon-vimeo3:before {
	content: "\ea9e";
}
.icon-lanyrd:before {
	content: "\ea9f";
}
.icon-flickr5:before {
	content: "\eaa0";
}
.icon-flickr2:before {
	content: "\eaa1";
}
.icon-flickr3:before {
	content: "\eaa2";
}
.icon-flickr4:before {
	content: "\eaa3";
}
.icon-picassa:before {
	content: "\eaa4";
}
.icon-picassa2:before {
	content: "\eaa5";
}
.icon-dribbble5:before {
	content: "\eaa6";
}
.icon-dribbble2:before {
	content: "\eaa7";
}
.icon-dribbble3:before {
	content: "\eaa8";
}
.icon-forrst:before {
	content: "\eaa9";
}
.icon-forrst2:before {
	content: "\eaaa";
}
.icon-deviantart:before {
	content: "\eaab";
}
.icon-deviantart2:before {
	content: "\eaac";
}
.icon-steam:before {
	content: "\eaad";
}
.icon-steam2:before {
	content: "\eaae";
}
.icon-dropbox2:before {
	content: "\eaaf";
}
.icon-onedrive2:before {
	content: "\eab0";
}
.icon-github6:before {
	content: "\eab1";
}
.icon-github2:before {
	content: "\eab2";
}
.icon-github3:before {
	content: "\eab3";
}
.icon-github4:before {
	content: "\eab4";
}
.icon-github5:before {
	content: "\eab5";
}
.icon-wordpress:before {
	content: "\eab6";
}
.icon-wordpress2:before {
	content: "\eab7";
}
.icon-joomla:before {
	content: "\eab8";
}
.icon-blogger:before {
	content: "\eab9";
}
.icon-blogger2:before {
	content: "\eaba";
}
.icon-tumblr4:before {
	content: "\eabb";
}
.icon-tumblr2:before {
	content: "\eabc";
}
.icon-yahoo:before {
	content: "\eabd";
}
.icon-tux:before {
	content: "\eabe";
}
.icon-apple:before {
	content: "\eabf";
}
.icon-finder:before {
	content: "\eac0";
}
.icon-android2:before {
	content: "\eac1";
}
.icon-windows3:before {
	content: "\eac2";
}
.icon-windows8:before {
	content: "\eac3";
}
.icon-soundcloud3:before {
	content: "\eac4";
}
.icon-soundcloud2:before {
	content: "\eac5";
}
.icon-skype2:before {
	content: "\eac6";
}
.icon-reddit:before {
	content: "\eac7";
}
.icon-linkedin4:before {
	content: "\eac8";
}
.icon-linkedin2:before {
	content: "\eac9";
}
.icon-lastfm3:before {
	content: "\eaca";
}
.icon-lastfm2:before {
	content: "\eacb";
}
.icon-delicious:before {
	content: "\eacc";
}
.icon-stumbleupon3:before {
	content: "\eacd";
}
.icon-stumbleupon2:before {
	content: "\eace";
}
.icon-stackoverflow:before {
	content: "\eacf";
}
.icon-pinterest3:before {
	content: "\ead0";
}
.icon-pinterest2:before {
	content: "\ead1";
}
.icon-xing3:before {
	content: "\ead2";
}
.icon-xing2:before {
	content: "\ead3";
}
.icon-flattr2:before {
	content: "\ead4";
}
.icon-foursquare2:before {
	content: "\ead5";
}
.icon-paypal4:before {
	content: "\ead6";
}
.icon-paypal2:before {
	content: "\ead7";
}
.icon-paypal3:before {
	content: "\ead8";
}
.icon-yelp2:before {
	content: "\ead9";
}
.icon-file-pdf:before {
	content: "\eada";
}
.icon-file-openoffice:before {
	content: "\eadb";
}
.icon-file-word:before {
	content: "\eadc";
}
.icon-file-excel:before {
	content: "\eadd";
}
.icon-libreoffice:before {
	content: "\eade";
}
.icon-html5:before {
	content: "\eadf";
}
.icon-html52:before {
	content: "\eae0";
}
.icon-css3:before {
	content: "\eae1";
}
.icon-git:before {
	content: "\eae2";
}
.icon-svg:before {
	content: "\eae3";
}
.icon-codepen:before {
	content: "\eae4";
}
.icon-chrome:before {
	content: "\eae5";
}
.icon-firefox:before {
	content: "\eae6";
}
.icon-IE:before {
	content: "\eae7";
}
.icon-opera:before {
	content: "\eae8";
}
.icon-safari:before {
	content: "\eae9";
}
.icon-IcoMoon:before {
	content: "\eaea";
}
