.paging h2 {
  font-weight: normal;
  font-size: 42px;
  font-family: 'Raleway', sans-serif;
  color: #ed4842;
}

.intro {

  &-standard {
    background-color: #0f72c3;
    display: block;
    text-decoration: none;
    color: #525252;
    padding: 16px 0px 16px 42px;
    font-size: 16px;
    transition: all 0.25s ease;
    color: white;
  }

  &-link {
    cursor: pointer;
    display: block;
    padding: 18px 15px 18px 22px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid #CCC;
    position: relative;
    transition: all 0.4s ease;
    background-color: #ed4842;
    width: 100%;
    border-radius: 0px;
  }

  &-cluster {
    background-color: #e4e4e4 !important;
    padding: 5px 0px 5px 20px;
    background: none;
  }
}

.highlighted {
  background-color: #0f72c3;
}

#procedures-links .submenu .highlighted a {
  color: white;
}

#procedures-links.accordion li.open .link {
  color: #525252;
  background-color: #e4e4e4;
}

#procedures-links .submenu a.standard-list {
  padding-left: 15px;
  background: none;
  cursor: pointer;
}

#procedures-links .submenu a.standard-list:hover {

  background: none !important;
}

#procedures-links.accordion li.open i.review-check {
  position: relative;
  float: right;
  padding-right: 0px;
  top: auto;
  right: auto;
  font-size: 15px;
  color: #e4e4e4;
  transition: all 0.4s ease;
}

#procedures-links.accordion li.open i.my-green {
  color: green;
  margin-left: 0px;
}

.my-red {
  color: #ec5349 !important;
  margin-left: 0px;
}

.my-white {
  color: #fff;
  margin-left: 0px;
}

#procedures-links .submenu li.cluster-list a {
  background-color: #e4e4e4 !important;
  padding: 5px 0px 5px 20px;
  background: none;
}

#procedures-links .submenu li.cluster-list a:hover {
  background-color: #e4e4e4 !important;
  color: #525252 !important;
  padding: 5px 0px 5px 20px !important;
  background: none;
}

.progress-panel {

  color: white;
  min-height: 80px;

}

.progress-panel::after {
  content: '';
  position: absolute;
  right: -5%;
  top: 0px;
  width: 0;
  height: 0;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  clear: both;
}

.panel-1::after, .panel-2::after, .panel-3::after {
  border-left: 25px solid #f0f0f0;
}

.panel-1, .panel-2, .panel-3, .panel-4 {
  background-color: #f0f0f0;
  color: #525252;
}

.panel-1.active::after, .panel-1:hover:after {
  border-left: 25px solid #ec5349;

}

.panel-2.active::after, .panel-2:hover:after {
  border-left: 25px solid orange;

}

.panel-3.active::after, .panel-3:hover:after {
  border-left: 25px solid rgb(2, 173, 198);

}

.panel-1.active, .panel-1:hover, .question-1.active, .question-1:hover {
  background-color: #ec5349;
  color: white;
}

.panel-2.active, .panel-2:hover, .question-2.active, .question-2:hover {
  background-color: orange;
  color: white;
}

.panel-3.active, .panel-3:hover, .question-3.active, .question-3:hover {
  background-color: rgb(2, 173, 198);
  color: white;
}

.panel-4.active, .panel-4:hover, .question-4.active, .question-4:hover {
  background-color: green;
  color: white;
}

textarea.form-control {
  height: 100px;
}

.aya-table-headers {
  background-color: orange !important;
  color: white;
}

#contributors .p {
  font-size: 17px
}

.user-avatar {
  min-width: 150px;
}

.commentWidth {
  width: 50%;
}

.dateWidth {
  min-width: 100px;
}

.cell-detail-description {
  display: block;
  font-size: .8em;
}

.rob-footer {
  color: #FFFFFF;
  background: #0f72c3;
}


.rob-footer__middle-row {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  background: #0d64ab;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.rob-footer__middle-row .container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media all and (min-width: 768px) {
  .rob-footer__middle-row .container {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.rob-footer__middle-left {
  -ms-flex: 1 1;
  flex: 1 1;
}

@media all and (min-width: 768px) {
  .rob-footer__middle-left {
    max-width: 50%;
  }
}

.rob-footer__middle-right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-align: start;
  align-items: flex-start;
  font-weight: 600;
  font-size: 16px;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media all and (min-width: 768px) {
  .rob-footer__middle-right {
    font-size: 20px;
  }
}

.rob-footer__bottom-row {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  padding: 25px 25px;
  border-top: #0d64ab solid 2px;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

@media all and (min-width: 768px) {
  .rob-footer__bottom-row {
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 25px 50px;
  }
}

.rob-footer__bottom-left {
  -ms-flex: 1 1;
  flex: 1 1;
  font-size: 12px;
}

@media all and (min-width: 768px) {
  .rob-footer__bottom-left {
    font-size: 16px;
  }
}

.rob-footer__bottom-right {
  -ms-flex: 1 1;
  flex: 1 1;
  font-size: 16px;
  text-align: right;
  margin-bottom: 20px;
}

@media all and (min-width: 768px) {
  .rob-footer__bottom-right {
    font-size: 16px;
    margin-bottom: 0px;
  }
}

.rob-footer__bottom-right img {
  width: 30px;
  max-height: 30px;
}

.rob-footer__bottom-right img:first-child {
  margin-left: 20px;
}

.rob-footer .icon__container {
  width: 25px;
  max-height: 50px;
  display: inline-block;
  margin-right: 20px;
}

.rob-footer .icon__container i {
  width: 100%;
}
