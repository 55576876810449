
.header .nav li.item-select{ background: #ff9c00;}
.header .nav li:hover{   background: #ff9c00;}
.wtf-menu ul.submenu li:hover {  background: #0f72c3;}
.tp-caption.grey_heavy_72-light {color:#ff9c00;}
.tp-caption.icon i { background: #ff9c00;}
.services-one .service-sec .icon{ background:#ff9c00;}
.z-tabs.silver > ul > li.z-active > a {	  border-bottom: solid 5px #ff9c00;	  color: #ff9c00;}
.z-tabs.horizontal .detail a:hover {    background-color: #ff9c00;}
.meet-specialists .detail h6 {    color: #ff9c00;}
.meet-specialists .detail a:hover {    color: #ff9c00;}
.latest-news .post .detail a:hover {    color: #ff9c00;}
.patients-testi .testi-sec span.name{ color:#ff9c00;}
.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{	background:#ff9c00;}
.main-footer .tweets .text a:hover {    color: #ff9c00;}
.main-footer .newsletter .form input[type=submit]:hover{ background: #ff9c00;}
.cd-top{ background-color: #ff9c00; }
.header2 .nav li.item-select { background: #ff9c00 }
.header2 .nav li.item-select:hover { background: #ff9c00 }
.header2 .nav li:hover{  background: #0f72c3; border-radius: 0; padding:36px 0 32px 0;}
.tp-bullets.preview4 .bullet.selected { background: #ff9c00 !important;}
.services-two .service-sec .icon{ background:#ff9c00;}
.services-three .serv-sec{background: #ff9c00;}
.services-three .serv-sec.serv-sec2{ background: #ff9c00;}
.services-three .serv-sec.serv-sec3{background: #ff9c00;}
.welcome-two .welcome-detail ul li{ color: #ff9c00;}
.footer-light .emergency i{ color:#ff9c00; }
.footer-light .main-footer .title h5{   color: #ff9c00; border-bottom: solid 1px #ff9c00; }
.footer-light .main-footer .tweets .text a{ color:#ff9c00;}
.footer-light .main-footer .tweets .icon{ color:#ff9c00;}
.footer-light .main-footer .get-touch ul li a:hover span{color:#ff9c00; }


/** banner three **/
.main-banner-three{ background: rgba(2, 173, 198, 0.69);  }
/** Fancybox overlay **/
.fancybox-overlay {	background: rgba(2, 173, 198, 0.84);}
/** Team Social icons **/
.cbp-caption-activeWrap {	background-color: rgba(2, 173, 198, 0.81);}

.appointment-form .form input[type=submit]:hover{ background: #ff9c00;}
.services-five .service-sec .icon{ background:#ff9c00;box-shadow: 0 0 0 3px #ff9c00;}
.sub-banner .detail ul li:last-child a{ color:#ff9c00;}
.sub-banner .detail ul li a:hover{ color:#ff9c00;}
.why-choose li.open .link { color: #ff9c00;}
.why-choose li.open i { color: #ff9c00 !important; }
#services-slide .owl-controls .owl-page.active span{ background:#ff9c00 ;}
.welcome-three .welcome-detail ul li i{  color: #ff9c00; }
.member-detail .team-detail .name h6{ color:#ff9c00;}
.year { color:#ff9c00;}
.main-appointment-form .appointment-form{   background: #ff9c00;}
.main-appointment-form .appoint-icon {  background-color: #ff9c00; }
.contact-us .leave-msg .form input[type=submit]:hover { background: #ff9c00;}
.contact-us .give-msg input[type=submit]:hover{  background: #ff9c00;}
.departments .depart-sec a:hover{ background: #ff9c00;}
.investigation .investigation-sec .title{ background:#ff9c00;}
.cbp-l-filters-list .cbp-filter-item.cbp-filter-item-active {	color: #ff9c00;	border-bottom: solid 5px #ff9c00;}
.cbp-l-filters-list .cbp-filter-item:hover {  border-bottom: solid 5px #ff9c00;}
.main-gallery .layer i{ background:#ff9c00;}
.main-gallery .gallery-sec .detail h6{ color:#ff9c00;}
.main-gallery .gallery-sec .detail{ border-bottom: solid 4px #ff9c00; }
.main-gallery a.gallery-sec:hover .detail{ background:#ff9c00;}
.main-gallery .paging a:hover{   background: #ff9c00;  border: solid 1px #ff9c00;}
.main-gallery .paging a.selected{   background: #ff9c00;  border: solid 1px #ff9c00;}
.news-posts .comment-box .detail a:hover {    background: #ff9c00;}
.news-posts .leave-reply .form input[type=submit]:hover{  background: #ff9c00;}
.news-posts .categories ul li a:hover{  color: #ff9c00;}
.news-posts .recent-posts .post-sec a.title:hover{color:#ff9c00;}
.news-posts .news-sec .detail a.read-more:hover{background-color: #ff9c00;}
.news-masonary .news-sec a:hover{color: #ff9c00; }
.text-posts .post-sec a.title:hover{ color:#ff9c00;}
.text-posts .post-sec p a{ color:#ff9c00;}
.text-posts .post-sec ul li a:hover i{ color:#ff9c00;}
.text-posts .post-sec ul li a:hover span{ color:#ff9c00;}
.text-posts .next-pre a:hover{ color:#ff9c00;}
.patient-family .how-treat a:hover{ background: #ff9c00;}
.procedures-links span.title {  background: #ff9c00; }
.procedures .procedure-text ul li{color: #ff9c00;}
.procedures .about-cancer-center span.name{  color: #ff9c00;}
#procedures-faq.accordion li.open .link {  background-color: #ff9c00; }
.research .research-detail li i{  color:#ff9c00; }
.services-content .serv-sec .detail h5{ color:#ff9c00; }
.product-thumb label{ background:#ff9c00;}
.product-description h3 a{color:#ff9c00;}
.product-description .product-cart-btn:hover, .product-description .product-detail-btn:hover{color:#ff9c00;}
.ui-widget-header {  background: #ff9c00;}
.top-products .top-products-detail a{color: #ff9c00;}
.ratings.big i{color: #ff9c00;}
.product-detail-slider .rslides_tabs li.rslides_here a{background:#ff9c00}
a#add-review-btn:hover{background-color: #ff9c00;}
.resp-vtabs li.resp-tab-active {color:#ff9c00;}
.product-detail-description .btn-medium:hover{ background:#ff9c00;}
.pricing-table-footer a{background-color: #ff9c00; }
.all-team-members .detail h6{ color:#ff9c00; }
.all-team-members .detail a:hover{color: #ff9c00;}
.cbp-l-caption-text a:hover{  color:#ff9c00;}
.main-footer .useful-links ul li a:hover{ color:#ff9c00;}
.main-footer .get-touch ul li a:hover span{color:#ff9c00;}
#accordion2 .time-table{ background: #ff9c00 url(../../images/time-icon-bg.png) no-repeat;}
.top-bar .get-touch li:last-child a:hover{   color: #ff9c00;}
.meet-specialists .gallery-sec .layer { background-color: rgba(255, 156, 0, 0.78);}
/** banner three **/
.main-banner-three{ background:rgba(255, 156, 0, 0.78);  }
/** Fancybox overlay **/
.fancybox-overlay {	background: rgba(255, 156, 0, 0.78);}
/** Team Social icons **/
.cbp-caption-activeWrap {	background-color: rgba(255, 156, 0, 0.78);}
.member-detail .gallery-sec .layer { background-color: rgba(255, 156, 0, 0.78);}
.meet-specialists .gallery-sec a:hover { color: #ff9c00;}
.footer-light .main-footer .newsletter .form input[type=submit]:hover{ background: #ff9c00;}
.why-choose li i {color: #ff9c00;}
#page .header a{	background-color:#ff9c00 !important;}
.mm-menu {  background: #ff9c00 !important;}
ul.z-tabs-nav.z-tabs-mobile.z-state-closed { background-color: #ff9c00 !Important;}
ul.z-tabs-mobile li:first-child { background-color: #ff9c00 !important;}
.header2.sticky .nav li.item-select { background: #ff9c00;}


.shop-cart .cart-pro-detail .cancel i{color: #ff9c00;}
.cash-decide .cart-total .total-sec a{background: #ff9c00;}
.shop-cart .cart-update-sec a:hover{ background: #ff9c00; }