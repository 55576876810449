/*-----------------------------------------------------------------------------

KENBURNER RESPONSIVE BASIC STYLES OF HTML DOCUMENT

Screen Stylesheet

version:   	1.0
date:      	07/27/11
author:		themepunch
email:     	support@themepunch.com
website:   	http://www.themepunch.com
-----------------------------------------------------------------------------*/



.boxedcontainer		{	max-width: 1170px; margin:auto; padding:0px 30px;}

/*********************************************
	-	SETTINGS FOR BANNER CONTAINERS	-
**********************************************/

.tp-banner-container{
	width:100%;
	position:relative;
	padding:0;

}

.tp-banner{
	width:100%;
	position:relative;
}

.tp-banner-fullscreen-container {
		width:100%;
		position:relative;
		padding:0;
}

.p-logobare{
    width:250px;
    position:absolute;
    left: 45%;
    top: 30%;
}

.panel-default > .panel-heading{
    background-color:transparent;
}

#p-editor-alert {
    position: fixed;
    right: 0;
    left: 0px;
    z-index: 1030;
    line-height: 25px;
    border-width: 0;
    padding: 18px;
    margin-bottom: 18px;
    border: 1px solid transparent;
    border-radius: 2px;
}
.alert-success {
    background-color: #34a853;
    border-color: #34a853;
    color: #ffffff;
}
.alert-primary {
    background-color: #02adc6;
    border-color: #00869a;
    color: #ffffff;
}

.myprofile{ padding:90px 0;}
.myprofile.form{}
.myprofile.form input[type=text]{   width: 100%;  padding: 0 12px;  border: solid 1px #b1b1b1;  height: 48px;}
.myprofile.form input[type=submit]{  width: 100%;  border: none;  background-color: #434A5A;  color: #fff;  padding: 14px 0;  text-transform: uppercase;
font-weight: 700;  cursor: pointer; margin:10px 0 0 0;}
.myprofile .leave-msg .form textarea{ width: 100%;  height: 220px;  padding: 12px 12px;}
.myprofile ::-webkit-input-placeholder { /* WebKit browsers */    color:    #767676 !important;}
.myprofile :-moz-placeholder { /* Mozilla Firefox 4 to 18 */   color:    #767676 !important;}
.myprofile ::-moz-placeholder { /* Mozilla Firefox 19+ */   color:    #767676 !important;}
.myprofile :-ms-input-placeholder { /* Internet Explorer 10+ */   color:    #767676 !important;}




#info-list .submenu {
  display: none;
  background: #fff;
  font-size: 14px;
}

#info-list .submenu li { padding: 16px 0 40px 0;}








#info-list.accordion {
  width: 100%;
  max-width: 100%;
  margin:35px 0 0 0;
  background: #FFF;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

#info-list.accordion .link {
  cursor: pointer;
  display: block;
  padding: 16px 15px 16px 22px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid #CCC;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
    background-color: #737c92;
      width: 100%;
        border-radius: 0px;

}
#info-list.accordion ul{ margin:0px 0 0 0 ;}
#info-list.accordion li:last-child .link { border-bottom: 0; }
        #info-list.accordion li a{color:orange;}
#info-list.accordion li p{font-size:12px;}


#info-list.accordion li{list-style: none;  color: #35bdd1;  font-size: 20px;  margin: 0 0 5px 0;  float: left;  width: 100%;   line-height: normal;  }
#info-list .accordion li i {
  position: absolute;
  top: 16px;
  right: 12px;
    font-size: 22px;
  color: #FFFFFF;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  
}

#info-list.accordion li i.fa-chevron-down {
  right: 12px;
  left: auto;
  font-size: 16px;
}

#info-list.accordion li.open .link {   color: #fff;}

#info-list.accordion li.open i { color: #fff; }

#info-list.accordion li.open i.fa-chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

/**
 * Submenu
 -----------------------------*/


#info-list .submenu {
  display: none;
  background: #fff;
  font-size: 14px;
}



#info-list .submenu li { padding: 16px 0 40px 0;}


/*------------------------------------------------------------------
  [Parsley]
*/
.parsley-errors-list.filled{
  margin-top: 10px;
  margin-bottom: 0;
  padding: 15px 20px;
  position: relative;
  background-color: #eb6357;
  color: #fff;
  

  > li{
    list-style: none;
  }
}

.question{
                  padding:20px;
                  background:#f0f0f0;
                  margin-bottom:10px;
                  clear:both;
                  float:left;
                  width:100%;
                }

                .question:hover, .question.active{
               
                  background:green;
                  color:white;
                 
                }

                .question .icon-checkmark{
                  font-size:2em;
                }

                .question .p-total{


                }

                 .key-question{
                  padding:11px;
                  background:#f0f0f0;
                  margin-bottom:10px;
                  clear:both;
                  float:left;
                  width:100%;
                }

                .key-question:hover{
                 
                  background:green;
                  color:white;
                 
                }

                .key-question .icon-checkmark{
                  font-size:2em;
                }

                .key-question .p-total{


                }

                .key-question-text{
                  height:51px;
                  line-height:51px;
                }

                .btn-readmore {
                    border: none;
                    background-color: #434A5A;
                    color: #fff;
                    padding: 7px 20px;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 11px;
                    cursor: pointer;
                    margin: 0 0 0;
                }
                .standard-red{
                      background-color: #ec5349;
                      padding:10px;
                      width:100%;
                      color:white;
                      font-size:1.2em !important;
                      line-height:1em;
                      margin-bottom:15px
                }

                
                .evidence-posts {

                  margin-top:30px;
                }

                .evidence-posts ul li {
                    color: #02adc6;
                }

                .evidence-posts ul li {
                    list-style: none;
                    font-size: 16px;
                    margin: 0 0 0 0px;
                    float: left;
                    width: 100%;
                    line-height: 20px;
                    border-bottom:1px solid #eeeeee;
                    padding-top:15px;
                    padding-bottom:15px;
                }

                .evidence-posts ul li span {
                    color: #222;
                    font-size: 18px;
                }
                

                .btn-complete {
                  background-color: green;
                }

                textarea.form-control{
                    width: 100%;
                  
                    border: solid 1px #b1b1b1;
                    border-radius:0px;
                    height: 200px;
                }

                .complete{
                  font-size:3em;
                  color:green;
                }

                .my-white{
                  color:white;
                  margin-left:3px;
                }

                .my-green{
                  color:green;
                  margin-left:3px;
                }

                .my-grey{
                  color: #e4e4e4;
                  margin-left:3px;
                }

                .reviewheader{
                  font-size:14px !important;
                }
               .z-tabs.horizontal > ul > li {
                    float: left;
                    margin: 0 5px 0 0;
                }
                 .action-icons{
                  float: right;
                  margin: 3px 0 0 0;
                }

                .action-icons button {
                    color: #434a5a;
                    border: solid 1px #434a5a;
                    background-color:transparent;
                    padding: 7px 9px;
                    border-radius: 100%;
                    font-size: 16px;
                }

                .action-icons button:hover {
                    transition: background-color 0.5s ease;
                    background: #ff9c00;
                    border: solid 1px #ff9c00;
                    color: #fff;
                }
                .btn-front {
                    border: none;
                    background-color: #434A5A;
                    color: #fff;
                    padding: 7px 20px;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 11px;
                    cursor: pointer;
                    margin: 12px 0 0 0;
                }
 .region-picker{
    font-size:30px;
    height:auto;
  }
.font-bold{ font-weight:bold } 


.sub-banner #searchbar .search {
   position:absolute;
   right:30px;
   bottom: 20px;
}
#searchbar .search input[type=text] {
    width: 83%;
    padding: 14px 12px;
    color: #747474;
    font-style: italic;
    border: none;
}

#searchbar .search button {
    width: 17%;
    float: right;
    height:48px;
    background-color: #fff;
    padding: 14px 10px;
    border: none;
    margin: 0 0px 0 0;
    color: #333;

}
#searchbar .search button:hover {
    background-color: #ff9c00;
    color: #fff;
}
#searchbar .search button:hover i{
   
    color: #fff;
}
#searchbar .search button i{
    position:relative;
    top:inherit;
    left:inherit;
}
 .search ::-webkit-input-placeholder { /* WebKit browsers */
    color:    #999;
}
 .search :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    #999;
}
 .search ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    #999;
}
 .search :-ms-input-placeholder { /* Internet Explorer 10+ */
    color:    #999;
}

.rationaleandpoints{
  font-size:25px;
  color: #ec5349;
}

  .standard-icons{
    float: right;
    margin: 3px 0 0 0;
  }
  .standard-icons button {
    color: #434a5a;
    border: solid 1px #434a5a;
    background-color: transparent;
    padding: 1px 3px;
    border-radius: 100%;
    font-size: 16px;
}

hr{
  border-top:solid 1px #bcbcbc
}


 /*==========  Mobile First Method  ==========*/

    /* Custom, iPhone Retina */ 
    @media only screen and (min-width : 320px) {
         .aya-map{

             height: 400px;
          }
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 480px) {
         .aya-map{

             height: 400px;
          }
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width : 768px) {
           .aya-map{

             height: 600px;
          }
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {
           .aya-map{

             height: 600px;
          }
    }

    /* Large Devices, Wide Screens */
    @media only screen and (min-width : 1200px) {
           .aya-map{

             height: 600px;
          }
    }

.rob-header{
  width:100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  display:flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: auto;
  @media only screen and (min-width : 768px) {

  }

  &__wrapper{
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    @media only screen and (min-width : 768px) {
      background: transparent;
      padding: 0px;
      position:absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }
  &__title{
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    font-size: 30px;
    color: #fa0e10;
    @media only screen and (min-width : 768px) {
      width: 500px;
      font-size: 55px;
    }


  }
  &__cta{
    background-color: #008000;
    width: 100%;
    color: white;
    display: flex;
    font-size: 15px;
    height: 80px;
    align-items: center;
    justify-content: center;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 13%;
    cursor: pointer;
    transition: all ease 0.5s;

    @media only screen and (min-width : 768px) {
      margin-bottom: 5%;
      width: 500px;
      font-size: 20px;
    }


    &:hover{
      background-color: #034a03;
    }
  }
}