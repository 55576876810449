/*
	jQuery.mmenu CSS
*/
/*
	jQuery.mmenu panels CSS
*/
.mm-menu.mm-horizontal > .mm-panel {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease; }

.mm-menu .mm-hidden {
  display: none; }

.mm-wrapper {
  overflow-x: hidden;
  position: relative; }

.mm-menu,
.mm-menu > .mm-panel {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0; }

.mm-menu {
  background: inherit;
  display: block;
  overflow: hidden;
  padding: 0; }
  .mm-menu > .mm-panel {
    background: inherit;
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 20px;
    -webkit-transform: translate(100%, 0);
    -moz-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    -o-transform: translate(100%, 0);
    transform: translate(100%, 0); }
    .mm-menu > .mm-panel.mm-opened {
      -webkit-transform: translate(0%, 0);
      -moz-transform: translate(0%, 0);
      -ms-transform: translate(0%, 0);
      -o-transform: translate(0%, 0);
      transform: translate(0%, 0); }
    .mm-menu > .mm-panel.mm-subopened {
      -webkit-transform: translate(-30%, 0);
      -moz-transform: translate(-30%, 0);
      -ms-transform: translate(-30%, 0);
      -o-transform: translate(-30%, 0);
      transform: translate(-30%, 0); }
    .mm-menu > .mm-panel.mm-highest {
      z-index: 1; }
  .mm-menu .mm-list {
    padding: 20px 0; }
  .mm-menu > .mm-list {
    padding-bottom: 0; }
    .mm-menu > .mm-list:after {
      content: '';
      display: block;
      height: 40px; }

.mm-panel > .mm-list {
  margin-left: -20px;
  margin-right: -20px; }
  .mm-panel > .mm-list:first-child {
    padding-top: 0; }

.mm-list,
.mm-list > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0; }

.mm-list {
  font: inherit;
  font-size: 14px; }
  .mm-list a,
  .mm-list a:hover {
    text-decoration: none; }
	.mm-list > li.select > a {background:rgba(0, 0, 0, 0.09);}
		.mm-list > li > a:hover {background:rgba(0, 0, 0, 0.15);}
	.mm-list a.mm-subopen{background:rgba(0, 0, 0, 0.15) !important;}
  .mm-list > li {
    position: relative; }
    .mm-list > li > a,
    .mm-list > li > span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: rgba(255, 255, 255, 0.82);
      line-height: 24px;
      display: block;
      padding: 10px 10px 10px 20px;
	  text-decoration:none;
      margin: 0; font-size:17px; }
  .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    content: '';
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0; }
  .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    width: auto;
    margin-left: 0;
    position: relative;
    left: auto; }
  .mm-list a.mm-subopen {
    background:rgba(2, 147, 169, 0.32);
    width: 40px;
    height: 100%;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2; }
    .mm-list a.mm-subopen:before {
      content: '';
      border-left-width: 1px;
      border-left-style: solid;
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }
    .mm-list a.mm-subopen.mm-fullsubopen {
      width: 100%; }
      .mm-list a.mm-subopen.mm-fullsubopen:before {
        border-left: none; }
    .mm-list a.mm-subopen + a,
    .mm-list a.mm-subopen + span {
      padding-right: 5px;
      margin-right: 40px; }
  .mm-list > li.mm-selected > a.mm-subopen {
    background: transparent; }
  .mm-list > li.mm-selected > a.mm-fullsubopen + a,
  .mm-list > li.mm-selected > a.mm-fullsubopen + span {
    padding-right: 45px;
    margin-right: 0; }
  .mm-list a.mm-subclose {
    text-indent: 20px;
    padding-top: 30px;
    margin-top: -20px; }
  .mm-list > li.mm-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 10px;
    text-transform: uppercase;
    text-indent: 20px;
    line-height: 25px;
    padding-right: 5px; }
  .mm-list > li.mm-spacer {
    padding-top: 40px; }
    .mm-list > li.mm-spacer.mm-label {
      padding-top: 25px; }
  .mm-list a.mm-subopen:after,
  .mm-list a.mm-subclose:before {
    content: '';
    border: 2px solid transparent;
    display: inline-block;
    width: 7px;
    height: 7px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-bottom: -5px;
    position: absolute;
    bottom: 50%; }
  .mm-list a.mm-subopen:after {
    border-top: none;
    border-left: none;
    right: 18px; }
  .mm-list a.mm-subclose:before {
    border-right: none;
    border-bottom: none;
    margin-bottom: -15px;
    left: 22px; }

.mm-menu.mm-vertical .mm-list .mm-panel {
  display: none;
  padding: 10px 0 10px 10px; }
  .mm-menu.mm-vertical .mm-list .mm-panel li:last-child:after {
    border-color: transparent; }
.mm-menu.mm-vertical .mm-list li.mm-opened > .mm-panel {
  display: block; }
.mm-menu.mm-vertical .mm-list > li > a.mm-subopen {
  height: 40px; }
  .mm-menu.mm-vertical .mm-list > li > a.mm-subopen:after {
    top: 16px;
    bottom: auto; }
.mm-menu.mm-vertical .mm-list > li.mm-opened > a.mm-subopen:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }
.mm-menu.mm-vertical .mm-list > li.mm-label > a.mm-subopen {
  height: 25px; }

html.mm-opened .mm-page {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }

.mm-menu {
    color: rgba(255, 255, 255, 0.6); }
  .mm-menu .mm-list > li:after {
    border-color: rgba(0, 0, 0, 0.15); }
  .mm-menu .mm-list > li > a.mm-subclose {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.3); }
  .mm-menu .mm-list > li > a.mm-subopen:after, .mm-menu .mm-list > li > a.mm-subclose:before {
    border-color: #E8E8E8; }
  .mm-menu .mm-list > li > a.mm-subopen:before {
    border-color: rgba(0, 0, 0, 0.15); }
  .mm-menu .mm-list > li.mm-selected > a:not(.mm-subopen),
  .mm-menu .mm-list > li.mm-selected > span {
    background: rgba(0, 0, 0, 0.1); }
  .mm-menu .mm-list > li.mm-label {
    background: rgba(255, 255, 255, 0.05); }
  .mm-menu.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
  .mm-menu.mm-vertical .mm-list li.mm-opened > ul {
    background: rgba(255, 255, 255, 0.05); }

/*
	jQuery.mmenu offcanvas addon CSS
*/
.mm-page {
  box-sizing: border-box;
  position: relative; }

.mm-slideout {
  -webkit-transition: -webkit-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
  transition: transform 0.4s ease; }

html.mm-opened {
  overflow: hidden;
  position: relative; }
  html.mm-opened body {
    overflow: hidden; }

html.mm-background .mm-page {
  background: inherit; }

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999; }

html.mm-opened #mm-blocker,
html.mm-blocking #mm-blocker {
  display: block; }

.mm-menu.mm-offcanvas {
  display: none;
  position: fixed; }
.mm-menu.mm-current {
  display: block; }

.mm-menu {
  width: 80%;
  min-width: 140px;
  max-width: 440px; }

html.mm-opening .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -moz-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  -o-transform: translate(80%, 0);
  transform: translate(80%, 0); }

@media all and (max-width: 175px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -moz-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    -o-transform: translate(140px, 0);
    transform: translate(140px, 0); } }
@media all and (min-width: 550px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -moz-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    -o-transform: translate(440px, 0);
    transform: translate(440px, 0); } }
/*
	jQuery.mmenu buttonbars addon CSS
*/
.mm-buttonbar {
  border: 1px solid transparent;
  border-radius: 5px;
  text-align: center;
  line-height: 20px;
  overflow: hidden;
  display: block;
  padding: 0;
  margin: 0;
  position: relative; }
  .mm-buttonbar:after {
    content: '';
    display: block;
    clear: both; }
  .mm-buttonbar > * {
    border-left: 1px solid transparent;
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
    float: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .mm-buttonbar > a {
    text-decoration: none; }
  .mm-buttonbar > input {
    position: absolute;
    left: -1000px;
    top: -1000px; }
  .mm-buttonbar > input:checked + label {
    border-color: transparent  !important; }
  .mm-buttonbar > *:first-child,
  .mm-buttonbar > input:first-child + * {
    border-left: none; }
  .mm-buttonbar.mm-buttonbar-2 > * {
    width: 50%; }
  .mm-buttonbar.mm-buttonbar-3 > * {
    width: 33.33%; }
  .mm-buttonbar.mm-buttonbar-4 > * {
    width: 25%; }
  .mm-buttonbar.mm-buttonbar-5 > * {
    width: 20%; }

.mm-header .mm-buttonbar {
  margin-top: 20px;
  margin-left: -30px;
  margin-right: -30px; }

.mm-footer .mm-buttonbar {
  border: none;
  border-radius: none;
  line-height: 40px;
  margin: -10px -10px 0 -20px; }
  .mm-footer .mm-buttonbar > * {
    border-left: none; }

.mm-list > li > .mm-buttonbar {
  margin: 10px 20px; }

.mm-menu .mm-buttonbar {
  border-color: rgba(255, 255, 255, 0.6);
  background: #333333; }
  .mm-menu .mm-buttonbar > * {
    border-color: rgba(255, 255, 255, 0.6); }
  .mm-menu .mm-buttonbar > input:checked + label {
    background: rgba(255, 255, 255, 0.6);
    color: #333333; }

/*
	jQuery.mmenu counters addon CSS
*/
em.mm-counter {
  font: inherit;
  font-size: 14px;
  font-style: normal;
  text-indent: 0;
  line-height: 20px;
  display: block;
  margin-top: -10px;
  position: absolute;
  right: 40px;
  top: 50%; }
  em.mm-counter + a.mm-subopen {
    padding-left: 40px; }
    em.mm-counter + a.mm-subopen + a,
    em.mm-counter + a.mm-subopen + span {
      margin-right: 80px; }
  em.mm-counter + a.mm-fullsubopen {
    padding-left: 0; }

.mm-vertical em.mm-counter {
  top: 12px;
  margin-top: 0; }

.mm-nosubresults > em.mm-counter {
  display: none; }

.mm-menu em.mm-counter {
  color: rgba(255, 255, 255, 0.3); }

/*
	jQuery.mmenu dragOpen addon CSS
*/
html.mm-opened.mm-dragging .mm-menu,
html.mm-opened.mm-dragging .mm-page,
html.mm-opened.mm-dragging .mm-fixed-top,
html.mm-opened.mm-dragging .mm-fixed-bottom,
html.mm-opened.mm-dragging #mm-blocker {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s; }

/*
	jQuery.mmenu footer addon CSS
*/
.mm-footer {
  background: inherit;
  border-top: 1px solid transparent;
  text-align: center;
  line-height: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px 10px 0 20px;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0; }

.mm-menu.mm-hasfooter > .mm-panel:after {
  height: 80px; }

.mm-menu .mm-footer {
  border-color: rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.78);
  font-size:14px;
   }

/*
	jQuery.mmenu header addon CSS
*/
.mm-header {
  background: inherit;
  border-bottom: 1px solid transparent;
  text-align: center;
  line-height: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  padding: 0 50px;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0; }
  .mm-header .mm-title{padding-top: 18px;}
  .mm-header .mm-prev,
  .mm-header .mm-next,
  .mm-header .mm-close {
    padding-top: 28px; }
  .mm-header .mm-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    width: 100%;
    position: relative;     color: #fff;
    font-size: 20px;}
  .mm-header .mm-prev,
  .mm-header .mm-next,
  .mm-header .mm-close {
    text-decoration: none;
    display: block;
    box-sizing: border-box;
    min-width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1; }
  .mm-header .mm-prev {
    padding-left: 20px;
    padding-right: 10px;
    left: 0;     background-color:rgba(0, 0, 0, 0.27);}
  .mm-header .mm-next,
  .mm-header .mm-close {
    padding-left: 10px;
    padding-right: 20px;
    right: 0; }
  .mm-header [href].mm-prev:before, .mm-header [href].mm-next:after {
    content: '';
    border: 2px solid transparent;
    display: inline-block;
    width: 7px;
    height: 7px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .mm-header [href].mm-prev:before {
    border-right: none;
    border-bottom: none;
    margin-left: 2px;
    margin-right: 5px; }
  .mm-header [href].mm-next:after, .mm-header [href].mm-close:after {
    margin-left: 5px;
    margin-right: -2px; }
  .mm-header [href].mm-next:after {
    border-top: none;
    border-left: none; }
  .mm-header [href].mm-close:after {
    content: 'x'; }

.mm-menu.mm-hassearch .mm-header {
  height: 50px;
  top: 50px; }
  .mm-menu.mm-hassearch .mm-header .mm-title,
  .mm-menu.mm-hassearch .mm-header .mm-prev,
  .mm-menu.mm-hassearch .mm-header .mm-next,
  .mm-menu.mm-hassearch .mm-header .mm-close {
    padding-top: 20px; }

.mm-menu.mm-hasheader li.mm-subtitle {
  display: none; }
.mm-menu.mm-hasheader > .mm-panel {
  padding-top: 80px; }
  .mm-menu.mm-hasheader > .mm-panel.mm-list {
    padding-top: 60px; }
  .mm-menu.mm-hasheader > .mm-panel > .mm-list:first-child {
    margin-top: -20px; }
.mm-menu.mm-hasheader.mm-hassearch > .mm-panel {
  padding-top: 120px; }
  .mm-menu.mm-hasheader.mm-hassearch > .mm-panel.mm-list {
    padding-top: 100px; }

.mm-menu .mm-header {
  border-color: rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.3); }
  .mm-menu .mm-header .mm-prev:before,
  .mm-menu .mm-header .mm-next:after,
  .mm-menu .mm-header .mm-close:after {
    border-color: #fff; margin: -3px 6px 0 0; float: left;}
 
/*
	jQuery.mmenu labels addon CSS
*/
.mm-list li.mm-label > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  line-height: 25px; }
.mm-list li.mm-label.mm-opened a.mm-subopen:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }
.mm-list li.mm-collapsed:not(.mm-uncollapsed) {
  display: none; }

.mm-menu.mm-vertical .mm-list > li.mm-label > a.mm-subopen:after {
  top: 8.5px; }

.mm-menu .mm-list li.mm-label > div > div {
  background: rgba(255, 255, 255, 0.05); }

/*
	jQuery.mmenu searchfield addon CSS
*/
.mm-search,
.mm-search input {
  box-sizing: border-box; }

.mm-list > li.mm-search {
  padding: 10px;
  margin-top: -20px; }
.mm-list > li.mm-subtitle + li.mm-search {
  margin-top: 0; }

div.mm-panel > div.mm-search {
  padding: 0 0 10px 0; }

.mm-menu.mm-hasheader .mm-list > li.mm-search {
  margin-top: 0; }

.mm-menu > .mm-search {
  background: inherit;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2; }

.mm-search {
  padding: 10px; }
  .mm-search input {
    border: none;
    border-radius: 30px;
    font: inherit;
    font-size: 14px;
    line-height: 30px;
    outline: none;
    display: block;
    width: 100%;
    height: 30px;
    margin: 0;
    padding: 0 10px;
	color:rgba(145, 145, 145, 1); }
  .mm-search input::-ms-clear {
    display: none; }

.mm-menu .mm-noresultsmsg {
  text-align: center;
  font-size: 21px;
  display: none;
  padding: 60px 0; }
  .mm-menu .mm-noresultsmsg:after {
    border: none !important; }

.mm-noresults .mm-noresultsmsg {
  display: block; }

.mm-menu li.mm-nosubresults > a.mm-subopen {
  display: none; }
  .mm-menu li.mm-nosubresults > a.mm-subopen + a,
  .mm-menu li.mm-nosubresults > a.mm-subopen + span {
    padding-right: 10px; }
.mm-menu.mm-hassearch > .mm-panel {
  padding-top: 70px; }
  .mm-menu.mm-hassearch > .mm-panel > .mm-list:first-child {
    margin-top: -20px; }
.mm-menu.mm-hasheader > .mm-panel > div.mm-search:first-child {
  margin-top: -10px; }
  .mm-menu.mm-hasheader > .mm-panel > div.mm-search:first-child + .mm-list {
    padding-top: 0; }

.mm-menu .mm-search input {
  background: #fff;
  color: rgba(145, 145, 145, 1); }
.mm-menu .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.3); }

/*
	jQuery.mmenu toggles addon CSS
*/
input.mm-toggle,
input.mm-check {
  position: absolute;
  left: -10000px; }

label.mm-toggle,
label.mm-check {
  margin: 0;
  position: absolute;
  bottom: 50%;
  z-index: 2; }
  label.mm-toggle:before,
  label.mm-check:before {
    content: '';
    display: block; }

label.mm-toggle {
  border-radius: 30px;
  width: 50px;
  height: 30px;
  margin-bottom: -15px; }
  label.mm-toggle:before {
    border-radius: 30px;
    width: 28px;
    height: 28px;
    margin: 1px; }

input.mm-toggle:checked ~ label.mm-toggle:before {
  float: right; }

label.mm-check {
  width: 30px;
  height: 30px;
  margin-bottom: -15px; }
  label.mm-check:before {
    border-left: 3px solid;
    border-bottom: 3px solid;
    width: 40%;
    height: 20%;
    margin: 25% 0 0 20%;
    opacity: 0.1;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg); }

input.mm-check:checked ~ label.mm-check:before {
  opacity: 1; }

.mm-menu.mm-vertical .mm-list > li label.mm-toggle, .mm-menu.mm-vertical .mm-list > li label.mm-check {
  bottom: auto;
  margin-bottom: 0; }
.mm-menu.mm-vertical .mm-list > li label.mm-toggle {
  top: 5px; }
.mm-menu.mm-vertical .mm-list > li label.mm-check {
  top: 5px; }

label.mm-toggle, label.mm-check {
  right: 20px; }

label.mm-toggle + a,
label.mm-toggle + span {
  margin-right: 70px; }

label.mm-check + a,
label.mm-check + span {
  margin-right: 50px; }

a.mm-subopen + label.mm-toggle, a.mm-subopen + label.mm-check {
  right: 50px; }

a.mm-subopen + label.mm-toggle + a,
a.mm-subopen + label.mm-toggle + span {
  margin-right: 100px; }

a.mm-subopen + label.mm-check + a,
a.mm-subopen + label.mm-check + span {
  margin-right: 80px; }

em.mm-counter + a.mm-subopen + label.mm-toggle, em.mm-counter + a.mm-subopen + label.mm-check {
  right: 90px; }

em.mm-counter + a.mm-subopen + label.mm-toggle + a,
em.mm-counter + a.mm-subopen + label.mm-toggle + span {
  margin-right: 140px; }

em.mm-counter + a.mm-subopen + label.mm-check + a,
em.mm-counter + a.mm-subopen + label.mm-check + span {
  margin-right: 120px; }

.mm-menu label.mm-toggle {
  background: rgba(0, 0, 0, 0.15); }
  .mm-menu label.mm-toggle:before {
    background: #333333; }
.mm-menu input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

.mm-menu label.mm-check:before {
  border-color: rgba(255, 255, 255, 0.6); }

/*
	jQuery.mmenu effects extension CSS
*/
html.mm-slide .mm-menu {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease; }
html.mm-slide.mm-opened .mm-menu {
  -webkit-transform: translateX(-30%);
  -moz-transform: translateX(-30%);
  -ms-transform: translateX(-30%);
  -o-transform: translateX(-30%);
  transform: translateX(-30%); }
html.mm-slide.mm-opening .mm-menu {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%); }
html.mm-slide.mm-right.mm-opened .mm-menu {
  -webkit-transform: translateX(30%);
  -moz-transform: translateX(30%);
  -ms-transform: translateX(30%);
  -o-transform: translateX(30%);
  transform: translateX(30%); }
html.mm-slide.mm-right.mm-opening .mm-menu {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%); }
html.mm-slide.mm-top.mm-opened .mm-menu {
  -webkit-transform: translateY(-30%);
  -moz-transform: translateY(-30%);
  -ms-transform: translateY(-30%);
  -o-transform: translateY(-30%);
  transform: translateY(-30%); }
html.mm-slide.mm-top.mm-opening .mm-menu {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%); }
html.mm-slide.mm-bottom.mm-opened .mm-menu {
  -webkit-transform: translateY(30%);
  -moz-transform: translateY(30%);
  -ms-transform: translateY(30%);
  -o-transform: translateY(30%);
  transform: translateY(30%); }
html.mm-slide.mm-bottom.mm-opening .mm-menu {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%); }

html.mm-zoom-menu .mm-menu {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease; }
html.mm-zoom-menu.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateX(-30%);
  -moz-transform: scale(0.7, 0.7) translateX(-30%);
  -ms-transform: scale(0.7, 0.7) translateX(-30%);
  -o-transform: scale(0.7, 0.7) translateX(-30%);
  transform: scale(0.7, 0.7) translateX(-30%);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center; }
html.mm-zoom-menu.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateX(0%);
  -moz-transform: scale(1, 1) translateX(0%);
  -ms-transform: scale(1, 1) translateX(0%);
  -o-transform: scale(1, 1) translateX(0%);
  transform: scale(1, 1) translateX(0%); }
html.mm-zoom-menu.mm-right.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateX(30%);
  -moz-transform: scale(0.7, 0.7) translateX(30%);
  -ms-transform: scale(0.7, 0.7) translateX(30%);
  -o-transform: scale(0.7, 0.7) translateX(30%);
  transform: scale(0.7, 0.7) translateX(30%);
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -ms-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center; }
html.mm-zoom-menu.mm-right.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateX(0%);
  -moz-transform: scale(1, 1) translateX(0%);
  -ms-transform: scale(1, 1) translateX(0%);
  -o-transform: scale(1, 1) translateX(0%);
  transform: scale(1, 1) translateX(0%); }
html.mm-zoom-menu.mm-top.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateY(-30%);
  -moz-transform: scale(0.7, 0.7) translateY(-30%);
  -ms-transform: scale(0.7, 0.7) translateY(-30%);
  -o-transform: scale(0.7, 0.7) translateY(-30%);
  transform: scale(0.7, 0.7) translateY(-30%);
  -webkit-transform-origin: center top;
  -moz-transform-origin: center top;
  -ms-transform-origin: center top;
  -o-transform-origin: center top;
  transform-origin: center top; }
html.mm-zoom-menu.mm-top.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateY(0%);
  -moz-transform: scale(1, 1) translateY(0%);
  -ms-transform: scale(1, 1) translateY(0%);
  -o-transform: scale(1, 1) translateY(0%);
  transform: scale(1, 1) translateY(0%); }
html.mm-zoom-menu.mm-bottom.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateY(30%);
  -moz-transform: scale(0.7, 0.7) translateY(30%);
  -ms-transform: scale(0.7, 0.7) translateY(30%);
  -o-transform: scale(0.7, 0.7) translateY(30%);
  transform: scale(0.7, 0.7) translateY(30%);
  -webkit-transform-origin: center bottom;
  -moz-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  -o-transform-origin: center bottom;
  transform-origin: center bottom; }
html.mm-zoom-menu.mm-bottom.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateY(0%);
  -moz-transform: scale(1, 1) translateY(0%);
  -ms-transform: scale(1, 1) translateY(0%);
  -o-transform: scale(1, 1) translateY(0%);
  transform: scale(1, 1) translateY(0%); }

html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel {
  -webkit-transform: scale(1.5, 1.5) translateX(100%);
  -moz-transform: scale(1.5, 1.5) translateX(100%);
  -ms-transform: scale(1.5, 1.5) translateX(100%);
  -o-transform: scale(1.5, 1.5) translateX(100%);
  transform: scale(1.5, 1.5) translateX(100%);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition-property: -webkit-transform, left;
  transition-property: transform, left; }
  html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel.mm-opened {
    -webkit-transform: scale(1, 1) translateX(0%);
    -moz-transform: scale(1, 1) translateX(0%);
    -ms-transform: scale(1, 1) translateX(0%);
    -o-transform: scale(1, 1) translateX(0%);
    transform: scale(1, 1) translateX(0%); }
    html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel.mm-opened.mm-subopened {
      -webkit-transform: scale(0.7, 0.7) translateX(-30%);
      -moz-transform: scale(0.7, 0.7) translateX(-30%);
      -ms-transform: scale(0.7, 0.7) translateX(-30%);
      -o-transform: scale(0.7, 0.7) translateX(-30%);
      transform: scale(0.7, 0.7) translateX(-30%); }

/*
	jQuery.mmenu fullscreen extension CSS
*/
.mm-menu.mm-fullscreen {
  width: 100%;
  min-width: 140px;
  max-width: 10000px; }

html.mm-opening.mm-fullscreen .mm-slideout {
  -webkit-transform: translate(100%, 0);
  -moz-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  -o-transform: translate(100%, 0);
  transform: translate(100%, 0); }

@media all and (max-width: 140px) {
  html.mm-opening.mm-fullscreen .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -moz-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    -o-transform: translate(140px, 0);
    transform: translate(140px, 0); } }
@media all and (min-width: 10000px) {
  html.mm-opening.mm-fullscreen .mm-slideout {
    -webkit-transform: translate(10000px, 0);
    -moz-transform: translate(10000px, 0);
    -ms-transform: translate(10000px, 0);
    -o-transform: translate(10000px, 0);
    transform: translate(10000px, 0); } }
html.mm-right.mm-opening.mm-fullscreen .mm-slideout {
  -webkit-transform: translate(-100%, 0);
  -moz-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  -o-transform: translate(-100%, 0);
  transform: translate(-100%, 0); }

@media all and (max-width: 140px) {
  html.mm-right.mm-opening.mm-fullscreen .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -moz-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    -o-transform: translate(-140px, 0);
    transform: translate(-140px, 0); } }
@media all and (min-width: 10000px) {
  html.mm-right.mm-opening.mm-fullscreen .mm-slideout {
    -webkit-transform: translate(-10000px, 0);
    -moz-transform: translate(-10000px, 0);
    -ms-transform: translate(-10000px, 0);
    -o-transform: translate(-10000px, 0);
    transform: translate(-10000px, 0); } }
.mm-menu.mm-front.mm-fullscreen.mm-top, .mm-menu.mm-front.mm-fullscreen.mm-bottom {
  height: 100%;
  min-height: 140px;
  max-height: 10000px; }

html.mm-opened.mm-fullscreen .mm-page {
  box-shadow: none !important; }

/*
	jQuery.mmenu position extension CSS
*/
.mm-menu.mm-top,
.mm-menu.mm-bottom {
  width: 100%;
  min-width: 100%;
  max-width: 100%; }

.mm-menu.mm-right {
  left: auto;
  right: 0; }

.mm-menu.mm-bottom {
  top: auto;
  bottom: 0; }

html.mm-right.mm-opening .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -moz-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  -o-transform: translate(-80%, 0);
  transform: translate(-80%, 0); }

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -moz-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    -o-transform: translate(-140px, 0);
    transform: translate(-140px, 0); } }
@media all and (min-width: 550px) {
  html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate(-440px, 0);
    -moz-transform: translate(-440px, 0);
    -ms-transform: translate(-440px, 0);
    -o-transform: translate(-440px, 0);
    transform: translate(-440px, 0); } }
/*
	jQuery.mmenu z-position extension CSS
*/
html.mm-front .mm-slideout {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
  z-index: 0 !important; }

.mm-menu.mm-front {
  z-index: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); }

html.mm-opened.mm-next .mm-page {
  box-shadow: none; }

.mm-menu.mm-front, .mm-menu.mm-next {
  -webkit-transition: -webkit-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
  transition: transform 0.4s ease;
  -webkit-transform: translate(-100%, 0);
  -moz-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  -o-transform: translate(-100%, 0);
  transform: translate(-100%, 0); }
  .mm-menu.mm-front.mm-right, .mm-menu.mm-next.mm-right {
    -webkit-transform: translate(100%, 0);
    -moz-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    -o-transform: translate(100%, 0);
    transform: translate(100%, 0); }
.mm-menu.mm-front.mm-top {
  -webkit-transform: translate(0, -100%);
  -moz-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  -o-transform: translate(0, -100%);
  transform: translate(0, -100%); }
.mm-menu.mm-front.mm-bottom {
  -webkit-transform: translate(0, 100%);
  -moz-transform: translate(0, 100%);
  -ms-transform: translate(0, 100%);
  -o-transform: translate(0, 100%);
  transform: translate(0, 100%); }

html.mm-opening .mm-menu.mm-front, html.mm-opening .mm-menu.mm-next {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0); }

.mm-menu.mm-front.mm-top, .mm-menu.mm-front.mm-bottom {
  height: 80%;
  min-height: 140px;
  max-height: 880px; }

/*
	jQuery.mmenu themes extension CSS
*/
html.mm-opened.mm-light .mm-page {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light {
  background: #f3f3f3;
  color: rgba(0, 0, 0, 0.6); }
  .mm-menu.mm-light .mm-list > li:after {
    border-color: rgba(0, 0, 0, 0.1); }
  .mm-menu.mm-light .mm-list > li > a.mm-subclose {
    background: rgba(255, 255, 255, 0.6);
    color: rgba(0, 0, 0, 0.3); }
  .mm-menu.mm-light .mm-list > li > a.mm-subopen:after, .mm-menu.mm-light .mm-list > li > a.mm-subclose:before {
    border-color: rgba(0, 0, 0, 0.3); }
  .mm-menu.mm-light .mm-list > li > a.mm-subopen:before {
    border-color: rgba(0, 0, 0, 0.1); }
  .mm-menu.mm-light .mm-list > li.mm-selected > a:not(.mm-subopen),
  .mm-menu.mm-light .mm-list > li.mm-selected > span {
    background: rgba(255, 255, 255, 0.6); }
  .mm-menu.mm-light .mm-list > li.mm-label {
    background: rgba(0, 0, 0, 0.03); }
  .mm-menu.mm-light.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
  .mm-menu.mm-light.mm-vertical .mm-list li.mm-opened > ul {
    background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-light .mm-buttonbar {
  border-color: rgba(0, 0, 0, 0.6);
  background: #f3f3f3; }
  .mm-menu.mm-light .mm-buttonbar > * {
    border-color: rgba(0, 0, 0, 0.6); }
  .mm-menu.mm-light .mm-buttonbar > input:checked + label {
    background: rgba(0, 0, 0, 0.6);
    color: #f3f3f3; }

.mm-menu.mm-light label.mm-check:before {
  border-color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-light em.mm-counter {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light .mm-footer {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light .mm-header {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.3); }
  .mm-menu.mm-light .mm-header .mm-prev:before,
  .mm-menu.mm-light .mm-header .mm-next:after,
  .mm-menu.mm-light .mm-header .mm-close:after {
    border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light .mm-list li.mm-label > div > div {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-light .mm-search input {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6); }
.mm-menu.mm-light .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light label.mm-toggle {
  background: rgba(0, 0, 0, 0.1); }
  .mm-menu.mm-light label.mm-toggle:before {
    background: #f3f3f3; }
.mm-menu.mm-light input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

html.mm-opened.mm-white .mm-page {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white {
  background: white;
  color: rgba(0, 0, 0, 0.6); }
  .mm-menu.mm-white .mm-list > li:after {
    border-color: rgba(0, 0, 0, 0.1); }
  .mm-menu.mm-white .mm-list > li > a.mm-subclose {
    background: rgba(0, 0, 0, 0.06);
    color: rgba(0, 0, 0, 0.3); }
  .mm-menu.mm-white .mm-list > li > a.mm-subopen:after, .mm-menu.mm-white .mm-list > li > a.mm-subclose:before {
    border-color: rgba(0, 0, 0, 0.3); }
  .mm-menu.mm-white .mm-list > li > a.mm-subopen:before {
    border-color: rgba(0, 0, 0, 0.1); }
  .mm-menu.mm-white .mm-list > li.mm-selected > a:not(.mm-subopen),
  .mm-menu.mm-white .mm-list > li.mm-selected > span {
    background: rgba(0, 0, 0, 0.06); }
  .mm-menu.mm-white .mm-list > li.mm-label {
    background: rgba(0, 0, 0, 0.03); }
  .mm-menu.mm-white.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
  .mm-menu.mm-white.mm-vertical .mm-list li.mm-opened > ul {
    background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-white .mm-buttonbar {
  border-color: rgba(0, 0, 0, 0.6);
  background: white; }
  .mm-menu.mm-white .mm-buttonbar > * {
    border-color: rgba(0, 0, 0, 0.6); }
  .mm-menu.mm-white .mm-buttonbar > input:checked + label {
    background: rgba(0, 0, 0, 0.6);
    color: white; }

.mm-menu.mm-white label.mm-check:before {
  border-color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-white em.mm-counter {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white .mm-footer {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white .mm-header {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.3); }
  .mm-menu.mm-white .mm-header .mm-prev:before,
  .mm-menu.mm-white .mm-header .mm-next:after,
  .mm-menu.mm-white .mm-header .mm-close:after {
    border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white .mm-list li.mm-label > div > div {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-white .mm-search input {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6); }
.mm-menu.mm-white .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white label.mm-toggle {
  background: rgba(0, 0, 0, 0.1); }
  .mm-menu.mm-white label.mm-toggle:before {
    background: white; }
.mm-menu.mm-white input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

html.mm-opened.mm-black .mm-page {
  box-shadow: none; }

.mm-menu.mm-black {
  background: black;
  color: rgba(255, 255, 255, 0.6); }
  .mm-menu.mm-black .mm-list > li:after {
    border-color: rgba(255, 255, 255, 0.2); }
  .mm-menu.mm-black .mm-list > li > a.mm-subclose {
    background: rgba(255, 255, 255, 0.25);
    color: rgba(255, 255, 255, 0.3); }
  .mm-menu.mm-black .mm-list > li > a.mm-subopen:after, .mm-menu.mm-black .mm-list > li > a.mm-subclose:before {
    border-color: rgba(255, 255, 255, 0.3); }
  .mm-menu.mm-black .mm-list > li > a.mm-subopen:before {
    border-color: rgba(255, 255, 255, 0.2); }
  .mm-menu.mm-black .mm-list > li.mm-selected > a:not(.mm-subopen),
  .mm-menu.mm-black .mm-list > li.mm-selected > span {
    background: rgba(255, 255, 255, 0.25); }
  .mm-menu.mm-black .mm-list > li.mm-label {
    background: rgba(255, 255, 255, 0.15); }
  .mm-menu.mm-black.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
  .mm-menu.mm-black.mm-vertical .mm-list li.mm-opened > ul {
    background: rgba(255, 255, 255, 0.15); }

.mm-menu.mm-black .mm-buttonbar {
  border-color: rgba(255, 255, 255, 0.6);
  background: black; }
  .mm-menu.mm-black .mm-buttonbar > * {
    border-color: rgba(255, 255, 255, 0.6); }
  .mm-menu.mm-black .mm-buttonbar > input:checked + label {
    background: rgba(255, 255, 255, 0.6);
    color: black; }

.mm-menu.mm-black label.mm-check:before {
  border-color: rgba(255, 255, 255, 0.6); }

.mm-menu.mm-black em.mm-counter {
  color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black .mm-footer {
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black .mm-header {
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.3); }
  .mm-menu.mm-black .mm-header .mm-prev:before,
  .mm-menu.mm-black .mm-header .mm-next:after,
  .mm-menu.mm-black .mm-header .mm-close:after {
    border-color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black .mm-list li.mm-label > div > div {
  background: rgba(255, 255, 255, 0.15); }

.mm-menu.mm-black .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6); }
.mm-menu.mm-black .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black label.mm-toggle {
  background: rgba(255, 255, 255, 0.2); }
  .mm-menu.mm-black label.mm-toggle:before {
    background: black; }
.mm-menu.mm-black input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }



a.demos{ background:#000 !important; opacity:1;}

